<template>
  <div class="home px-md-3">
    <md-toolbar>
      <h3 class="md-title float-left">Home</h3>
    </md-toolbar>
    <div class="home-content md-elevation-4 p-5">
      <!-- <div class="alert alert-secondary alert-dismissible d-flex">
        <span>Account: F45 Prestons, has no email channel configured.</span>
        <md-button class="md-primary md-raised">Manage Channel</md-button>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="alert alert-secondary alert-dismissible d-flex">
        <span>To connect Studio Backoffice to your Mindbody Account, you must be the Owner of the Mindbody Site Please enter your Site ID to recieve the activation code required for integration.</span>
        <md-button class="md-primary md-raised">Manage Integration</md-button>
        <button type="button" class="close" data-dismiss="alert-1" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>-->
      <div class="mt-5">
        <h5 class="my-3">Studio Backoffice</h5>
         <p class="my-5">
         The ultimate lead management tool. 
        </p>
<!--
        <h6>Regards,</h6>
        <h6>Joshu</h6>-->
      </div>
    </div>
  </div>
</template>

<script>
import { db, messaging } from "@/firebase/init";
import { mapGetters } from "vuex";
import firebase from "firebase";

let refUsers = db.collection("users");

export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>

<style>
.home-content {
  min-height: calc(100vh - 140px);
  background-color: white;
}
@media only screen and (max-width: 960px) {
  .home-content {
    min-height: calc(100vh - 120px);
  }
}
.alert .md-button {
  min-width: 170px;
  margin-left: 30px;
}
</style>