import { db } from "@/firebase/init";
import firebase from "firebase";
import moment from "moment";


export default {
    methods: {
        preventDefault(e)
        {
            e.preventDefault()
            e.stopPropagation()
        },
        showDialogTrue(e) {
            this.showDialog = true;
            e.preventDefault()
            e.stopPropagation()
        },
        snoozeLead(days, snoozeText) {
            let lead = this.lead;
            let user = this.user;

            this.$store.dispatch("setSending", true);
            const leadDate = moment().add(days, "d").toDate();
            db.collection("contacts")
                .doc(lead.id)
                .update({
                    leadReviewDate: firebase.firestore.Timestamp.fromDate(leadDate),
                })
                .then(async () => {
                    let messageText =
                        user.data.displayName + " snoozed the lead for " + snoozeText;
                    await this.logActivity(
                        messageText,
                        lead.accountId,
                        lead.id,
                        {
                            days: days,
                            updatedBy: {
                                name: user.data.displayName,
                                id: user.data.uid,
                            },
                        }
                    );
                })
                .then(() => {
                    this.$store.dispatch("setSending", false);
                    this.$store.dispatch("showSnackbar", "Lead updated successfully");
                })
                .catch((err) => {
                    this.$store.dispatch("setSending", false);
                    this.$store.dispatch("showSnackbar", err);
                });
        },
        lostLead() {
            this.updateStatus("lost")
        },
        completedLead() {
            this.updateStatus("won")
        },
        updateStatus(status) {
            let lead = this.lead;
            let user = this.user;

            this.$store.dispatch("setSending", true);
            db.collection("contacts")
                .doc(lead.id)
                .update({
                    leadStatus: status,
                    leadLost: firebase.firestore.Timestamp.now(),
                })
                .then(async () => {
                    let messageText = user.data.displayName + " marked the lead as " + status;
                    await this.logActivity(
                        messageText,
                        lead.accountId,
                        lead.id,
                        {
                            status: status,
                            updatedBy: {
                                name: user.data.displayName,
                                id: user.data.uid,
                            },
                        }
                    );
                })
                .then(() => {
                    this.$store.dispatch("setSending", false);
                    this.$store.dispatch("showSnackbar", "Lead updated successfully");
                })
                .catch((err) => {
                    this.$store.dispatch("setSending", false);
                    this.$store.dispatch("showSnackbar", err);
                });
        }
    }
}