<template>
  <md-button class="md-icon-button" @click="action()" :title="text">
    <md-icon class="text-gray">{{icon}}</md-icon>
  </md-button>
</template>
<script>
import StatusUpdateMixin from "@/mixins/StatusUpdateMixin";

export default {
  name: "ActionArchive",
  props: ["item", "collection", "status", "text", "icon"],
  mixins: [StatusUpdateMixin]
};
</script>