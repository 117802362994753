<template>
  <div class="contacts px-md-3">
    <md-toolbar>
      <div class="md-layout md-gutter" style="flex:1;">
        <div class="md-layout-item mt-3">
          <h3 class="md-title float-left">Contacts</h3>
          <!-- <span class="custom-badge status-primary ml-3">
            {{
            accountContacts.length
            }}
          </span>-->
        </div>
        <div class="md-layout-item">
          <md-field>
            <md-icon>search</md-icon>
            <label>Search...</label>
            <md-input v-model="searchText"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item d-flex mt-2">
          <ContactDialog />
          <ContactEditDialog
            :showEditDialog="showEditDialog"
            :contact="editedContact"
            @close="showEditDialog = false"
          />
          <md-button class="md-primary">Upload xls</md-button>
        </div>
      </div>
      <md-menu md-size="medium" md-align-trigger :mdCloseOnClick="closeOnClick" :mdCloseOnSelect="closeOnSelect">
        <md-button md-menu-trigger class="md-icon-button">
          <md-icon>filter_list</md-icon>
        </md-button>

        <md-menu-content class="px-3 md-elevation-20 bg-white account-filter">
          <div class="md-title text-gray my-2">Contacts Filters</div>
          <md-menu-item @click="$event => selectedTimeFilter = 'lastUpdated'">

            <md-radio v-model="selectedTimeFilter" value="lastUpdated" @click="$event => !closeOnSelect"> Last Updated</md-radio>
          </md-menu-item>
          <md-menu-item @click="selectedTimeFilter = 'leadReviewDate'">

            <md-radio v-model="selectedTimeFilter" value="leadReviewDate" @click="$event => !closeOnSelect"> Last Reviewed</md-radio>
          </md-menu-item>
          <md-menu-item @click="selectedTimeFilter = 'createdAt'">
            <md-radio v-model="selectedTimeFilter" @click="$event =>! closeOnClick" value="createdAt"> Last
              Created</md-radio>
          </md-menu-item>
        </md-menu-content>
      </md-menu>
      <!-- <md-menu md-size="medium" md-align-trigger>
        <md-button md-menu-trigger class="md-icon-button">
          <md-icon>filter_list</md-icon>
        </md-button>

        <FiltersAccounts></FiltersAccounts>
      </md-menu> -->
    </md-toolbar>
    <md-list class="md-elevation-4 contacts-list" v-if="accountContacts.length > 0">
      <md-list-item v-for="contact in accountContacts" :key="contact.id">
        <div class="row col-12 m-0 p-0 one-lead">
      <div :class="'lead-day-container'">
        <div class="lead-day">
          <div v-on:click="logAction(contact,'Phone')" class="md-title">
               <span v-if="contact.phone">
                  <a :href="'tel:'+contact.phone"><md-icon class="md-primary mx-1 mb-3 text-white">phone</md-icon></a>
               </span>

          </div>
          <div v-on:click="logAction(contact,'Email')" class="md-title">
              <span v-if="contact.email">
                    <a :href="'mailto:'+contact.email" target="_blank"><md-icon class="md-primary mx-1 text-white">email</md-icon></a>
              </span>
          </div>
          <div class="md-title">
              <router-link :to="{ name: 'Lead Detail', params: { id: contact.id } }">
            <md-icon class="md-primary mx-1 mt-3 text-white">note_add</md-icon>
            </router-link>

          </div>
        </div>
      </div>
      <div class="lead-contacts lead-content" >
        <router-link :to="{ name: 'Lead Detail', params: { id: contact.id } }">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 py-3">
            <Avatar
              :name="contact.displayName"
              :photoUrl="contact.photoUrl"
              class="ml-3 float-left"
            />
            <div class="float-left mx-2">
              <div class="main-info">

                <span class="md-title contact-name">
                  {{
                  contact.displayName
                  }}
                </span>
                <span :class="'custom-badge-1 ml-2 text-white badge-color-0 status-'+contact.dueTime" >
                  {{contact.reviewTextTitle}} {{contact.reviewTextSubHeading}}
                </span>
              </div>
              <div class="contact-info my-2">
                <span  v-on:click="logAction(contact,'Phone')" v-if="contact.phone">
                  <md-icon class="md-primary mx-1">phone_iphone</md-icon>
                  <a :href="'tel:'+contact.phone">{{ contact.phone }}</a>
                </span>
                <span v-on:click="logAction(contact,'Email')" v-if="contact.email">
                  <md-icon class="md-primary mx-1">email</md-icon>
                  <a :href="'mailto:'+contact.email">{{ contact.email }}</a>
                </span>
              </div>
              <div class="contact-info my-2">
                Last Updated Date:
                {{ getShortDate(contact.lastUpdated.seconds) }}</br>
                contact Created Date:  {{ getShortDate(contact.createdAt.seconds) }}</br>

                contact logged status: {{ contact.leadStatus }}
                </div>
              <div class="status-info py-2">
                <span
                  v-for="(tag, index) in contact.tags"
                  :key="index"
                  class="custom-badge mr-2"
                  :class="'badge-color-' + index"
                >{{ tag.name }}
                </span>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-small-size-100 lead-info-container">
            <md-card v-for="(info, index) in contact.leadsInfo" :key="index" class="lead-info">
              <md-card-header>
                <div>{{ info.title }}</div>
              </md-card-header>

              <md-card-content class="text-wrap">{{ info.description }}</md-card-content>
            </md-card>
          </div>
        </div>
        </router-link>
      </div>
    </div>
      </md-list-item>
      <!-- <md-card
        v-for="contact in accountContacts"
        :key="contact.id"
        md-with-hover
        class="m-2 bg-light one-contact w-80"
        @click.native="leadDetail(contact.id)"
      >
        <md-card-header>
          <md-card-header-text>
            <Avatar :name="contact.displayName" :photoUrl="contact.photoUrl" class="float-left mr-2" />
            <div class="float-left">
              <div class="row main-info mb-1">
                <span class="md-title
                 contact-name">
                  {{
                  contact.displayName
                  }}
                </span>
                <span :class="'custom-badge-1 ml-2 text-white badge-color-0 status-'" >
                  {{contact.reviewTextTitle}} {{contact.reviewTextSubHeading}}
                </span>
                <span :class="'custom-badge-1 ml-2 text-white badge-color-0 status-'" >
                  {{contact.reviewTextTitle}} {{contact.reviewTextSubHeading}}
                </span>
                </div>
                <div class="contact-info my-2">
                <span class="contact-info" v-if="contact.phone">
                  <md-icon class="md-primary ml-2">phone_iphone</md-icon>
                  {{ contact.phone }}
                </span>
                <span class="contact-info" v-if="contact.email">
                  <md-icon class="md-primary ml-2">email</md-icon>
                  {{ contact.email }}
                </span>
              </div>

              <div class="contact-info my-2">
                Last Updated Date:
                {{ getShortDate(contact.lastUpdated.seconds) }}</br>
                Lead Created Date:  {{ getShortDate(contact.leadCreated.seconds) }}</br>
                Lead logged status: {{ contact.leadStatus }}
                </div>
              <div class="row status-info">
                <span
                  v-for="(tag, index) in contact.tags"
                  :key="index"
                  class="custom-badge mr-2"
                  :class="'badge-color-' + index"
                >{{ tag.name }}</span>
              </div>
            </div>
          </md-card-header-text>
        </md-card-header>
      </md-card> -->
      <div class="md-layout-item md-small-size-100 lead-info-container">
            <md-card v-for="(info, index) in contacts.leadsInfo" :key="index" class="lead-info">
              <md-card-header>
                <div>{{ info.title }}</div>
              </md-card-header>

              <md-card-content class="text-wrap">{{ info.description }}</md-card-content>
            </md-card>
          </div>
    </md-list>

    <md-list-item v-if="scrollProgress">
      <!-- <div class="w-100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></div> -->
      <md-progress-spinner md-mode="indeterminate" :md-stroke="3" class="mx-auto"></md-progress-spinner>
    </md-list-item>
  </div>
</template>

<script>
import { db } from "@/firebase/init";
import moment from "moment";
import { mapGetters } from "vuex";

import Avatar from "@/components/blocks/Avatar";
import ContactDialog from "@/components/blocks/forms/ContactDialog";
import ContactEditDialog from "@/components/blocks/dialogs/ContactEdit";
import ContactModel from "@/models/ContactModel";
import FiltersAccounts from "@/components/blocks/filters/Accounts";
import firebase from "firebase";
import LogAcivityMixin from "@/mixins/LogActivityMixin";

let contactsRef = db.collection("contacts")
let accountsRef = db.collection("accounts").orderBy("accountName");
let lastVisible;

export default {
  name: "Contacts",
  components: {
    Avatar,
    ContactDialog,
    ContactEditDialog,
    FiltersAccounts,
  },
  mixins: [LogAcivityMixin],

  data() {
    return {
      searchText: "",
      contacts: [],
      searchResults: false,
      showEditDialog: false,
      editedContact: null,
      fetchedAccounts: [],
      selectedTimeFilter: 'lastUpdated',
      closeOnSelect:true,
      closeOnClick:true,
      scrollProgress: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      accounts: "accounts",
      selectedAccounts: "selectedAccounts",
    }),
    accountContacts() {


      let contacts = this.contacts.filter(
        (contact) => this.selectedAccounts.indexOf(contact.accountId
        ) !== -1
      );


      if (this.searchText) {
        contacts = contacts.filter((contact) => {
          return (
            contact.displayName.toLowerCase().search(this.searchText) !== -1 ||
            (contact.hasOwnProperty("email") &&
              contact.email.toLowerCase().search(this.searchText) !== -1) ||
            (contact.hasOwnProperty("phone") &&
              typeof contact.phone === "string" &&
              contact.phone.search(this.searchText) !== -1)
          );
        });
      }

      return contacts;
    },
  },
  watch: {
    accounts: function (newValue, oldValue) {
      this.fetchContacts();
    },
    selectedTimeFilter:function() {

      this.fetchContacts();

    },
    selectedAccounts: function (newValue, oldValue) {
       this.fetchContacts();

    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandler);


    //this.fetchContacts();

  },
  created() {


    let user = this.user.data;
    if (user.hasOwnProperty("userDoc") && user.userDoc) {
      let access = user.userDoc.access;
    }
    // subscribe to changes to the 'messages' collection
   this.fetchContacts();

      },
  methods: {
    getShortDate(ms) {
      //return moment(ms).format("M/D");
      const date= new Date(moment(ms * 1000).format("L"));
      let finalDate= `${date.getDate()}  ${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()} `
     return finalDate;
    },
    fetchContacts() {

      if (!this.accounts.length || !this.selectedAccounts.length) {
        console.log("if ")
        return;
      }
      else
{      let newAccounts = this.selectedAccounts.filter(
        (acountId) => !this.fetchedAccounts.includes(acountId)
      );

      if (!newAccounts.length) {
        return;
      }

      this.$store.dispatch("setSending", true);

      this.fetchedAccounts = [...this.selectedAccounts];
      contactsRef
        .where("accountId", "in", this.selectedAccounts).orderBy("displayName").limit(20).orderBy(this.selectedTimeFilter,"desc")
         //.where(this.selectedTimeFilter,"==",this.selectedTimeFilter)
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            this.$store.dispatch("setSending", false);
            let doc = change.doc;

            let contact = ContactModel(doc, this.accounts);




            if (change.type == "modified") {
              //remove the contact
              this.contacts = this.contacts.filter(
                (contact) => contact.id !== doc.id
              );
              this.contacts.unshift(contact); //add contact on top
            } else if (change.type == "added") {
              this.contacts.unshift(contact);
            }
            lastVisible = doc;
          });
        });

        this.$store.dispatch("setSending", false);
      }
    },
    edit(contact) {
      this.showEditDialog = true;
      this.editedContact = contact;
    },
    leadDetail(contactId) {
      this.$router.push({ name: "ContactDetail", params: { id: contactId } });
    },
    scrollHandler(event) {
      //let scrollableElement = document.querySelector(".contacts-list");

      if (this.contacts.length < 20) {
        return;
      }
      //let firstMsg = jQuery('.collection-item:first');
      if (
        !this.scrollProgress &&
        window.innerHeight + window.scrollY + 100 >= document.body.offsetHeight
      ) {
        this.scrollProgress = true;
        this.$store.dispatch("setSending", true);
        contactsRef
          .where("accountId", "in", this.selectedAccounts)
          .orderBy(this.selectedTimeFilter,"desc")
          .startAfter(lastVisible)
          .get()
          .then((querySnapshot) => {
            this.scrollProgress = false;
            this.$store.dispatch("setSending", false);
            querySnapshot.forEach((doc) => {

              let contactObj = ContactModel(doc, this.accounts);

              this.contacts.push(contactObj);
              //this.scrollTo();
              lastVisible = doc;
            });

            //this.messages = this.messages.sort((a, b) => a.secs - b.secs);
          });
          this.$store.dispatch("setSending", false);
          this.scrollProgress = false;
      }

    },
    // filterContacts(){
    //   this.searchResults = this.contacts.filter((contact) => {
    //     return contact.displayName.toLowerCase().search(this.searchText) !== -1
    //     || contact.email.toLowerCase().search(this.searchText) !== -1
    //     || contact.phone.search(this.searchText) !== -1
    //   });
    // },
    // filterContactsByAccount(){
    //   this.contacts = this.contacts.filter(contact => false);
    // }
    logAction(contact,action){

      let user = this.user;
      let lead = this.leadObj;

      let messageText = user.data.displayName + " clicked on the "+ action;
      contactsRef
          .doc(contact.id)
          .update({
            lastContacted: firebase.firestore.Timestamp.now(),
            lastContactedMessage: action
          })
          .then(() => {
            this.logActivity(
                messageText,
                contact.accountId,
                contact.id,
                {
                  call: true,
                  updatedBy: {
                    name: user.data.displayName,
                    id: user.data.uid,
                  },
                }
            );
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", action+" logged successfully");
          })
          .catch((err) => {
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", err);
          });


    }

  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollHandler);
  },
};
</script>

<style lang="scss">
.lead-info-container {
    overflow-y: auto;

    .md-theme-default.lead-info {
      width: 200px;
      background-color: #510fa8;
      color: white;
      float: left;
      border-radius: 10px;
      margin: 5px;

      .md-card-header {
        font-size: 16px;
        font-weight: 500;
      }

      .md-card-content {
        font-size: 13px;
      }
    }
  }


.leads .md-list.md-theme-default .md-list-item-container:not(.md-list-item-default):not(.md-list-item-expand):not([disabled]):hover {
  background: transparent;
}
.one-contact {

  .contact-name {
    min-width: 200px;
    display: block;
    float: left;
    &.md-title {
      font-weight: 600;
      font-size: 16px;
      margin-left: 10px;
    }
  }
  .contact-info {
      font-size: 13px;

      .md-icon {
        font-size: 18px !important;
      }
    }
}
.lead-contacts{
  width:  calc(100% - 100px) !important;
  height: 200px !important;
}
</style>
