<template>
  <div class="settings-channels">
    <md-table>
      <md-table-row>
        <md-table-head class="bg-primary">Channel Name</md-table-head>
        <md-table-head class="bg-primary">Channel System</md-table-head>
        <md-table-head class="bg-primary">Channel Info</md-table-head>
        <md-table-head class="bg-primary">Configuration</md-table-head>
        <md-table-head class="bg-primary">Notifications</md-table-head>
        <md-table-head class="bg-primary">Action</md-table-head>
      </md-table-row>
      <ItemChannel v-for="channel in filteredChannels" :key="channel.id" :channel="channel" />
    </md-table>
  </div>
</template>

<script>
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";

import ItemChannel from "@/components/blocks/items/Channel";

export default {
  name: "SettingsChannels",
  props: ["selectedAccount"],
  components: {
    ItemChannel,
  },
  data() {
    return {
      channels: [],
    };
  },
  // firestore: {
  //   channels: db.collection('channels'),
  // },
  watch: {
    selectedAccount: {
      immediate: true,
      handler(account) {
        this.$store.dispatch("setSending", true);
        this.$bind(
          "channels",
          db.collection("channels").where("accountId", "==", account.id)
        ).then(() => {
          this.$store.dispatch("setSending", false);
        });
      },
    },
    notificationChannels: function(newValue, oldValue) {
      db.collection("users").doc(this.user.data.uid).update({notificationChannels: newValue});
    }
  },
  computed: {
    ...mapGetters({
      accounts: "accounts",
      notificationChannels: "notificationChannels",
      user: "user"
    }),
    filteredChannels() {
      let channels = this.channels.filter(
        (channel) => this.selectedAccount.id === channel.accountId
      );
      return channels;
    },
  },
  methods: {},
};
</script>

<style></style>