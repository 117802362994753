import { db } from "@/firebase/init";
import firebase from "firebase";

export default {
    methods: {
        async logActivity(messageText, accountId, contactId, details) {
            await db
                .collection("messages")
                .doc()
                .set(this.logActivityObject(messageText, accountId, contactId, details));
        },
        logActivityObject(messageText, accountId, contactId, details) {
            return {
                accountId: accountId,
                linkedContact: contactId,
                messageText: messageText,
                messageTime: firebase.firestore.Timestamp.now(),
                messageType: "activity",
                direction: "outgoing",
                details: details
            };
        }
    }

}