<template>
  <div>
    <md-table v-model="contacts" md-card @md-selected="onSelect">
      <md-table-toolbar>
        <h1 class="md-title">Contacts</h1>
      </md-table-toolbar>

      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        :class="getClass(item)"
        md-selectable="single"
      >
        <!-- <md-table-cell md-label="ID" md-sort-by="id" md-numeric>{{ item.id }}</md-table-cell> -->
        <md-table-cell md-label="First Name" md-sort-by="firstName">{{ item.firstName }}</md-table-cell>
        <md-table-cell md-label="Last Name" md-sort-by="lastName">{{ item.lastName }}</md-table-cell>
        <md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
        <md-table-cell md-label="Phone" md-sort-by="phone">{{ item.phone }}</md-table-cell>
      </md-table-row>

      <md-table-empty-state
        v-if="loaded"
        md-label="No contacts found"
        :md-description="`No contact found with auto search.`"
      >
        <md-button class="md-primary md-raised" @click="openContact()">Create New Contact</md-button>
      </md-table-empty-state>
    </md-table>
    <md-card-actions>
      <md-button class="md-secondary" @click="openContact()">Create New Contact</md-button>
      <md-button type="button" class="md-secondary" @click="close()">Cancel</md-button>
      <md-button
        type="button"
        class="md-dense md-raised md-primary"
        :disabled="sending || !contacts.length"
        @click="linkContact()"
      >Link</md-button>
    </md-card-actions>
  </div>
</template>
<script>
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";
let threadRef = db.collection("threads");

export default {
  name: "LinkContacts",
  props: ["contact", "thread"],
  data() {
    return {
      selected: {},
      contacts: [],
      currentSort: "name",
      currentSortOrder: "asc",
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      sending: "sending",
    }),
  },
  mounted() {
    this.searchContacts();
  },
  methods: {
    searchContacts() {
      let contact = this.contact;
      if (contact.phone) {
        let contacts = db
          .collection("contacts")
          .where("accountId", "==", contact.accountId)
          .where("phone", "==", contact.phone)
          .limit(5)
          .get()
          .then((querySnapshot) => {
            this.loaded = true;
            querySnapshot.forEach((doc) => {
              let contactData = doc.data();
              let contact = { id: doc.id, ...contactData, choose: "Choose" };
              this.contacts.push(contact);
            });
          });
      } else if (contact.email) {
        let contacts = db
          .collection("contacts")
          .where("email", "==", contact.email)
          .where("accountId", "==", contact.accountId)
          .limit(5)
          .get()
          .then((querySnapshot) => {
            this.loaded = true;
            querySnapshot.forEach((doc) => {
              let contactData = doc.data();
              let contact = { id: doc.id, ...contactData };
              this.contacts.push(contact);
            });
          });
      } else {
        console.log(contact);
        let contacts = db
          .collection("contacts")
          .where("firstName", "==", contact.firstName)
          .where("lastName", "==", contact.lastName)
          .where("accountId", "==", contact.accountId)
          .limit(5)
          .get()
          .then((querySnapshot) => {
            this.loaded = true;
            querySnapshot.forEach((doc) => {
              let contactData = doc.data();
              let contact = { id: doc.id, ...contactData };
              this.contacts.push(contact);
            });
          });
        //this.loaded = true;
      }
    },
    linkContact() {
      if (!this.selected.id) {
        alert("Please select a contact from table");
        return;
      }
      const selected = this.selected;
      const contact = this.contact;
      let sanityUpdates = {};
      const items = ["firstName", "lastName", "email", "phone", "photoUrl"];
      items.forEach((item) => {
        if (!selected[item] && contact[item]) {
          sanityUpdates[item] = contact[item];
        }
      });

      if (Object.keys(sanityUpdates).length) {
        db.collection("contacts").doc(selected.id).update(sanityUpdates);
      }

      threadRef
        .doc(this.thread.id)
        .update({
          linkedContact: this.selected.id,
        })
        .then(() => {
          this.$emit("close");
        })
        .catch((err) => {
          alert(err);
        });
    },
    getClass: ({ id }) => ({
      "md-primary": true,
    }),
    onSelect(item) {
      this.selected = item;
    },
    close() {
      this.$emit("close");
    },
    openContact() {
      this.$emit("openContact");
    },
  },
};
</script>