<template>
    <span>
        <StatusButton
            v-if="thread.status !== 'important'"
            :item="thread"
            collection="threads"
            status="important"
            text="Move to Important"
            icon="label_important"
        />
        <ActionInbox v-if="thread.status !== 'I'" :item="thread" collection="threads" />
        <ActionArchive v-if="thread.status !== 'A'" :item="thread" collection="threads" />
        <ActionTrash v-if="thread.status !== 'T'" :item="thread" collection="threads" />
        
        <!-- <ActionStar :item="selectedThread" collection="threads" /> -->
    </span>
</template>
<script>
import ActionArchive from '@/components/blocks/actions/Archive';
import ActionInbox from '@/components/blocks/actions/Inbox';
import ActionStar from '@/components/blocks/actions/Star';
import ActionTrash from '@/components/blocks/actions/Trash';
import StatusButton from '@/components/blocks/actions/StatusButton';

export default {
    name: "ThreadActions",
    props: ["thread"],
    components: {
        ActionArchive,
        ActionInbox,
        ActionTrash,
        ActionStar,
        StatusButton
    }
}
</script>