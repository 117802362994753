// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import firebase from "firebase";
import store from "@/store/index";
import VueChatScroll from "vue-chat-scroll";
import { db } from "@/firebase/init";
import { firestorePlugin } from "vuefire";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import Axios from "axios";

/*
 * @todo
 * Include only required VueMaterial components
 * for beter productivity
 */
import VueMaterial from "vue-material";
import "@/assets/scss/app.scss";
import accountsMixin from "@/mixins/AccountsMixin";

Vue.use(VueChatScroll);
Vue.use(VueMaterial);
Vue.use(firestorePlugin);

Vue.config.productionTip = false;

/*
Sentry.init({
  dsn:
    "https://7d91516a01ae41f7b67ea875c9c2f46d@o393934.ingest.sentry.io/5243516",
  integrations: [new VueIntegration({ Vue, attachProps: true })],
});
*/

let app = null;

// wait for firebase auth to init before creating the app
firebase.auth().onAuthStateChanged((user) => {
  let userDoc = null;

  store.dispatch("fetchUser", { user, userDoc });

  if (user) {
    let refUser = db.collection("users").doc(user.uid);

    refUser.get().then((doc) => {
      if (doc.exists) {
        userDoc = doc.data();
        store.dispatch("fetchUser", { user, userDoc });
        store.dispatch("setNotificationChannels", userDoc.notificationChannels || []);
      }
    });

    // let accountsRef = db.collection("accounts").orderBy("accountName");
    // let accounts = [];
    // accountsRef.get().then(function(querySnapshot) {
    //   querySnapshot.forEach(function(doc) {
    //       let accountData = doc.data();
    //       let account = {id: doc.id, name: accountData.accountName}
    //       accounts.push(account);
    //   });
    // }).then(function(){
    //   store.dispatch("fetchAccounts", accounts);
    // })

    user
      .getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        store.dispatch("storeUserToken", idToken);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  Vue.prototype.$http = Axios;
  const API_URL =
    process.env.VUE_APP_API_URL ||
    //"https://999de77092b4.ngrok.io/" ||
    //"http://localhost:8080" ||
    "https://datasync.studiobackoffice.com/";
  Vue.prototype.$http.defaults.headers.post["Accept"] = "application/json";
  Vue.prototype.$http.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";

  Vue.prototype.$http.defaults.baseURL = API_URL;

  // init app if not already created
  if (!app) {
    app = new Vue({
      el: "#app",
      router,
      store,
      mixins: [accountsMixin],
      components: { App },
      template: "<App/>",
      render: (h) => h(App),
      mounted() {
        this.refreshAccounts(user);
      },
    });
  }
});

firebase.auth().onIdTokenChanged(function (user) {
  if (user) {
    user
      .getIdToken()
      .then(function (idToken) {
        store.dispatch("storeUserToken", idToken);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
});
