<template>
  
   <li @click="selectThread()">
            <i :class="'material-icons circle ' + thread.color">{{thread.initials}}</i>
            <span class="teal-text">{{ thread.name }}</span>
            <span class="grey-text time">{{ thread.content }}</span>
          </li>
  
</template>

<script>
export default {
  name: "Thread",
  props: ["thread"],
  methods: {
    selectThread(){
      this.$emit("selectThread", this.thread)
    }
  }
};
</script>

