import moment from "moment";

export default function ActionModel(actionObj, accounts) {
  
  var todoInfoBlocks = [];
  
  // if (actionObj.displayAccount) {
  //   todoInfoBlocks.push({
  //     title: "Account",
  //     description: actionObj.displayAccount.replace("\n", "<br />"),
  //   });
  // }
  // if (actionObj.displayTrial) {
  //   todoInfoBlocks.push({
  //     title: "Trial",
  //     description: actionObj.displayTrial.replace("\n", "<br />"),
  //   });
  // }

  // if (actionObj.lastContacted && typeof actionObj.lastContacted !== "string") {
  //   todoInfoBlocks.push({
  //     title: "Last Contacted",
  //     description: actionObj.lastContactedMessage + " - " + dateFormat(actionObj.lastContacted.toDate()),
  //   });
  // }
  const days = leadDays(actionObj);
  return {
    id: actionObj.id,
    accountId: actionObj.accountId,
    contracts: transformContracts(actionObj.contracts),
    contactInfo: {
      id: "",
      accountId: actionObj.accountId,
      displayName: actionObj.displayName,
      phone: actionObj.phone,
      email: actionObj.email,
     // photoUrl: actionObj.photoUrl
    },
    contact: {
      id: actionObj.id,
      firstName: actionObj.firstName,
      lastName: actionObj.lastName,
     // integrationId: actionObj.integrationId,
      email: actionObj.email,
      phone: actionObj.phone,
      accountId: actionObj.accountId,
    },
    days: days,
   // integrationId: actionObj.integrationId,
   todoInfo: todoInfoBlocks,
    todoStatus: actionObj.todoStatus,
    //lastUpdated: actionObj.lastUpdated,
    todoCreated: actionObj.todoCreated,
    todo: actionObj.todo,
    leadID: actionObj.leadID,
    // notes: actionObj.notes,
    // tags: actionObj.tags,
    // tagsShow: TransformTags(actionObj, accounts),
    // visits: transformVisits(actionObj.visits),
    // dueTime: dueTime(days, actionObj.todoStatus),
    // reviewText: reviewText(days, actionObj.todoStatus),
    // reviewTextTitle: reviewTextTitle(days, actionObj.todoStatus),
    // reviewTextSubHeading: reviewTextSubHeading(days, actionObj.todoStatus)
  };
}


function transformContracts(contracts) {
  if (!contracts) {
    return []
  }
  contracts = Object.values(contracts);
  contracts.sort((a, b) => b.startDate.seconds - a.startDate.seconds);
  return contracts;
}

function transformVisits(visits) {
  if (!visits) {
    return []
  }
  visits = Object.values(visits);
  visits.sort((a, b) => b.startDate.seconds - a.startDate.seconds);
  return visits;
}

function leadDays(actionObj) {
  let days = "";


  if (actionObj.leadCompletedDate) {
    days = moment().diff(moment(actionObj.todoCompletedDate.toDate()), "days")
  } else if (actionObj.leadLost) {
    days = moment().diff(moment(actionObj.todoLost.toDate()), "days")
  } else if (actionObj.leadReviewDate) {
    days = moment().diff(moment(actionObj.todoReviewDate.toDate()), "days")
  } else if (actionObj.leadCreated) {
    days = moment().diff(moment(actionObj.todoCreated.toDate()), "days")
  } else if (actionObj.createdAt) {
    //days = moment().diff(moment(actionObj.createdAt.toDate()), "days")
  }
  return days;
}

function dueTime(days, status) {
  if (status == 'lead') {
    if (days < 0) {
      return "future";
    } else if (days > 15) {
      return "overdue";
    } else {
      return "due"
    }
  } else {
    return status
  }

}

function reviewTextTitle(days, status) {
  let text = ""
  if (status == 'lead') {
    if (days > -1) {
      text = "Review Due"
    } else if (days > -2) {
      text = "Review"
    } else {
      text = "Review in"
    }
  } else if (status == 'won') {
    text = 'Won'
  } else if (status == 'lost') {
    text = 'Lost'
  }
  return text;
}

function reviewTextSubHeading(days, status) {
  let text = ""
  if (days > 13) {
    const week = Math.round(days / 7)
    text = week + " weeks ago"
  } else if (days > 7) {
    text = "1 week ago"
  } else if (days > 1) {
    text = days + " days ago"
  } else if (days > 0) {
    text = "Yesterday"
  } else if (days > -1) {
    text = "Today"
  } else if (days > -2) {
    text = "Tomorrow"
  } else if (days > -7) {
    text = Math.abs(days) + " days"
  } else if (days > -14) {
    text = "1 week"
  } else {
    const week = Math.round(Math.abs(days) / 7)
    text = week + " weeks"
  }

  return text;
}

function reviewText(days, status) {
  return reviewTextTitle(days, status) + " " + reviewTextSubHeading(days, status);
}

// function reviewText(days) {
//   let text = ""
//   if (days > 13) {
//     const week = Math.round(days / 7)
//     text = "Review Due " + week + " weeks ago"
//   } else if (days > 7) {
//     text = "Review Due 1 week ago"
//   } else if (days > 1) {
//     text = "Review Due " + days + " days ago"
//   } else if (days > 0) {
//     text = "Review Due Yesterday"
//   } else if (days > -1) {
//     text = "Review Due Today"
//   } else if (days > -2) {
//     text = "Review Tomorrow"
//   } else if (days > -7) {
//     text = "Review in " + Math.abs(days) + " days"
//   } else if (days > -14) {
//     text = "Review in 1 week"
//   } else {
//     const week = Math.round(Math.abs(days) / 7)
//     text = "Review in " + week + " weeks"
//   }

//   return text;
// }