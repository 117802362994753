<template>
  <div>
    <md-table>
      <md-table-row v-if="editForm == 'name'">
        <md-table-cell md-numeric>Name</md-table-cell>
        <md-table-cell>
          <form novalidate class="md-layout" @submit.prevent="updateName">
            <md-field md-inline>
              <label>Name</label>
              <md-input v-model="displayName" required></md-input>
            </md-field>
          </form>
        </md-table-cell>
        <md-table-cell md-numeric>
          <md-button type="button" class="md-secondary" @click="updateName()" :disabled="sending">
            <md-icon>edit</md-icon>Update
          </md-button>
          <md-button type="button" class="md-secondary" @click="cancelEdit()" :disabled="sending">
            <md-icon>cancel</md-icon>Cancel
          </md-button>
        </md-table-cell>
      </md-table-row>
      <md-table-row v-else>
        <md-table-cell md-numeric>Name</md-table-cell>
        <md-table-cell>{{user.data.displayName}}</md-table-cell>
        <md-table-cell md-numeric>
          <md-button
            type="button"
            class="md-secondary"
            :disabled="sending"
            @click="showNameUpdate()"
          >
            <md-icon>edit</md-icon>Edit
          </md-button>
        </md-table-cell>
      </md-table-row>

      <md-table-row v-if="editForm == 'email'">
        <md-table-cell md-numeric>Email</md-table-cell>
        <md-table-cell>
          <form novalidate class="md-layout" @submit.prevent="updateEmail" v-if="reAuth">
            <md-field>
              <label>Please enter your new email</label>
              <md-input v-model="email" required></md-input>
            </md-field>
          </form>
          <form novalidate class="md-layout" @submit.prevent="checkPassword" v-else>
            <md-field>
              <label>Please confirm your password</label>
              <md-input v-model="password" type="password" required></md-input>
            </md-field>
          </form>
        </md-table-cell>
        <md-table-cell md-numeric>
          <md-button type="button" class="md-secondary" @click="updateEmail()" :disabled="sending">
            <md-icon>edit</md-icon>Update
          </md-button>
          <md-button type="button" class="md-secondary" @click="cancelEdit()" :disabled="sending">
            <md-icon>cancel</md-icon>Cancel
          </md-button>
        </md-table-cell>
      </md-table-row>
      <md-table-row v-else>
        <md-table-cell md-numeric>Email</md-table-cell>
        <md-table-cell>{{user.data.email}}</md-table-cell>
        <md-table-cell md-numeric>
          <md-button
            type="button"
            class="md-secondary"
            :disabled="sending"
            @click="showEmailUpdate()"
          >
            <md-icon>edit</md-icon>Edit
          </md-button>
        </md-table-cell>
      </md-table-row>
      <md-table-row v-if="editForm == 'password'">
        <md-table-cell md-numeric>Password</md-table-cell>
        <md-table-cell>
          <form novalidate class="md-layout" @submit.prevent="updatePassword" v-if="reAuth">
            <md-field>
              <label>Please enter your new password</label>
              <md-input v-model="password" required></md-input>
            </md-field>
          </form>
          <form novalidate class="md-layout" @submit.prevent="checkPassword" v-else>
            <md-field>
              <label>Please confirm your password</label>
              <md-input v-model="confirmPassword" type="password" required></md-input>
            </md-field>
          </form>
        </md-table-cell>
        <md-table-cell md-numeric>
          <md-button
            type="button"
            class="md-secondary"
            @click="updatePassword()"
            :disabled="sending"
          >
            <md-icon>edit</md-icon>Update
          </md-button>
          <md-button type="button" class="md-secondary" @click="cancelEdit()" :disabled="sending">
            <md-icon>cancel</md-icon>Cancel
          </md-button>
        </md-table-cell>
      </md-table-row>
      <md-table-row v-else>
        <md-table-cell md-numeric>Password</md-table-cell>
        <md-table-cell>********</md-table-cell>
        <md-table-cell md-numeric>
          <md-button
            type="button"
            class="md-secondary"
            :disabled="sending"
            @click="showPasswordUpdate()"
          >
            <md-icon>edit</md-icon>Edit
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
export default {
  name: "Profile",
  computed: {
    ...mapGetters({
      user: "user",
      sending: "sending"
    })
  },
  data() {
    return {
      editForm: "",
      displayName: "",
      email: "",
      confirmPassword: "",
      password: "",
      reAuth: false
    };
  },
  methods: {
    showNameUpdate() {
      this.displayName = this.user.data.displayName;
      this.editForm = "name";
    },
    cancelEdit() {
      this.editForm = false;
    },
    updateName() {
      this.$store.dispatch("setSending", true);
      firebase
        .auth()
        .currentUser.updateProfile({
          displayName: this.displayName
        })
        .then(
          () => {
            this.$store.dispatch("setSending", false);
            this.$store.dispatch(
              "showSnackbar",
              "Profile Updated Successfully"
            );
          },
          error => {
            this.$store.dispatch("showSnackbar", error);
            this.$store.dispatch("setSending", false);
            // An error happened.
          }
        );
      this.user.data.displayName = this.displayName;
      this.editForm = false;
    },
    showEmailUpdate() {
      this.email = this.user.data.email;
      this.editForm = "email";
    },
    updateEmail() {
      this.$store.dispatch("setSending", true);
      firebase
        .auth()
        .currentUser.updateEmail(this.email)
        .then(
          () => {
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", "Email Updated Successfully");
          },
          error => {
            this.$store.dispatch("showSnackbar", error);
            this.$store.dispatch("setSending", false);
            // An error happened.
          }
        );
      this.user.data.email = this.email;
      this.editEmail = false;
    },
    showPasswordUpdate() {
      this.editForm = "password";
    },
    updatePassword() {
      this.$store.dispatch("setSending", true);
      firebase
        .auth()
        .currentUser.updatePassword(this.password)
        .then(
          () => {
            this.$store.dispatch("setSending", false);
            this.$store.dispatch(
              "showSnackbar",
              "Password Updated Successfully"
            );
          },
          error => {
            this.$store.dispatch("showSnackbar", error);
            this.$store.dispatch("setSending", false);
            // An error happened.
          }
        );
      this.editForm = false;
    },
    checkPassword() {
      this.$store.dispatch("setSending", true);
      firebase
        .auth()
        .signInWithEmailAndPassword(this.user.data.email, this.confirmPassword)
        .then(
          () => {
            this.confirmPassword = "";
            this.$store.dispatch("setSending", false);
            this.reAuth = true;
            //this.$store.dispatch("showSnackbar", "Email Updated Successfully");
          },
          error => {
            this.confirmPassword = "";
            this.$store.dispatch("showSnackbar", error);
            this.$store.dispatch("setSending", false);
            // An error happened.
          }
        );
    }
  }
};
</script>