import { db } from "@/firebase/init";
import LinkContacts from "@/components/blocks/contact/LinkContacts";
import ContactForm from "@/components/blocks/forms/Contact";
const threadRef = db.collection("threads");

export default {
  components: {
    LinkContacts,
    ContactForm
  },
  data() {
    return {
      showEditDialog: false,
      showLinkDialog: false
    };
  },
  computed: {
    threadToContact() {
      let thread = this.thread;

      let firstName = null;
      let lastName = null;

      if (thread.fullData.displayName !== "Display Name Missing") {
        let displayName = thread.fullData.displayName.split(" ");
        firstName = displayName.shift();
        lastName = displayName.join(" ");
      }

      if (this.thread.fullData.tempContact.firstName) {
        firstName = this.thread.fullData.tempContact.firstName;
      }

      if (this.thread.fullData.tempContact.lastName) {
        lastName = this.thread.fullData.tempContact.lastName;
      }

      return {
        firstName: firstName,
        lastName: lastName,
        email: thread.fullData.email,
        phone: thread.fullData.phone,
        accountId: thread.accountId,
        photoUrl: thread.photoUrl
      };
    }
  },
  methods: {
    close() {
      this.showEditDialog = false;
    },
    added(contactId, displayName) {
      threadRef
        .doc(this.thread.id)
        .update({
          linkedContact: contactId,
          displayName: displayName
        })
        .then(() => {
          this.$store.dispatch("showSnackbar", "Contact is linked successfully")
        })
        .catch((err) => {
          this.$store.dispatch("showSnackbar", err)
        });
    },
  }
}