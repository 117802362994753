<template>
  <div class="settings">
    <md-app>
      <md-app-drawer :md-active.sync="statusCollapse" md-persistent="full">
        <md-toolbar>
          <h3 class="md-title">
            Settings
            <AskToken />
          </h3>
        </md-toolbar>
        <md-list md-expand-single="true" class="account-list md-elevation-4 custom-scrollbar">
          <md-list-item
            @click="showData('', 'User')"
            :class="{'active': curSetting === 'User'}"
            :to="{name:'Settings'}"
            class="sub-setting"
          >
            <span class="md-subheading">User Settings</span>
          </md-list-item>
          <md-list-item
            md-expand
            v-for="account in accounts"
            :key="account.id"
            :md-expanded.sync="account.id === expandedAccount"
          >
            <span class="md-list-item-text md-subheading">{{account.name}}</span>
            <md-list slot="md-expand">
              <md-list-item
                class="md-inset sub-setting"
                v-for="setting in settingList"
                :key="`${account.id}-${setting}`"
                @click="showData(account, setting)"
                :class="{'active': account.id === curAccount.id && setting === curSetting}"
                :to="{name: `Settings${setting}`, params: {accountId: account.id}}"
              >
                <span class="md-subheading">{{setting}}</span>
              </md-list-item>
            </md-list>
          </md-list-item>
          <!-- <DialogNewAccount class="ml-auto" /> -->
          <md-button
            class="md-primary ml-auto"
            :to="{name: 'New Account', params: {nextRoute: 'Settings'} }"
          >
            <md-icon>add</md-icon>
            <span>Add Account</span>
          </md-button>
        </md-list>
      </md-app-drawer>
      <md-app-toolbar>
        <md-button class="md-icon-button md-small-show" @click="toggleCollapse">
          <md-icon>menu</md-icon>
        </md-button>
        <DialogCreateChannel
          v-if="curSetting === 'Channels'"
          class="ml-auto"
          :accountId="curAccount.id"
        />
        <DialogAddUser v-if="curSetting === 'Users'" class="ml-auto" :account="curAccount" />
      </md-app-toolbar>
      <md-app-content>
        <SettingsUser v-if="curSetting === 'User'" />
        <SettingsChannels v-if="curSetting === 'Channels'" :selected-account="curAccount" />
        <SettingsUsers v-if="curSetting === 'Users'" :selected-account="curAccount" />
        <SettingsIntegrations v-if="curSetting === 'Integrations'" :selected-account="curAccount" />
      </md-app-content>
    </md-app>
    <md-snackbar :md-active.sync="snackbar">{{snackbarMessage}}</md-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SettingsUsers from "@/components/pages/settings/Users";
import SettingsChannels from "@/components/pages/settings/Channels";
import SettingsUser from "@/components/pages/settings/User";
import SettingsIntegrations from "@/components/pages/settings/Integrations";
// import DialogNewAccount from "@/components/blocks/dialogs/CreateAccount"

import AskToken from "@/components/blocks/AskToken";
import DialogCreateChannel from "@/components/blocks/dialogs/CreateChannel";
import DialogAddUser from "@/components/blocks/dialogs/AddUser";

export default {
  name: "Settings",
  components: {
    SettingsUsers,
    SettingsChannels,
    SettingsUser,
    SettingsIntegrations,
    // DialogNewAccount,

    AskToken,
    DialogCreateChannel,
    DialogAddUser
  },
  computed: {
    ...mapState({
      accounts: "accounts"
    })
  },
  watch: {
    accounts: {
      immediate: true,
      handler() {
        this.routeNavigate();
      }
    },
    $route(to, from) {
      this.routeNavigate();
    }
  },
  data() {
    return {
      settingList: ["Users", "Channels", "Integrations"],
      curAccount: "",
      curSetting: "",
      snackbar: false,
      snackbarMessage: "",
      statusCollapse: true,
      expandedAccount: false
    };
  },
  mounted() {
    let snackbarMessage = this.$route.params.snackbarMessage;
    if (typeof snackbarMessage !== "undefined") {
      this.snackbar = true;
      this.snackbarMessage = snackbarMessage;
    }
    this.routeNavigate();
  },
  methods: {
    showData(account, setting) {
      this.curAccount = account;
      this.curSetting = setting;
    },
    toggleCollapse() {
      this.statusCollapse = !this.statusCollapse;
    },
    addAccount() {},
    routeNavigate() {
      const accountId = this.$route.params.accountId;
      const route = this.$route.name;
      if (route === "Settings") {
        this.curSetting = "User";
      } else {
        const setting = route.replace("Settings", "");
        let accounts = this.accounts.filter(
          account => account.id === accountId
        );
        if (accounts.length) {
          const account = accounts[0];
          this.showData(account, setting);
          this.expandedAccount = accountId;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.account-list {
  height: calc(100vh - 130px);
  overflow-y: auto;
  .md-list-item-expand.md-active > .md-list-item-content,
  .md-list-item-expand.md-active .router-link-active .md-list-item-content {
    background-color: #430890;
    color: white;
  }
  .sub-setting.active {
    .router-link-active .md-list-item-content {
      background-color: #510fa8;
      color: white;
    }
    .md-list-item-content {
      background-color: #510fa8;
      color: white;
    }
  }
}
.settings {
  .md-drawer {
    width: 300px;
    overflow: hidden;
  }
  .md-app {
    height: calc(100vh - 70px);
  }
}
@media only screen and (max-width: 960px) {
  .settings {
    .md-app {
      height: calc(100vh - 50px);
    }
  }
  .account-list {
    height: calc(100vh - 100px);
  }
}
</style>