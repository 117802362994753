<template>
  <div>
    <md-button class="md-primary md-raised" @click="showDialog = true">{{buttonText}}</md-button>
    <md-dialog :md-active.sync="showDialog" class="iframe-container">
      <iframe v-if="link" :src="link" />
    </md-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogIframe",
  props: {
    buttonText: {},
    link: {},
    show: {
      default: false,
    },
  },
  watch: {
    show: function (newValue, oldValue) {
      this.showDialog = newValue;
    },
  },
  data() {
    return {
      showDialog: this.show,
    };
  },
  methods: {
    onIframeEvent(action, cb) {
      const eventMethod = window.addEventListener
        ? "addEventListener"
        : "attachEvent";
      const removeMethod = window.removeEventListener
        ? "removeEventListener"
        : "detachEvent";
      const eventer = window[eventMethod];
      const messageEvent =
        eventMethod == "attachEvent" ? "onmessage" : "message";

      window[removeMethod](messageEvent, cb, false);
      eventer(messageEvent, cb, false);
    },
  },
  mounted() {
    // Listen to message from child window
    this.$store.dispatch("setManagingChannel", "");
    this.onIframeEvent("init", ({ data }) => {
      if (data.event === "sync:close") {
        this.showDialog = false;
      }
    });
  },
};
</script>

<style lang="scss">
.md-dialog.iframe-container {
  .md-dialog-container {
    display: contents;
    iframe {
      width: 767px;
      height: 500px;
    }
  }
}
</style>
