<template>
  <div>
    <md-dialog :md-active.sync="showEditDialog">
      <md-dialog-title>{{title}}</md-dialog-title>
      <ContactForm :contact="contact" :lockedAccount="true" @close="close()" :threadId="threadId" />
    </md-dialog>
  </div>
</template>

<script>
import ContactForm from "@/components/blocks/forms/Contact";

export default {
  name: "ContactDialog",
  props: {
    showEditDialog: {
      type: Boolean,
      default: false
    },
    contact: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: "Update Contact"
    },
    threadId: {
      type: String,
      default: null
    }
  },
  components: {
    ContactForm
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
