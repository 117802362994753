<template>
  <li
    :class="'collection-item dir-' + message.direction"
    @click="showDialog = true"
    :style="message.messageType !== 'activity' ? 'cursor:pointer' : ''"
  >
  
    <!-- <Avatar :name="message.name" class="float-left"></Avatar> -->
    <!-- <div class="message-body" :class="message.bgClass">
      {{
      message.content
      }}

       onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px';"
       
    </div>-->
    <div class="separator py-3" v-if="dateValue">{{ dateValue }}</div>
    <div class="message-body" :class="message.bgClass">
      <iframe
        v-if="message.html"
        :srcdoc="updatedMessage"
        v-iframe-resize="{ log: true, checkOrigin: false }"
        style=" width: 100%;min-width: 650px;border:0px;"
      />
      <div v-else>{{ message.content }}</div>
      <md-icon v-if="message.isNote">note</md-icon>
    </div>
    <div class="text-gray time">
      {{ readTimestamp(message.timestamp) }}
      <span
        v-if="message.senderName"
      >{{" | "}}{{message.senderName}}</span>
      <span v-if="message.channelType == 'email' ">
        {{" | "}}
        <a href @click.stop.prevent="reply()">Reply</a>
      </span>
      <span v-if="message.files.length">
        {{" | "}} Attachments: 
        <a :href="attachmentUrl(file)" target="_blank" v-for="file in message.files" :key="file.id">{{file.filename}} </a>
      </span>
    </div>
    <div class="text-gray time" v-if="debug">{{ message.id }}</div>
    <md-dialog v-if="message.html" :md-active.sync="showDialog" class="iframe-container">
      <iframe :srcdoc="message.html" />
    </md-dialog>
  </li>
</template>

<script>
import Avatar from "@/components/blocks/Avatar";
import moment from "moment";
import { mapGetters } from "vuex";
import iFrameResize from "iframe-resizer/js/iframeResizer";
export default {
  name: "Message",
  props: ["message", "prevDate","channelId"],
  components: {
    Avatar
  },
  data() {
    return {
      debug: false,
      showDialog: false
    };
  },
  computed: {
    ...mapGetters({
      userToken: "userToken",
    }),
    // a computed getter
    updatedMessage: function() {
      //console.log(this.message.html);
      const iframestring =
        "<script type=text/javascript src=https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.5.3/iframeResizer.contentWindow.js></scr" +
        "ipt>";
      return iframestring + this.message.html;
    },
    dateValue() {
      console.log(this.prevDate);
      let curDate = moment(this.message.timestamp).format("L");
      if (this.prevDate !== curDate) {
        let today = moment().format("L");
        let yesterday = moment()
          .subtract(1, "days")
          .format("L");
        if (curDate === today) {
          return "Today";
        } else if (curDate === yesterday) {
          return "Yesterday";
        } else {
          return moment(this.message.timestamp).format("LL");
        }
      } else {
        return "";
      }
    }
  },
  directives: {
    "iframe-resize": {
      bind(el, binding) {
        iFrameResize(binding.value, el);
      }
    }
  },
  methods: {
    readTimestamp(dateTime) {
      // let today = moment().format("DDMMYYYY");
      // let yesterday = moment()
      //   .subtract(1, "days")
      //   .format("DDMMYYYY");
      // let aDateTime = moment(dateTime).format("DDMMYYYY");

      // let output = moment(dateTime).format("L");
      // if (aDateTime == today) {
      let output = moment(dateTime).format("hh:mm A");
      // } else if (aDateTime == yesterday) {
      //   output = "Yesterday";
      // }
      return output;
    },
    reply() {
      let content = "<p></p><blockquote>" + this.message.html + "</blockquote>";
      this.$store.dispatch("setEditorContent", content);
    },
    attachmentUrl(file){
      const API_URL = process.env.VUE_APP_API_URL || "https://datasync.studiobackoffice.com/";
      return API_URL+'attachment/'+this.channelId+ '/' + file.id + '?token='+this.userToken;
    }
  }
};
</script>