<template>
  <div>
    <md-table>
      <md-table-row>
        <md-table-cell md-numeric>Notification Status</md-table-cell>
        <md-table-cell>{{notificationStatusText}}</md-table-cell>
        <md-table-cell md-numeric></md-table-cell>
      </md-table-row>

      <md-table-row>
        <md-table-cell md-numeric>Channels</md-table-cell>
        <md-table-cell>{{notificationChannels.length}} Enabled</md-table-cell>
        <md-table-cell md-numeric>
          <md-button
            type="button"
            class="md-raised md-primary"
            :disabled="sending"
            @click="enableAllChannels()"
          >
            Enable All Channels
          </md-button>
          <md-button v-if="notificationChannels.length"
            type="button"
            class="md-raised md-accent"
            :disabled="sending"
            @click="disableAllChannels()"
          >
            Disable All Channels
          </md-button>
        </md-table-cell>
      </md-table-row>
      <md-table-row>
        <md-table-cell md-numeric>Automatic add new channels</md-table-cell>
        <md-table-cell>
          <md-switch 
          v-if="typeof notifyChannelAutoAdd == 'boolean'" 
          v-model="notifyChannelAutoAdd" :value="true"
          @change="updateSettingsNotifyChannelAutoAdd"
          class="md-primary"
          ></md-switch>
        </md-table-cell>
        <md-table-cell md-numeric></md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<script>
import { db } from "@/firebase/init";
import firebase from "firebase";
import { mapGetters } from "vuex";

export default {
  name: "Profile",
  computed: {
    ...mapGetters({
      accounts: "accounts",
      currentDeviceToken:"currentDeviceToken",
      notificationChannels: "notificationChannels",
      sending: "sending",
      user: "user",
      userDoc: "userDoc"
    }),
    notificationStatusText() {
      switch (this.notificationStatus) {
        case "denied":
          return "Denied";
        case "granted":
          return "Granted";
        default:
          return "Pending";
      }
    }
  },
  created(){
    this.notificationStatus = Notification.permission;
  },
  data() {
    return {
      notifyChannelAutoAdd: null,
      notificationStatus: ""
    };
  },
  methods: {
    accountsArray() {
      let accountsArray = [];
      let count = 0;
      this.accounts.some((account) => {
        accountsArray.push(account.id);
        count++;
        // if (count > 9) {
        //   this.$store.dispatch(
        //     "showSnackbar",
        //     "Threads from only first 10 accounts are displayed"
        //   );
        //   return true;
        // }
      });
      return accountsArray;
    },
    disableAllChannels(){
      db.collection("users").doc(this.user.data.uid).update({notificationChannels: []});
      this.$store.dispatch("setNotificationChannels", []);
      this.$store.dispatch("showSnackbar", "All Channels Disabled Successfully");
    },
    async enableAllChannels(){
      const accounts = this.accountsArray();
      const channelsSnapshot = await db.collection("channels").where("accountId","in", accounts).get();
      let channels = [];
      channelsSnapshot.forEach(function(doc){
        channels.push(doc.id);
      });

      db.collection("users").doc(this.user.data.uid).update({notificationChannels: channels});
      this.$store.dispatch("setNotificationChannels", channels);
      this.$store.dispatch("showSnackbar", "All Channels Enabled Successfully");
    },
    updateSettingsNotifyChannelAutoAdd(){
      let newValue = this.notifyChannelAutoAdd;
      if(newValue == null){
        newValue = false;
      }
      db.collection("users").doc(this.user.data.uid).update({settingsNotifyChannelAutoAdd: newValue});
    },
  },
  watch: {
    currentDeviceToken(){
      this.notificationStatus = Notification.permission;
    },
    userDoc: {
      immediate: true,
      handler(newValue, oldValue) {
        const userDoc = newValue;
        if(!userDoc) return;
        if(typeof userDoc.settingsNotifyChannelAutoAdd === "undefined"){
          db.collection("users").doc(this.user.data.uid).update({settingsNotifyChannelAutoAdd: true});
        }
        this.notifyChannelAutoAdd = typeof userDoc.settingsNotifyChannelAutoAdd !== "undefined" ? userDoc.settingsNotifyChannelAutoAdd : true;
      }
    },
    notifyChannelAutoAdd(newValue, oldValue){
      if(newValue == null){
        this.notifyChannelAutoAdd = false;
        return;
      }
    }

  }
};
</script>