<template>
  <div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>
        {{ user ? 'Edit User' : 'Create New User' }}
      </md-dialog-title>

      <FormUser 
        v-if="user"
        @close="showDialog = false" 
        :form="user" 
        form-type="edit"
      />
      <FormUser 
        v-else
        @close="showDialog = false" 
        form-type="add"
      />

    </md-dialog>

    <md-button 
      v-if="user"
      class="md-primary"
      @click="showDialog = true"
    >
      <md-icon>edit</md-icon>
      Edit
    </md-button>
    <md-button 
      v-else
      class="md-primary md-raised"
      @click="showDialog = true"
    >
      New User
    </md-button>  
  </div>
</template>

<script>
 import FormUser from '@/components/blocks/forms/User'

  export default {
    name: 'CreateUser',
    props: {
      user: {
        type: Object,
        default: null
      }
    },
    components: {
        FormUser
    },
    data: () => ({
      showDialog: false
    })
  }
</script>
