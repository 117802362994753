<template>
  <div>
    <form novalidate class="md-layout" @submit.prevent="validate">
      <md-card class="md-layout-item md-size-100 md-small-size-100">
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('channelSystem')">
                <label>Channel System</label>
                <md-select v-model="form.channelSystem" md-dense :disabled="sending">
                  <md-option
                    v-for="system in channelSystems"
                    :value="system.id"
                    :key="system.id"
                  >{{system.name}}</md-option>
                </md-select>
                <span
                  class="md-error"
                  v-if="!$v.form.channelSystem.required"
                >The channel system is required</span>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('channelName')">
                <label for="last-name">Channel Name</label>
                <md-input v-model="form.channelName" :disabled="sending" />
                <span
                  class="md-error"
                  v-if="!$v.form.channelName.required"
                >The channel type is required</span>
                <span
                  class="md-error"
                  v-else-if="!$v.form.channelName.minlength"
                >The channel name is invalid</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field v-if="form.channelSystem==='Email'" :class="getValidationClass('email')">
                <label>Email</label>
                <md-input v-model="form.email" :disabled="sending" />
                <span class="md-error" v-if="!$v.form.email.required">The email is required</span>
                <span class="md-error" v-else-if="!$v.form.email.email">The email is invalid</span>
              </md-field>
              <md-field
                v-if="form.channelSystem=='TextMagic'"
                :class="getValidationClass('mobileNumber')"
              >
                <label>Mobile Number</label>
                <md-input v-model="form.mobileNumber" :disabled="sending" />
                <span
                  class="md-error"
                  v-if="!$v.form.mobileNumber.required"
                >The mobile number is required</span>
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-card-actions>
          <md-button type="button" class="md-secondary" @click="close()" :disabled="sending">Cancel</md-button>
          <md-button type="submit" class="md-primary" :disabled="sending">Create Channel</md-button>
        </md-card-actions>
      </md-card>

      <md-snackbar :md-active.sync="saved">New channel was saved with success!</md-snackbar>
    </form>
  </div>
</template>

<script>
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";
import firebase from "firebase";

let refChannels = db.collection("channels");

import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "Channel",
  mixins: [validationMixin],
  props: ["accountId"],
  computed: {
    ...mapGetters({
      accounts: "accounts",
      sending: "sending",
    }),
  },
  data: () => ({
    form: {
      channelSystem: null,
      channelName: null,
      email: null,
      mobileNumber: null,
      accountId: null,
    },
    saved: false,
    channelSystems: [
      { id: "Email", name: "Email" },
      { id: "Facebook", name: "Facebook Page" },
      { id: "SMS", name: "SMS" },
      { id: "TextMagic", name: "TextMagic" },
      { id: "Twilio", name: "Twilio" },
    ],
  }),
  mounted() {
    this.form.accountId = this.accountId;
  },
  validations() {
    let validations = {
    form: {
      channelName: {
        required,
        minLength: minLength(3),
      },
      channelSystem: {
        required,
      },
      email: {
        email,
      },
      mobileNumber: {},
      accountId: {
        required,
      },
    },
    };
    if(this.form.channelSystem === "Email"){
      validations.form.email = {email, required}
    }
    return validations;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.channelSystem = null;
      this.form.channelName = null;
      this.form.email = null;
      this.form.mobileNumber = null;
      this.form.accountId = null;
    },
    saveChannel() {
      this.$store.dispatch("setSending", true);
      let doc_id = this.form.id;
      let param = null;
      if (this.form.channelSystem === "Email") {
        param = {
          channelSystem: this.form.channelSystem,
          accountId: this.form.accountId,
          channelName: this.form.channelName,
          accountEmail: this.form.email,
        };
      } else if (this.form.channelSystem === "TextMagic") {
        param = {
          channelSystem: this.form.channelSystem,
          accountId: this.form.accountId,
          channelName: this.form.channelName,
          mobileNumber: this.form.mobileNumber,
        };
      } else {
        param = {
          channelSystem: this.form.channelSystem,
          accountId: this.form.accountId,
          channelName: this.form.channelName,
        };
      }
      refChannels
        .add({
          ...param,
          createdAt: firebase.firestore.Timestamp.now(),
        })
        .then((docRef) => {
          this.$store.dispatch("setManagingChannel", docRef.id);
          this.saved = true;
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", "Channel Created Successfully");
          this.clearForm();
          this.close();
        })
        .catch((err) => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", err);
        });
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveChannel();
      }
    },
  },
};
</script>
