<template>
  <div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Create New Contact</md-dialog-title>

      <ContactForm @close="showDialog = false" />

    </md-dialog>

    <md-button class="md-primary md-raised" @click="showDialog = true">New Contact</md-button>
    
  </div>
</template>

<script>
 import ContactForm from '@/components/blocks/forms/Contact'

  export default {
    name: 'ContactDialog',
    components: {
        ContactForm
    },
    data: () => ({
      showDialog: false
    })
  }
</script>
