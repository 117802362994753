<template>
  <div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Manage Facebook Integration</md-dialog-title>
      <!-- <FacebookIntegration /> -->
      <FormFacebook @close="showDialog = false" :channel="channel" />
    </md-dialog>

    <md-button class="md-primary md-raised" @click="showDialog = true">Manage</md-button>
  </div>
</template>

<script>
import FacebookIntegration from "@/components/blocks/integrations/Facebook";
import FormFacebook from "@/components/blocks/forms/Facebook";

export default {
  name: "FacebookChannel",
  props: {
    channel: {
      type: Object,
      default: null,
    },
    show: {
      default: false,
    },
  },
  components: {
    FormFacebook,
  },
  mounted() {
    this.$store.dispatch("setManagingChannel", "");
  },
  watch: {
    show: function (newValue, oldValue) {
      this.showDialog = newValue;
    },
  },
  data() {
    return {
      showDialog: this.show,
    };
  },
};
</script>
