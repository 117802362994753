<template>
  <div class="settings-channels">
    <!-- <Tokens /> -->
    <h1>Profile</h1>
    <Profile />
    <hr />
    <h1>Notifications</h1>
    <TokensSettings />
    <TokensCollection />
  </div>
</template>

<script>
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";

// import AskToken from '@/components/blocks/AskToken';
import Profile from "@/components/blocks/sections/Profile";
import Tokens from "@/components/blocks/sections/Tokens";
import TokensCollection from "@/components/blocks/sections/TokensCollection";
import TokensSettings from "@/components/blocks/sections/TokensSettings";

let dbChannels = db.collection("channels");

export default {
  name: "SettingsServer",
  components: {
    // AskToken,
    Profile,
    Tokens,
    TokensCollection,
    TokensSettings
  }
};
</script>

<style></style>