<template>
  <div>
    <h3>Tokens</h3>
    <md-button v-if="!tokenAdded && currentDeviceToken" class="md-primary" @click="add()">
      Add Current Device
      <md-icon>add_location</md-icon>
    </md-button>
    <md-list class="md-elevation-4">
      <div v-for="token in tokens" :key="token.id">
        <md-list-item>
          <div class="md-list-item-text">
            <span>{{token.device}}</span>
            <span>{{time(token.createdAt.toDate())}}</span>
            <!-- <span>{{token.token}}</span> -->
          </div>

          <md-icon v-if="currentDeviceToken === token.token" class="md-primary">person_pin_circle</md-icon>
          <md-button class="md-icon-button md-list-action" @click="remove(token.id)">
            <md-icon class="md-primary">delete</md-icon>
          </md-button>
        </md-list-item>
        <md-divider></md-divider>
      </div>
    </md-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { db } from "@/firebase/init";
import firebase from "firebase";
import moment from "moment";

let refTokens = db.collection("tokens");

export default {
  name: "TokensCollections",
  data() {
    return {
      tokens: []
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      //tokens: "userTokens",
      currentDeviceToken: "currentDeviceToken"
    }),
    tokenAdded() {
      let tokens = this.tokens.filter(
        token => token.token === this.currentDeviceToken
      );
      return tokens.length;
    }
  },
  watch: {
    user: {
      // call it upon creation too
      immediate: true,
      handler(user) {
        this.$bind(
          "tokens",
          refTokens
            .orderBy("createdAt", "desc")
            .where("userId", "==", user.data.uid)
        );
      }
    },
    tokens: function(newValue, oldValue) {
      this.$store.dispatch("storeTokens", newValue);
    }
  },
  created() {
    // let tokens = [];
    // refTokens
    //   .orderBy("createdAt", "desc")
    //   .where("userId", "==", this.user.data.uid)
    //   // .onSnapshot(snapshot => {
    //   //   //this.$store.dispatch("setSending", false);
    //   //   snapshot.docChanges().forEach(change => {
    //   //     if (change.type == "added") {
    //   //       let doc = change.doc;
    //   //       let token = { id: doc.id, ...doc.data() };
    //   //       tokens.push(token);
    //   //     }
    //   //     this.$store.dispatch("storeTokens", tokens);
    //   //   });
    //   // })
    //   .get()
    //   .then(querySnapshot => {
    //     querySnapshot.forEach(doc => {
    //       let token = { id: doc.id, ...doc.data() };
    //       tokens.push(token);
    //     });
    //   })
    //   .then(() => {
    //     this.$store.dispatch("storeTokens", tokens);
    //   });
  },
  // firestore: {
  //   tokens: db
  //     .collection("tokens")
  //     .orderBy("createdAt", "desc")
  //     .where("userId", this.user.data.uid)
  // },
  methods: {
    add() {
      let currentToken = this.currentDeviceToken;
      let uid = this.user.data.uid;
      refTokens
        .doc()
        .set({
          token: currentToken,
          userId: uid,
          createdAt: firebase.firestore.Timestamp.now(),
          device: platform.description
        })
        .then(() => {
          this.$store.dispatch("showSnackbar", "Device Added Successfully");
        })
        .catch(err => {
          this.$store.dispatch("showSnackbar", err);
        });
    },
    time(createdAt) {
      return moment(createdAt).format("lll");
    },
    remove(tokenId) {
      refTokens
        .doc(tokenId)
        .delete()
        .then(() => {
          let tokens = this.tokens.filter(token => token.id !== tokenId);
          this.$store.dispatch("storeTokens", tokens);
          this.$store.dispatch("showSnackbar", "Token Removed Successfully");
        })
        .catch(err => {
          this.$store.dispatch("showSnackbar", err);
        });
    }
  }
};
</script>