<template>
  <div>
    <form novalidate @submit.prevent="validateInfo">
      <md-card class="login">
        <md-card-header>
          <div class="md-title text-primary">Forgot Password?</div>
        </md-card-header>
        <md-card-content>
          <md-field :class="getValidationClass()">
            <label>Email</label>
            <md-input v-model="email" :disabled="sending"></md-input>
            <span class="md-error" v-if="!$v.email.required">The email is required</span>
            <span class="md-error" v-else-if="!$v.email.email">The email is invalid</span>
          </md-field>
          <p v-if="feedback" class="text-danger">{{ feedback }}</p>
        </md-card-content>

        <md-card-actions>
          <md-button 
            type="submit" 
            class="md-raised md-primary w-100"
            :disabled="sending"
          >
            Send
          </md-button>
        </md-card-actions>
      </md-card>
    </form>
    <md-snackbar :md-active.sync="formProcessed">{{snackBarMessage}}</md-snackbar>
  </div>  
</template>

<script>
import firebase from 'firebase'
import store from "@/store/index"
import {mapGetters} from 'vuex'

import {validationMixin} from 'vuelidate'
import {
  required,
  email,
} from 'vuelidate/lib/validators'

export default {
  name: 'ForgotPassword',
  mixins: [validationMixin],
  data: () => ({
    email: null,
    feedback: null,
    snackBarMessage: "",
    formProcessed: false
  }),
  computed: { 
    ...mapGetters({
      sending: "sending"
    }) 
  },
  validations: { 
    email: {
      required,
      email
    }
  },
  methods: {
    send() {
      //this.$router.push({name: 'ResetPassword'})
      this.$store.dispatch('setSending', true)
      let auth = firebase.auth();
      let emailAddress = this.email;
      this.formProcessed = false;

      auth.sendPasswordResetEmail(emailAddress)
      .then(() => {
        this.$v.$reset()
        this.email = ""
        this.snackBarMessage = "A password reset link has been sent to your email address.";
        this.$store.dispatch('setSending', false)
        this.formProcessed = true;
      }).catch((error) => {
        this.$v.$reset()
        this.$store.dispatch('setSending', false)
        this.snackBarMessage = error;
        this.formProcessed = true;
      });
    },
    getValidationClass () {
      const field = this.$v.email
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    validateInfo() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.send()
      }
    }
  }
}
</script>

