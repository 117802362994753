<template>
  <div>
    <form novalidate @submit.prevent="validateInfo">
      <md-card class="login">
        <md-card-header>
          <div class="md-title text-primary">Please sign in to continue</div>
        </md-card-header>
        <md-card-content>
          <md-field :class="getValidationClass('email')">
            <label>Email</label>
            <md-input v-model="form.email" :disabled="sending"></md-input>
            <span class="md-error" v-if="!$v.form.email.required">The email is required</span>
            <span class="md-error" v-else-if="!$v.form.email.email">The email is invalid</span>
          </md-field>
          <md-field :class="getValidationClass('password')">
            <label>Password</label>
            <md-input v-model="form.password" type="password" :disabled="sending"></md-input>
            <span class="md-error" v-if="!$v.form.password.required">The password is required</span>
          </md-field>
          <p v-if="feedback" class="text-danger">{{ feedback }}</p>
        </md-card-content>

        <md-card-actions class="d-block">
          <md-button type="submit" class="md-raised md-primary w-100 mb-3" :disabled="sending">Login</md-button>
          <router-link :to="{name: 'Forgot Password'}" :disabled="sending">Forgot Password?</router-link>
          <p class="mt-5 text-center">
            Don't have an account?
            <router-link :to="{name: 'Signup'}" :disabled="sending">Sign up</router-link>
          </p>
        </md-card-actions>
      </md-card>
    </form>
  </div>
</template>

<script>
import firebase from "firebase";
import { db } from "@/firebase/init";
import store from "@/store/index";
import { mapGetters } from "vuex";

import { validationMixin } from "vuelidate";
import accountsMixin from "@/mixins/AccountsMixin";

import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Login",
  mixins: [validationMixin, accountsMixin],
  data: () => ({
    form: {
      email: null,
      password: null
    },
    feedback: null
  }),
  computed: {
    ...mapGetters({
      sending: "sending",
      accounts: "accounts"
    })
  },
  mounted() {
    store.dispatch("resetState");
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  methods: {
    login() {
      this.$store.dispatch("setSending", true);
      this.feedback = null;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(user => {
          this.refreshAccounts(user.user);
          db.collection("users")
            .doc(user.user.uid)
            .collection("access")
            .get()
            .then(snapshot => {
              if (snapshot.empty) {
                this.$router.push({
                  name: "FirstAccount",
                  params: { nextRoute: "Home" }
                });
              } else {
                this.$router.push({ name: "Leads" });
              }
            });
          this.$store.dispatch("setSending", false);
        })
        .catch(err => {
          this.feedback = err.message;
          this.$store.dispatch("setSending", false);
        });
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    validateInfo() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.login();
      }
    }
  }
};
</script>

<style>
.md-card.login {
  max-width: 400px;
  margin: 60px auto 0px;
  padding: 30px;
  border-radius: 10px;
}
.login h2 {
  font-size: 2.4em;
}
</style>
