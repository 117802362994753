import Vue from "vue";
import Router from "vue-router";
import Home from "@/components/pages/Home";
import Inbox from "@/components/pages/Inbox";
import Contacts from "@/components/pages/Contacts";
import Leads from "@/components/pages/Leads";
import ActionItems from "@/components/pages/ActionItems";
// import SettingsChannels from "@/components/pages/settings/Channels";
// import SettingsUsers from "@/components/pages/settings/Users";
import Settings from '@/components/pages/Settings'
import LeadDetail from '@/components/pages/LeadDetail'
import ActionDetail from '@/components/pages/ActionDetail'
import NewAccount from '@/components/pages/NewAccount'

import Signup from "@/components/auth/Signup";
import Login from "@/components/auth/Login";

import ForgotPassword from '@/components/auth/ForgotPassword.vue'
import ResetPassword from '@/components/auth/ResetPassword'
import firebase from "firebase";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/inbox/",
      name: "PageInbox",
      component: Inbox,
      meta: {
        requiresAuth: true,
      },
      redirect: { name: 'Important' },
      children: [
        {
          path: "/inbox/important/",
          name: "Important",
          component: Inbox,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/inbox/important/:id",
          name: "ImportantThread",
          component: Inbox,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/inbox/inbox",
          name: "Inbox",
          component: Inbox,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/inbox/inbox/:id",
          name: "InboxThread",
          component: Inbox,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/inbox/archive",
          name: "Archive",
          component: Inbox,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/inbox/archive/:id",
          name: "ArchiveThread",
          component: Inbox,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/inbox/trash",
          name: "Trash",
          component: Inbox,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/inbox/trash/:id",
          name: "TrashThread",
          component: Inbox,
          meta: {
            requiresAuth: true,
          },
        },
      ]
    },
    {
      path: "/contacts",
      name: "Contacts",
      component: Contacts,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/leads",
      name: "Leads",
      component: Leads,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/leads/all/",
          name: "All Leads",
          component: Leads,
          meta: {
            requiresAuth: true,
          },
        },{
          path: "/leads/active/",
          name: "Active Leads",
          component: Leads,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/leads/lost/",
          name: "Lost Leads",
          component: Leads,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/leads/won/",
          name: "Won Leads",
          component: Leads,
          meta: {
            requiresAuth: true,
          },
        },
      ]
    },
    {
      path: "/lead-detail/:id",
      name: "Lead Detail",
      component: LeadDetail,
      props: true,
      mata: {
        requiresAuth: true
      }
    },
    {
      path: "/todo-detail/:id",
      name: "Action Detail",
      component: ActionDetail,
      props: true,
      mata: {
        requiresAuth: true
      }
    },
    {
      path: "/contact-detail/:id",
      name: "ContactDetail",
      component: LeadDetail,
      props: true,
      mata: {
        requiresAuth: true
      }
    },
    {
      path: "/settings",
      name: 'Settings',
      component: Settings,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/settings/:accountId/users",
      name: 'SettingsUsers',
      component: Settings,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/settings/:accountId/channels",
      name: 'SettingsChannels',
      component: Settings,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/settings/:accountId/integrations",
      name: 'SettingsIntegrations',
      component: Settings,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/signup",
      name: "Signup",
      component: Signup,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: '/forgot-password',
      name: 'Forgot Password',
      component: ForgotPassword
    },
    {
      path: '/reset-password',
      name: 'Reset Password',
      component: ResetPassword
    },
    {
      path: '/new-account',
      name: 'New Account',
      component: NewAccount,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/first-account',
      name: 'FirstAccount',
      component: NewAccount,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/action-items',
      name: 'ActionItems',
      component: ActionItems,
      props: true,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "/action-items/all/",
          name: "All Action-items",
          component: ActionItems,
          meta: {
            requiresAuth: true,
          },
        },{
          path: "/action-items/active/",
          name: "Active Action-items",
          component:ActionItems,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/action-items/completed/",
          name: "Completed Action-items",
          component: ActionItems,
          meta: {
            requiresAuth: true,
          },
        },
        
      ]
    },
  ],
});

// router guards
router.beforeEach((to, from, next) => {
  // check to see if route has auth guard
  if (to.matched.some((rec) => rec.meta.requiresAuth)) {
    // check auth state of user
    let user = firebase.auth().currentUser;
    if (user) {
      // User is signed in. Proceed to route
      next();
    } else {
      // No user is signed in. Redirect to login
      next({
        name: "Login",
      });
    }
  } else {
    // if route is not guarded by auth, proceed
    next();
  }
});

export default router;
