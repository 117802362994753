<template>
  <div>
    <form novalidate class="md-layout" @submit.prevent="validateUser">
      <md-card class="md-layout-item md-size-100 md-small-size-100">
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <textarea v-model="form.note" :readonly="sending" cols="50" rows="5"></textarea>
            </div>
          </div>
        </md-card-content>

        <md-card-actions>
          <md-button type="button" class="md-secondary" @click="close()">Cancel</md-button>
          <md-button type="submit" class="md-primary" :disabled="sending">Save Note</md-button>
        </md-card-actions>
      </md-card>
    </form>
  </div>
</template>

<script>
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";

import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "Note",
  mixins: [validationMixin],
  props: ["note", "collection", "id"],
  computed: {
    ...mapGetters({
      sending: "sending"
    })
  },
  data: () => ({
    form: {
      note: null
    },
    userSaved: false,
    lastUser: null
  }),
  validations: {
    form: {}
  },
  // mounted() {
  //   this.form.note = newValue;
  //   alert("mounted");
  // },
  watch: {
    note: {
      immediate: true,
      handler(newValue) {
        this.form.note = newValue;
      }
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    clearForm() {
      this.$v.$reset();
    },
    saveUser() {
      this.$store.dispatch("setSending", true);

      db.collection(this.collection)
        .doc(this.id)
        .update({
          note: this.form.note
        })
        .then(() => {
          //this.$emit("noteUpdated", this.form.note);
          this.formProcessed("Note Saved Successfully");
        })
        .catch(err => {
          this.formProcessed(err);
        });
    },
    formProcessed(message) {
      this.$store.dispatch("setSending", false);
      this.$store.dispatch("showSnackbar", message);
      //this.clearForm();
      this.close();
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveUser();
      }
    }
  }
};
</script>

<style scoped>
.md-input {
  border-bottom: none !important;
}
</style>