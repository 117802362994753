<template>
  <div class="md-layout new-account">
    <div
      class="md-layout-item md-small-size-100 md-medium-size-75 md-large-size-50 md-xlarge-size-50 mx-auto"
    >
      <form novalidate @submit.prevent="validateInfo">
        <md-card>
          <md-card-content class="p-0">
            <div class="md-layout">
              <div class="md-layout-item bg-primary">
                <div class="left-panel">
                  <h1>Create a new account</h1>
                  <p
                    class="my-4"
                  >Setup your studio in just four simple steps, or book in a free call for us to assist you with the setup process.</p>
                  <md-button
                    class="md-raised text-primary"
                    :href="callLink"
                    target="_blank"
                  >Book an Onboarding Call</md-button>
                </div>
              </div>
              <div class="md-layout-item">
                <div class="right-panel">
                  <md-field :class="getValidationClass('accountName')">
                    <label>Account Name</label>
                    <md-input v-model="form.accountName"></md-input>
                    <span
                      class="md-error"
                      v-if="!$v.form.accountName.required"
                    >The account name is required</span>
                    <span
                      class="md-error"
                      v-else-if="!$v.form.accountName.alphaNum"
                    >The account name is invalid. You can use letters, numbers and spaces.</span>
                  </md-field>
                  <md-autocomplete
                    v-model="form.country"
                    :md-options="countries"
                    :class="getValidationClass('country')"
                    @md-selected="onCountryChanged"
                  >
                    <label>Country</label>
                    <span class="md-error" v-if="!$v.form.country.required">The country is required</span>
                  </md-autocomplete>
                  <md-button
                    v-if="accounts.length>0"
                    :to="{name: 'Settings'}"
                    class="md-primary"
                  >Cancel</md-button>
                  <md-button type="submit" class="md-primary md-raised">Next</md-button>
                </div>
              </div>
            </div>
            <p v-if="feedback" class="text-danger">{{ feedback }}</p>
          </md-card-content>
        </md-card>
      </form>
    </div>
  </div>
</template>
<script>
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";

let refAccounts = db.collection("accounts");

import { validationMixin } from "vuelidate";
import accountsMixin from "@/mixins/AccountsMixin";
import { required, helpers } from "vuelidate/lib/validators";
const alphaNumSpace = helpers.regex("alphaNumSpace", /^[a-zA-Z0-9 ]*$/);
export default {
  name: "NewAccount",
  mixins: [validationMixin, accountsMixin],
  props: {
    nextRoute: {
      type: String,
      default: "Settings"
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      accounts: "accounts"
    })
  },
  watch: {
    accounts: {
      handler(newValue) {
        if (newValue.length) {
          if (this.$route.name == "FirstAccount") {
            this.$router.push({
              name: "Inbox"
            });
          }
        }
      }
    }
  },
  data: () => ({
    form: {
      accountName: "",
      country: ""
    },
    feedback: "",
    countries: [],
    callLink: "https://calendly.com/joshuaoliver/30min?month=2020-06",
    referenceCountries: []
  }),
  validations: {
    form: {
      accountName: {
        required,
        alphaNumSpace
      },
      country: {
        required
      }
    }
  },
  created() {
    this.fectchCountries();
  },
  methods: {
    onCountryChanged(countryName) {
      this.form.countryCode = this.referenceCountries.find((country) => {
        return country.name === countryName
      })?.alpha2Code;
    },
    fectchCountries() {
      let vm = this;
      vm.$http
        .get("https://restcountries.eu/rest/v2/all")
        .then(res => {
          vm.referenceCountries = res.data
          vm.countries = res.data.map(o => o.name);
        })
        .catch(err => {
          console.log("error");
        });
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    saveAccount() {
      this.$store.dispatch("setSending", true);
      refAccounts
        .add({
          ...this.form,
          created: Date.now()
        })
        .then(docRef => {
          // let name = this.form.accountName;
          // refUser.doc(this.user.data.uid).collection("access").doc(docRef.id).set({
          //   accountName: name
          // }).then(()=>{
          this.refreshAccounts(this.user.data);
          this.$store.dispatch("setSending", false);
          this.$router.push({ name: this.nextRoute });
          // })
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setSending", false);
        });
    },
    validateInfo() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveAccount();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.new-account {
  margin: 60px auto 0;
}
.left-panel {
  background-image: url("../../assets/img/back-1.svg");
  padding: 4rem 2rem;
  background-position: center;
  background-size: contain;
  p {
    font-size: 16px;
  }
}
.right-panel {
  margin: 4rem 2rem;
}
</style>