<template>
  <div>
    <form novalidate class="md-layout" @submit.prevent="validateToDo">
      <md-card class="md-layout-item md-size-100 md-small-size-100 p-3">
        <md-card-content>

          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('todo')">
              <label for="todo">Action</label>
              <md-input
                  autocomplete="todo"
                  v-model="form.todo"
                  :disabled="sending"
                  name="todo"
                  id="todo"
              />
              <span class="md-error" v-if="!$v.form.todo.required">The Todo is required</span>
              <!-- <span class="md-error" v-else-if="!$v.form.phone.minlength">The phone is invalid</span> -->
            </md-field>
          </div>
        </md-card-content>

        <md-card-actions>
          <md-button type="submit" class="md-secondary" @click="close()">Cancel</md-button>
          <md-button
              type="submit"
              class="md-primary"
              :disabled="sending"
          >{{ formType === 'add' ? 'Create To Do' : 'Update To Do' }}</md-button>
        </md-card-actions>
      </md-card>

      <md-snackbar
          :md-active.sync="todoSaved"
      >{{ formType === 'add' ? 'The Action-item was created with success!' : 'The Action-item was updated with success!' }}</md-snackbar>
    </form>
  </div>
</template>

<script>
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";
import firebase from "firebase";

import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "FormActionItem",
  mixins: [validationMixin],
  props: {
    form: {
      type: Object,
      default: function () {
        return {
          accountId: null,
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
          todo:null

        };
      },
    },
    formType: {
      type: String,
      default: "",
    },lead: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      accounts: "accounts",
      sending: "sending",
      selectedAccounts: "selectedAccounts",
      user: "user",
    }),
  },
  data: () => ({
    todoSaved: false,
  }),
  validations: {
    form: {
      /*firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        minLength: minLength(8),
      },

      accountId: {
        required,
      },*/todo:{
        required
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      /*    this.form.firstName = null;
          this.form.lastName = null;
          this.form.email = null;
          this.form.phone = null;*/
      this.form.todo = null;
    },
    saveTodo() {
      console.log(this.lead)

      let leadInfo = this.lead
      this.$store.dispatch("setSending", true);
      let displayName = this.form.lastName
          ? `${this.form.firstName} ${this.form.lastName}`
          : this.form.firstName;
      db.collection("todo")
          .doc()
          .set({
            ...this.form,
            displayName: this.lead.contact.firstName,
            email: this.lead.contact.email,
            phone: this.lead.contact.phone,
            todoStatus: "todo",
            accountId: leadInfo.accountId,
            leadID: leadInfo.id,
            todoCreated: firebase.firestore.Timestamp.now(),
            todoReviewDate: firebase.firestore.Timestamp.now(),
            todoUpdated:"",
          })
          .then(() => {
            this.todoSaved = true;
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", "Todo added successfully");
            this.clearForm();
            this.close();
          })
          .catch((err) => {
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", err);
          });
    },
    validateToDo() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveTodo();
      }
    },
  },
};
</script>
