<template>
    <div class="lead-detail px-md-3">
      <md-toolbar>
        <!-- <router-link :to="{ name: 'Leads' }">
          <md-icon>arrow_back</md-icon> Back
        </router-link>-->
        <md-button class="md-primary text-decoration-none" @click="backLink()">
          <md-icon class="mr-2">arrow_back</md-icon>Back
        </md-button>
        <md-menu
                    v-if="todo.leadStatus === 'lead'"
                    md-size="medium"
                    md-align-trigger
                    :mdCloseOnSelect="true"
                  >
                    <md-button class="md-raised md-primary" md-menu-trigger>Set followup</md-button>
                    <md-menu-content>
                      <md-menu-item @click="snoozeLead(1, '24 hours')">24 hours</md-menu-item>
                      <md-menu-item @click="snoozeLead(7, '1 week')">1 week</md-menu-item>
                      <md-menu-item @click="snoozeLead(14, '2 weeks')">2 weeks</md-menu-item>
                      <md-menu-item @click="snoozeLead(21, '3 weeks')">3 weeks</md-menu-item>
                      <md-menu-item @click="lostLead()">Mark as Lost</md-menu-item>
                      <md-menu-item @click="completedLead()">Mark as Won</md-menu-item>
                    </md-menu-content>
                  </md-menu>
                  <md-button class="md-raised md-primary" @click="showEditDialog = true">Edit</md-button>
      </md-toolbar>
      <div v-if="todo" class="md-layout md-gutter m-0 py-3 md-elevation-4">
        <div class="md-layout-item md-small-size-100 md-sicze-70 lead-detail-body">
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-60 py-3">
              <Avatar
                :name="todo.contactInfo.displayName"
                :photoUrl="todo.contactInfo.photoUrl"
                class="float-left"
              />
              <div class="float-left mx-2">
                <div class="main-info">
                  <span class="md-title contatodo mr-5">
                    {{
                    todo.contactInfo.displayName
                    }}
                  </span>
                  <span class="lead-days">Todo Review {{ todo.reviewText }}</span>
                </div>
                <div class="contact-info my-2">
                  <span>
                    <md-icon class="md-primary">phone_iphone</md-icon>
                    <a :href="'tel:'+todo.contactInfo.phone">{{todo.contactInfo.phone }}</a>
                  </span>
                  <span>
                    <md-icon class="md-primary ml-2">email</md-icon>
                    <a :href="'mailto:'+todo.contactInfo.email">{{ todo.contactInfo.email }}</a>
                  </span>
                </div>
                <div class="status-info float-left py-2" v-if="todo.tagsShow">
                  <span
                    v-for="(tag, index) in todo.tagsShow"
                    :key="index"
                    class="custom-badge mr-2"
                    :class="'badge-color-' + index"
                  >
                    {{ tag.name
                    }}
                    <a
                      v-if="index > 0"
                      href="#"
                      class="btn-close"
                      @click.prevent="delTag(index - 1)"
                    >&times;</a>
                  </span>
                </div>
                <AddTag className="md-raised md-primary" @addTag="addTag" />
                <div class="w-100">
                  
                  
                  <ContacEditDialog
                    :showEditDialog="showEditDialog"
                    :contact="todo.contact"
                    @close="showEditDialog = false"
                  />
                </div>
              </div>
            </div>
            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-size-20 px-1">
              <md-card class="primary-card">
                <md-card-header>
                  <div class="md-title">Account</div>
                </md-card-header>
                <md-card-content class="pt-3 fixed">
                  <div
                    v-for="contract in todo.contracts"
                    :key="contract.contractId"
                    class="custom-badge mb-2"
                    :class="contract.status === 'inactive' ? 'status-email' : 'status-member'"
                  >{{ getShortDate(contract.startDate.seconds) }} - {{ contract.contractName }}</div>
                </md-card-content>
                <md-card-actions v-if="todo.integrationId">
                  <md-button
                    class="md-raised md-primary w-100"
                    @click="refreshIntegrationSync"
                  >Refresh Integration</md-button>
                </md-card-actions>
              </md-card>
            </div>
            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-size-20 px-1">
              <md-card class="primary-card">
                <md-card-header>
                  <div class="md-title">Session</div>
                </md-card-header>
                <md-card-content class="pt-3 fixed session-container">
                  <!-- <MindbodyVisits />
                  <div
                    v-for="visit in todo.visits"
                    :key="visit.id"
                    class="custom-badge mb-2"
                    :class="visit.signedIn ? 'status-email' : 'status-member'"
                  >{{ getShortDate(visit.startDate.seconds) }} - {{ visit.name }}</div> -->
                </md-card-content>
                <!-- <md-card-actions>
                  <md-button class="md-raised md-primary w-100">Book</md-button>
                </md-card-actions> -->
              </md-card>
            </div>
          </div>
          <div class="md-layout mt-3">
            <md-card class="primary-card w-100">
              <md-card-header>
                <div class="md-title">Notes</div>
              </md-card-header>
              <md-card-content class="pt-3">
                <md-field>
                  <md-textarea v-model="notes"></md-textarea>
                </md-field>
              </md-card-content>
              <md-card-actions>
                <md-button class="md-raised md-primary" @click="saveNote()">Save</md-button>
              </md-card-actions>
            </md-card>
          </div>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-40">
          <md-card class="primary-card h-100">
            <md-card-header>
              <div class="md-title">Activity Log</div>
            </md-card-header>
            <md-card-content class="px-0">
              <ul
                id="messages"
                class="messages message-list custom-scrollbar px-3"
                ref="chatContainer"
              >
                <Message
                  v-for="(message, index) in messages"
                  :key="message.id"
                  :message="message"
                  :prevDate="prevDate(index)"
                />
              </ul>
            </md-card-content>
            <div class="w-100 px-1 border-top" style="right:0">
              <md-button class="md-raised md-primary md-dense" :disabled="sending" @click="logCall()">Log Call</md-button>
              <!-- <NewMessage :thread="selectedThread" /> -->
              <!-- <form @submit.prevent="addMessage">
                <div class="message-action">
                  <div class="sender-info px-1">
                    <md-field class="mr-auto channel-container" md-dense>
                      <label for="channel">Channel</label>
                      <md-select v-model="currentChannel" name="channel" id="channel">
                        <md-option
                          v-for="(channel, index) in channelList"
                          :value="channel"
                          :key="index"
                        >{{ channel }}</md-option>
                      </md-select>
                    </md-field>
                    <div class="sender-details text-right mt-1">
                      <span class="text-gray mx-1">
                        <strong>From:</strong> US Based customer channel
                      </span>
                    </div>
                  </div>
                  <md-field class="mb-2">
                    <md-textarea v-model="newMessage"></md-textarea>
                  </md-field>
                  <p v-if="feedback" class="text-danger">{{ feedback }}</p>
                  <div class="mb-3">
                    <md-button class="md-icon-button">
                      <md-icon>sentiment_satisfied_alt</md-icon>
                    </md-button>
                    <md-button class="md-icon-button">
                      <md-icon>edit</md-icon>
                    </md-button>
                    <md-button class="md-icon-button">
                      <md-icon>attach_file</md-icon>
                    </md-button>
                    <md-button class="md-icon-button">
                      <md-icon>add_circle_outline</md-icon>
                    </md-button>
                    <md-button class="md-raised md-primary float-right" @click="sendMessage">
                      <img src="@/assets/img/icon-send.svg" />
                      Send
                    </md-button>
                  </div>
                </div>
              </form>-->
            </div>
          </md-card>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { db } from "@/firebase/init";
  import ActionModel from "@/models/ActionModel";
  import Avatar from "@/components/blocks/Avatar";
  import axios from "axios";
  import AddTag from "@/components/blocks/dialogs/AddTag";
  import ContacEditDialog from "@/components/blocks/dialogs/ContactEdit";
  import moment from "moment";
  import { mapGetters } from "vuex";
  import firebase from "firebase";
  
  import Message from "@/components/blocks/Message";
  import MessageModel from "@/models/MessageModel";
  import NewMessage from "@/components/blocks/forms/NewMessage";
  import MindbodyVisits from "@/components/blocks/MindbodyVisits";
  import LogAcivityMixin from "@/mixins/LogActivityMixin";
  import LeadActionsMixin from "@/mixins/LeadActionsMixin";
  
  const todos = db.collection("todo");
  const messagesRef = db
    .collection("messages")
    .orderBy("messageTime", "desc")
    .limit(10);
  
  export default {
    name: "ActionDetail",
    props: ["id"],
    components: {
      Avatar,
      AddTag,
      ContacEditDialog,
      Message,
      MindbodyVisits,
      NewMessage,
    },
    mixins: [LeadActionsMixin, LogAcivityMixin],
    data() {
      return {
        todo: {
          contactInfo: {
            displayName: "",
          },
        },
        thread: {},
        todoObj: {},
        messages: [],
        newMessage: "",
        notes: "",
        scrollable: true,
        totalChatHeight: 0,
        currentChannel: "Facebook",
        channelList: ["Facebook", "TextMagic", "Nylas", "Twilio"],
        feedback: "",
        backRouteName: "Leads",
        showEditDialog: false,
      };
    },
    watch: {
      id: {
        // call it upon creation too
        immediate: true,
       async handler(id) {
          let todoObj = todos.doc(id);
         let data= await todoObj.get()
          
          this.$bind("todoObj", todoObj);
        },
      },
      todoObj: {
        handler(newValue, oldValue) {
          this.todo = ActionModel(newValue, this.accounts);
          
          this.notes = this.todo.notes;
          if (newValue.accountId !== oldValue.accountId) {
            this.fetchMessages();
          }

        },
      },
    },
    mounted() {

      this.scroll();
      this.scrollToEnd();
    todos.doc(this.id).get().then((doc)=>{
        this.todo={...this.todo,id: doc.id,...doc.data()}

      });
      
   // this.fetchMessages()
    },
    computed: {
      ...mapGetters({
        sending: "sending",
        user: "user",
        userToken: "userToken",
        accounts: "accounts",
      }),
      // selectedThread() {
      //   const count = this.messages.length;
      //   if (!count) return {};
      //   let message = this.messages[count - 1];
      //   return this.getThread(message.threadId);
      // }
    },
    methods: {
      refreshIntegrationSync() {
        this.$store.dispatch("setSending", true);
        this.mindbodyData = {};
        let todo = this.todo;
        let url =
          `integrations/mindbody/sync?accountId=` +
          todo.accountId +
          `&action=processrecords&token=${this.userToken}`;
        axios({
          url: url,
          method: "POST",
          data: {
            query: {
              limit: 1,
              ClientIds: [todo.integrationId],
            },
          },
        })
          .then((resp) => {
          
            if (resp.status === 200) {
              this.mindbodyData = resp.data;
            }
            this.$store.dispatch("setSending", false);
          })
          .catch((err) => {
            this.$store.dispatch("setSending", false);
            this.snackbar = true;
            this.snackbarMessage = err;
          });
      },
      fetchMessages() {
        this.messages = [];
    
        try {
          messagesRef
            .where("linkedContact", "==", this.id)
            .where("accountId", "==", this.todo.accountId)
            .onSnapshot((snapshot) => {
              this.$store.dispatch("setSending", false);
              let routeSelect = false;
              snapshot.docChanges().forEach((change) => {
                let doc = change.doc;
                let message = MessageModel(doc);
                if (change.type == "modified") {
                  this.messages = this.messages.filter(
                    (message) => message.id !== doc.id
                  ); //remove the thread
                  this.messages.unshift(message); //add thread on top
                } else if (change.type == "added") {
                  this.messages.unshift(message);
                } else if (change.type == "removed") {
                  this.messages = this.messages.filter(
                    (message) => message.id !== doc.id
                  );
                }
                this.messages = this.messages.sort((a, b) => a.secs - b.secs);
                this.scrollToEnd();
              });
            });
        } catch (err) {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", err);
        }
      },
      getThread(threadId) {
        db.collection("thread")
          .doc(threadId)
          .get()
          .then((doc) => {
            return { id: doc.id, ...doc.data() };
          });
      },
      getMessageArray(doc) {
        return {
          messageText: doc.messageText,
          messageTime: moment(doc.messageTime.toDate()).format("lll"),
          direction: doc.direction,
        };
      },
      addTag(tagName) {
        this.todo.tags.push({
          name: tagName,
          score: 0,
        });
        this.$store.dispatch("setSending", true);
        todos
          .doc(this.id)
          .update({
            tags: this.lead.tags,
          })
          .then(() => {
            this.$store.dispatch("setSending", false);
          })
          .catch((err) => {
            console.log(err);
            this.$store.dispatch("setSending", false);
          });
      },
      delTag(index) {
        this.todo.tags.splice(index, 1);
        this.$store.dispatch("setSending", true);
        todos
          .doc(this.id)
          .update({
            tags: this.todo.tags,
          })
          .then(() => {
            this.$store.dispatch("setSending", false);
          })
          .catch((err) => {
            console.log(err);
            this.$store.dispatch("setSending", false);
          });
      },
      backLink() {
        this.$router.go(-1);
      },
      sendMessage() {
        if (this.newMessage) {
          this.messages.push({
            messageText: this.newMessage,
            messageTime: moment().format("hh:mm A"),
            direction: "outgoing",
          });
          this.newMessage = "";
          this.scrollToEnd();
        }
      },
      scroll() {
        let scrollableElement = document.querySelector(".message-list");
        scrollableElement.addEventListener("scroll", (event) => {});
      },
      scrollToEnd() {
        this.$nextTick(() => {
          var container = this.$el.querySelector(".message-list");
          container.scrollTop = container.scrollHeight;
        });
      },
      scrollTo() {
        this.$nextTick(() => {
          let currentHeight = this.$refs.chatContainer.scrollHeight;
          let difference = currentHeight - this.totalChatHeight;
          var container = this.$el.querySelector(".message-list");
          container.scrollTop = difference;
        });
      },
      prevDate(index) {
        if (index === 0) {
          return "";
        } else {
          let prevMessage = this.messages[index - 1];
          return moment(prevMessage.timestamp).format("L");
        }
      },
      getShortDate(ms) {
        //return moment(ms).format("M/D");
        return moment(ms * 1000).format("L");
      },
      saveNote() {
        if(!this.notes && !this.todoObj.notes){
          this.$store.dispatch("showSnackbar", "Note field is empty");
          return;
        }
        this.$store.dispatch("setSending", true);
        todos
          .doc(this.id)
          .update({
            notes: this.notes,
          })
          .then(() => {
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", "Note updated successfully");
          })
          .catch((err) => {
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", err);
          });
      },
      logCall(){
        this.$store.dispatch("setSending", true);
        
        todo = this.todoObj;
       
        let user = this.user;
        let messageText = user.data.displayName + " called the contact";
        todos

          .doc(this.id)
          .update({
            lastContacted: firebase.firestore.Timestamp.now(),
            lastContactedMessage: "Outgoing Call"
          })
          .then(() => {
            this.logActivity(
                          messageText,
                          todo.accountId,
                          todo.id,
                          {
                              call: true,
                              updatedBy: {
                                  name: user.data.displayName,
                                  id: user.data.uid,
                              },
                          }
                      );
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", "Call logged successfully");
          })
          .catch((err) => {
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", err);
          });
        
      }
    },
  };
  </script>
  <style lang="scss" scoped>
  .lead-detail-body {
    position: relative;
    height: calc(100vh - 160px);
    .main-info {
      .contact-name {
        font-size: 16px;
        font-weight: bold;
      }
      .lead-days {
        font-size: 13px;
      }
    }
    .contact-info {
      font-size: 13px;
      .md-icon {
        font-size: 18px !important;
      }
    }
  }
  .bottom-action {
    position: absolute;
    bottom: 0;
    right: 15px;
  }
  .message-list {
    height: calc(100vh - 480px);
    overflow: auto;
    padding: 0px;
    .one-message {
      list-style: none;
      .message-body {
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.08);
        font-size: 14px;
        max-width: 80%;
        padding: 0.5rem;
        border-radius: 0px 10px 10px 0px;
        display: inline-block;
      }
      &:first-child .message-body {
        border-radius: 10px 10px 10px 0px;
      }
      &:last-child .message-body {
        border-radius: 0px 10px 10px 10px;
      }
      &.dir-outgoing {
        text-align: right;
        .message-body {
          border-radius: 10px 0px 0px 10px;
          background-color: #eef2ff;
        }
        &:first-child .message-body {
          border-radius: 10px 10px 0px 10px;
        }
        &:last-child .message-body {
          border-radius: 10px 0px 10px 10px;
        }
      }
    }
  }
  .status-info {
    .custom-badge {
      position: relative;
      cursor: pointer;
      .btn-close {
        background-color: lightgrey;
        color: black;
        border-radius: 10px;
        text-align: center;
        line-height: 20px;
        padding: 0px 7px;
        margin-left: 0px;
        margin-top: -5px;
        position: absolute;
        display: none;
        z-index: 10;
      }
      &:hover {
        .btn-close {
          display: initial;
        }
      }
    }
  }
  .session-container {
    max-height: 350px;
    overflow-y: auto;
  }
  </style>
  <style>
    .messages .message-body {
      min-width: 85%;
    }
  </style>
  