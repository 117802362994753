<template>
  <div>
    <form novalidate class="md-layout" @submit.prevent="validateUser">
      <md-card class="md-layout-item md-size-100 md-small-size-100">

        <md-card-content class="mx-5">
          <md-field :class="getValidationClass('name')">
            <label for="name">Name</label>
            <md-input autocomplete="name" v-model="form.name" :disabled="sending" />
            <span class="md-error" v-if="!$v.form.name.required">The user name is required</span>
          </md-field>

          <md-field :class="getValidationClass('email')">
            <label for="email">Channel Type</label>
            <md-input autocomplete="email" v-model="form.email" :disabled="sending" />
            <span class="md-error" v-if="!$v.form.email.required">The email is required</span>
          </md-field>

          <md-field :class="getValidationClass('role')">
            <label for="role">Role</label>
            <md-select v-model="form.role" md-dense :disabled="sending">
              <md-option v-for="role in roles" :value="role" :key="role">{{role}}</md-option>
            </md-select>
            <span class="md-error" v-if="!$v.form.role.required">The role is required</span>
          </md-field>

          <md-field :class="getValidationClass('accountId')">
            <label for="accountId">Account</label>
            <md-select name="accountId" id="accountId" v-model="form.accountId" md-dense :disabled="sending">
              <md-option v-for="account in accounts" :value="account.id" :key="account.id">{{account.name}}</md-option>
            </md-select>
            <span class="md-error" v-if="!$v.form.accountId.required">The account is required</span>
          </md-field>
        </md-card-content>

        <md-card-actions>
            <md-button type="submit" class="md-secondary" @click="close()">Cancel</md-button>
            <md-button type="submit" class="md-primary" :disabled="sending">
              {{ formType === 'add' ?  'Create User' : 'Update User' }}
            </md-button>
        </md-card-actions>
      </md-card>

      <md-snackbar :md-active.sync="userSaved">
        {{ formType === 'add' ? 'The user was created with success!' : 'The user was updated with success!' }}
      </md-snackbar>
    </form>
  </div>
</template>

<script>
  import { db } from '@/firebase/init'
  import { mapGetters } from "vuex";

    // let refChannels = db.collection("channels");

  import { validationMixin } from 'vuelidate'
  import {
    required,
    minLength
  } from 'vuelidate/lib/validators'

  export default {
    name: 'User',
    mixins: [validationMixin],
    props: {
      form: {
        type: Object,
        default: function() {
          return {
            accountId: null,
            name: null,
            email: null,
            role: null,
          }
        }
      },
      formType: {
        type: String,
        default: ''
      }
    },
    computed:{
      ...mapGetters({
        accounts: "accounts",
        sending: "sending"
      })
    },
    data: () => ({
      userSaved: false,
      lastUser: null,
      roles: [
        "Owner",
        "Role Sample",
      ]
    }),
    validations: {
      form: {
        name: {
          required
        },
        email: {
          required
        },
        role: {
          required
        },
        accountId: {
          required
        }
      }
    },
    methods: {
      close(){
          this.$emit("close")
      },
      getValidationClass (fieldName) {
        const field = this.$v.form[fieldName]

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },
      clearForm () {
        this.$v.$reset()
        this.form.name = null
        this.form.email = null
        this.form.role = null
        this.form.accountId = null
      },
      saveUser () {
        this.sending = true
        // refChannels.doc().set({
        //     ...this.form

        // }).then(() => {
        //   this.userSaved = true
        //   this.sending = false
        //   this.clearForm()
        //   this.close()
        // })
      },
      validateUser () {
        
        this.$v.$touch()

        if (!this.$v.$invalid) {
          this.saveUser()
        }
      }
    }
  }
</script>
