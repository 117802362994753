<template>
  <span>
    <md-icon
      class="md-primary"
      style="cursor:pointer"
      @click.native="getToken(true)"
      v-if="status === 'default'"
      title="Enable Notifications"
    >{{notificationIcon}}</md-icon>
    <md-icon v-else class="md-primary">{{notificationIcon}}</md-icon>
  </span>
</template>

<script>
import { db, messaging } from "@/firebase/init";
import { mapGetters } from "vuex";
import firebase from "firebase";
import platform from "platform";

let refUsers = db.collection("users");
let refTokens = db.collection("tokens");

export default {
  name: "AskToken",
  components: {},
  data() {
    return {
      status: null,
      uid: null,
      platformDesc: platform.description
    };
  },
  computed: {
    ...mapGetters({
      accounts: "accounts",
      user: "user",
      tokens: "userTokens",
      currentDeviceToken: "currentDeviceToken",
      notificationChannels: "notificationChannels"
    }),
    notificationIcon() {
      switch (this.status) {
        case "denied":
          return "notifications_off";
        case "granted":
          return "notifications_active";
        default:
          return "notifications_none";
      }
    }
  },
  watch: {
    tokens: function(newValue, oldValue) {
      //alert("tokens loaded");
    }
  },
  created() {
    this.status = Notification.permission;
    this.uid = this.user.data.uid;
    if (this.status === "granted") {
      this.getToken(false);
    }
    this.tokenRefresh();
  },
  methods: {
    getToken(manual) {
      messaging.requestPermission().then(() => {
        messaging
          .getToken({vapidKey: "BDaeByAf3DX_B8Dl7nBhscqZQY7TwtUeocty0V7LunN5teOQtw3eFaL3vztY5rpnT184ouT27m_fV2J4b_Opf50"})
          .then(currentToken => {
            //this.setTokenSentToServer(false);
            if (manual) {
              this.sendTokenToServer(currentToken, this.uid);
            }
            this.$store.dispatch("storeCurrentDeviceToken", currentToken);
          })
          .catch(() => {});
        this.status = Notification.permission;
      });
    },
    tokenRefresh() {
      messaging.onTokenRefresh(() => {
        messaging
          .getToken()
          .then(refreshedToken => {
            // Indicate that the new Instance ID token has not yet been sent to the
            // app server.
            //this.setTokenSentToServer(this.uid, false);
            // Send Instance ID token to app server.
            this.sendTokenToServer(refreshedToken, this.uid);
          })
          .catch(err => {});
      });
    },
    isTokenSentToServer(uid) {
      let status = window.localStorage.getItem("sentToServer-" + uid) === "1";
      this.user.data.userDoc.tokens;
      return status;
    },
    isTokenSentToServer2(currentToken) {
      let tokens = this.user.data.userDoc.tokens;
      let filterToken = tokens.filter(token => token === currentToken);
      return filterToken.length;
    },
    isTokenSentToServer3(currentToken) {
      let filterToken = this.tokens.filter(
        token => token.token === currentToken
      );
      return filterToken.length;
    },
    setTokenSentToServer(uid, sent) {
      window.localStorage.setItem("sentToServer-" + uid, sent ? "1" : "0");
    },
    sendTokenToServer(currentToken, uid) {
      this.$store.dispatch("storeCurrentDeviceToken", currentToken);
      if (!this.isTokenSentToServer3(currentToken)) {
        //console.log(this.user);
        refTokens
          .doc()
          .set({
            token: currentToken,
            userId: uid,
            createdAt: firebase.firestore.Timestamp.now(),
            device: platform.description
          })
          .then(() => {
            this.$store.dispatch(
              "showSnackbar",
              "Notification enabled successfully"
            );
          })
          .catch(err => {
            this.$store.dispatch("showSnackbar", err);
          });
          if(!this.notificationChannels.length){
            this.enableAllChannels()
          }
        // let refUser = refUsers.doc(uid);
        // refUser.get().then(doc => {
        //   if (doc.exists) {
        //     let user = doc.data();
        //     //console.log(user);
        //     let tokens =
        //       typeof user.tokens !== "undefined" ? [...user.tokens] : [];
        //     tokens.push(currentToken);
        //     //console.log(tokens);
        //     refUser
        //       .update({
        //         tokens: tokens
        //       })
        //       .then(() => {
        //         //alert('updated token')
        //       })
        //       .catch(err => {
        //         //alert(err);
        //       });

        //     refUser.get().then(doc => {
        //       if (doc.exists) {
        //         let userDoc = doc.data();
        //         this.$store.dispatch("updateUserDocument", userDoc);
        //       }
        //     });

        //     this.setTokenSentToServer(uid, true);
        //   } else {
        //     console.log("user doc not found");
        //   }
        // });
      }
    },
    accountsArray() {
      let accountsArray = [];
      let count = 0;
      this.accounts.some((account) => {
        accountsArray.push(account.id);
        count++;
        // if (count > 9) {
        //   this.$store.dispatch(
        //     "showSnackbar",
        //     "Threads from only first 10 accounts are displayed"
        //   );
        //   return true;
        // }
      });
      return accountsArray;
    },
    async enableAllChannels(){
      const accounts = this.accountsArray();
      const channelsSnapshot = await db.collection("channels").where("accountId","in", accounts).get();
      let channels = [];
      channelsSnapshot.forEach(function(doc){
        channels.push(doc.id);
      });

      db.collection("users").doc(this.user.data.uid).update({notificationChannels: channels});
      this.$store.dispatch("setNotificationChannels", channels);
      this.$store.dispatch("showSnackbar", "All Channels Enabled Successfully");
    },
  }
};
</script>

<style></style>