<template>
  <div class="bg-custom-gray" v-if="thread">
    <!-- <div class="separator py-3"></div> -->
    <ul id="messages" class="messages message-list custom-scrollbar px-3" ref="chatContainer">
      <Message
        v-for="(message, index) in messages"
        :key="message.id"
          :message="message"
        :prevDate="prevDate(index)"
        :channelId="thread.channelId"
      />
    </ul>
  </div>
</template>

<script>
import { db } from "@/firebase/init";
import moment from "moment";
import { mapGetters } from "vuex";

import Message from "@/components/blocks/Message";
import MessageModel from "@/models/MessageModel";

let messagesRef = db
  .collection("messages")
  .orderBy("messageTime", "desc")
  .limit(10);
let lastVisible;
let scrollableElement = document.querySelector(".messages");

export default {
  name: "Messages",
  props: ["thread"],
  components: {
    Message
  },
  data() {
    return {
      messages: [],
      unsbuscribe: false,
      scrollable: true,
      totalChatHeight: 0
    };
  },
  mounted() {
    this.scroll();
  },
  created() {
    if (!this.thread) {
      return;
    }
  },
  watch: {
    thread: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!newValue || !oldValue || newValue.id !== oldValue.id) {
          this.threadMessages();
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      accounts: "accounts"
    })
  },
  methods: {
    threadMessages() {
      //console.log("fetching");
      //console.log('threadMessages');
      this.scrollable = true;
      this.$store.dispatch("setSending", true);

      let thread = this.thread;
      let messages = [];

      if (!thread) {
        return messages;
      }

      this.messages = [];

      if (this.unsbuscribe) {
        this.unsbuscribe();
      }

      try {
        this.unsbuscribe = messagesRef
          .where("threadId", "==", thread.id)
          .where("accountId", "==", thread.accountId)
          .onSnapshot(snapshot => {
            this.$store.dispatch("setSending", false);
            snapshot.docChanges().forEach(change => {
              if (change.type == "added") {
                let doc = change.doc;

                let messageObj = MessageModel(doc);

                let keyExist = false;
                if (
                  !this.messages.filter(message => message.id === doc.id).length
                ) {
                  this.messages.push(messageObj);
                  this.scrollToEnd();
                }

                this.messages = this.messages.sort((a, b) => a.secs - b.secs);
                lastVisible = doc;
              } else if (change.type == "removed") {
                let doc = change.doc;
                this.messages = this.messages.filter(
                  message => message.id !== doc.id
                ); //remove the threads
              }
            });
          });
      } catch (err) {
        this.$store.dispatch("showSnackbar", err);
      }
    },

    scroll() {
      let scrollableElement = document.querySelector(".messages");

      scrollableElement.addEventListener("scroll", event => {
        if (this.messages.length < 10) {
          return;
        }
        //let firstMsg = jQuery('.collection-item:first');
        if (event.target.scrollTop < 100 && this.scrollable) {
          this.$store.dispatch("setSending", true);
          this.scrollable = false;

          this.totalChatHeight = this.$refs.chatContainer.scrollHeight;

          messagesRef
            .where("threadId", "==", this.thread.id)
            .where("accountId", "==", this.thread.accountId)
            .startAfter(lastVisible)
            .get()
            .then(querySnapshot => {
              this.$store.dispatch("setSending", false);
              querySnapshot.forEach(doc => {
                this.scrollable = true;
                let messageObj = MessageModel(doc);
                this.messages.unshift(messageObj);
                this.scrollTo();
                lastVisible = doc;
              });
              this.messages = this.messages.sort((a, b) => a.secs - b.secs);
            });
        }
      });
    },
    scrollToEnd() {
      this.$nextTick(() => {
        var container = this.$el.querySelector(".messages");
        container.scrollTop = container.scrollHeight;
      });
    },
    scrollTo() {
      this.$nextTick(() => {
        let currentHeight = this.$refs.chatContainer.scrollHeight;
        let difference = currentHeight - this.totalChatHeight;
        var container = this.$el.querySelector(".messages");
        container.scrollTop = difference;
      });
    },
    prevDate(index) {
      if (index === 0) {
        return "";
      } else {
        let prevMessage = this.messages[index - 1];
        return moment(prevMessage.timestamp).format("L");
      }
    }
  }
};
</script>

<style lang="scss">
.messages {
  height: calc(100vh - 400px);
  overflow: auto;
  padding: 0px;
  li.dir-outgoing,
  li.dir-o {
    margin-left: auto;
    text-align: right;
  }
  .chat-name {
    display: block;
    margin-bottom: 5px;
  }
  .chat-bubble {
    display: inline-block;
    font-size: 14px;
    max-width: 70%;
  }
  .bg-light-info {
    text-align: right;
  }

  .bg-light-inverse {
    text-align: left;
  }
  .message-body {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.08);
    max-width: 80%;
    min-width: 650px;
    padding: 0.5rem;
    border-radius: 10px 10px 10px 10px;
    word-wrap: break-word;
  }
  li {
    list-style: none;
    font-size: 14px;
    &:first-child .message-body {
      border-radius: 10px 10px 10px 10px;
    }
    &:last-child .message-body {
      border-radius: 10px 10px 10px 10px;
    }
    &.dir-outgoing {
      .message-body {
        border-radius: 10px 10px 10px 10px;
        background-color: #eef2ff;
        &.message-note {
          background-color: #bbf7de;
          color: #069058;
        }
      }
      &:first-child .message-body {
        border-radius: 10px 10px 10px 10px;
      }
      &:last-child .message-body {
        border-radius: 10px 10px 10px 10px;
      }
    }
  }
}
@media only screen and (max-width: 960px) {
  .messages {
    height: calc(100vh - 380px);
  }
}
</style>
