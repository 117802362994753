<template>
  <div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Are you absolutely sure?</md-dialog-title>
      <md-dialog-content class="md-body-1">
        <p>Please type <strong>{{confirmString}}</strong> to confirm</p>
        <md-field>
          <label>Please type... </label>
          <md-input v-model="inputString" />
        </md-field>
        <p class="text-warning">It will delete all threads and messages from this channel.</p>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button 
          class="md-primary"
          @click="showDialog = false; inputString = ''"
        >
          Close
        </md-button>
        <md-button 
          class="md-primary md-raised" 
          :disabled="inputString!==confirmString" 
          @click="deleteChannel"
        >
          Delete
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-button 
      class="md-accent" 
      @click="showDialog = true"
    >
      <md-icon>delete</md-icon>
      Delete
    </md-button>
    <md-snackbar :md-active.sync="deleted">
      The channel name was deleted with success!
    </md-snackbar>
  </div>
</template>
<script>
import {db} from '@/firebase/init'
import {mapGetters} from 'vuex'

let refChannels = db.collection('channels')

export default {
  name: 'DeleteChannel',
  props: [
    'channel'
  ],
  computed: { 
    ...mapGetters({
      sending: 'sending'
    }),
  },
  data: () => ({
    deleted: false,
    showDialog: false,
    confirmString: 'I am sure I want to delete this channel',
    inputString: ''
  }),
  methods: {
    deleteChannel() {
      this.$store.dispatch('setSending', true)
      refChannels.doc(this.channel.id).delete().then(() => {
        this.$store.dispatch('setSending', false)
        this.deleted = true
      }).catch(err => {
        console.log(err)
      })
    }
  } 
}
</script>