import moment from "moment";
import dateFormat from "@/includes/dateFormat"
import TransformTags from "@/models/functions/TransformTags";

export default function LeadModel(leadObj, accounts) {
  var leadsInfoBlocks = [];
  if (leadObj.displayAccount) {
    leadsInfoBlocks.push({
      //title: "Account",
      title: leadObj.status,
      description: getDateFromString(leadObj.displayAccount.replace("\n", "<br />")),
    });
  }
  if (leadObj.displayTrial) {
    leadsInfoBlocks.push({
      title: "Trial",
      description: leadObj.displayTrial.replace("\n", "<br />"),
    });
  }

  if (leadObj.lastContacted && typeof leadObj.lastContacted !== "string") {
    leadsInfoBlocks.push({
      title: "Last Contacted",
      description: leadObj.lastContactedMessage + " - " + dateFormat(leadObj.lastContacted.toDate()),
    });
  }
  if (leadObj.visitName && leadObj.visitDate) {
    leadsInfoBlocks.push({
      title: "Last Visit",
      description: dateFormat(leadObj.visitDate.toDate()) + " - " + leadObj.visitName,
    });
  }


  const days = leadDays(leadObj);
  return {
    id: leadObj.id,
    accountId: leadObj.accountId,
    contracts: transformContracts(leadObj.contracts),
    contactInfo: {
      id: "",
      accountId: "",
      displayName: leadObj.displayName,
      phone: leadObj.phone,
      email: leadObj.email,
      photoUrl: leadObj.photoUrl
    },
    contact: {
      id: leadObj.id,
      firstName: leadObj.firstName,
      lastName: leadObj.lastName,
      integrationId: leadObj.integrationId,
      email: leadObj.email,
      phone: leadObj.phone,
      accountId: leadObj.accountId,
    },
    days: days,
    integrationId: leadObj.integrationId,
    leadsInfo: leadsInfoBlocks,
    leadStatus: leadObj.leadStatus,
    lastUpdated: leadObj.lastUpdated,
    leadCreated: leadObj.leadCreated,
    notes: leadObj.notes,
    tags: leadObj.tags,
    tagsShow: TransformTags(leadObj, accounts),
    visits: transformVisits(leadObj.visits),
    dueTime: dueTime(days, leadObj.leadStatus),
    reviewText: reviewText(days, leadObj.leadStatus),
    reviewTextTitle: reviewTextTitle(days, leadObj.leadStatus),
    reviewTextSubHeading: reviewTextSubHeading(days, leadObj.leadStatus),
    status: leadObj.status
  };
}


function transformContracts(contracts) {
  if (!contracts) {
    return []
  }
  contracts = Object.values(contracts);
  contracts.sort((a, b) => b.startDate.seconds - a.startDate.seconds);
  return contracts;
}

function transformVisits(visits) {
  if (!visits) {
    return []
  }
  visits = Object.values(visits);
  visits.sort((a, b) => b.startDate.seconds - a.startDate.seconds);
  return visits;
}

function leadDays(leadObj) {
  let days = "";


  if (leadObj.leadCompletedDate) {
    days = moment().diff(moment(leadObj.leadCompletedDate.toDate()), "days")
  } else if (leadObj.leadLost) {
    days = moment().diff(moment(leadObj.leadLost.toDate()), "days")
  } else if (leadObj.leadReviewDate) {
    days = moment().diff(moment(leadObj.leadReviewDate.toDate()), "days")
  } else if (leadObj.leadCreated) {
    days = moment().diff(moment(leadObj.leadCreated.toDate()), "days")
  } else if (leadObj.createdAt) {
    //days = moment().diff(moment(leadObj.createdAt.toDate()), "days")
  }
  return days;
}

function dueTime(days, status) {
  if (status == 'lead') {
    if (days < 0) {
      return "future";
    } else if (days > 15) {
      return "overdue";
    } else {
      return "due"
    }
  } else {
    return status
  }

}

function reviewTextTitle(days, status) {
  let text = ""
  if (status == 'lead') {
    if (days > -1) {
      text = "Review Due"
    } else if (days > -2) {
      text = "Review"
    } else {
      text = "Review in"
    }
  } else if (status == 'won') {
    text = 'Won'
  } else if (status == 'lost') {
    text = 'Lost'
  }
  return text;
}

function reviewTextSubHeading(days, status) {
  let text = ""
  if (days > 13) {
    const week = Math.round(days / 7)
    text = week + " weeks ago"
  } else if (days > 7) {
    text = "1 week ago"
  } else if (days > 1) {
    text = days + " days ago"
  } else if (days > 0) {
    text = "Yesterday"
  } else if (days > -1) {
    text = "Today"
  } else if (days > -2) {
    text = "Tomorrow"
  } else if (days > -7) {
    text = Math.abs(days) + " days"
  } else if (days > -14) {
    text = "1 week"
  } else {
    const week = Math.round(Math.abs(days) / 7)
    text = week + " weeks"
  }

  return text;
}

function reviewText(days, status) {
  return reviewTextTitle(days, status) + " " + reviewTextSubHeading(days, status);
}

function getDateFromString(originalString)
{
  // Regular expression to match the date part of the string
  const dateRegex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/;

  // Extract the date portion from the string
  const match = originalString.match(dateRegex);

  if (match) {
    const originalDate = match[0];

    // Convert and format the date using JavaScript Date object
    const formattedDate = new Date(originalDate).toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    // Add the formatted date to the original string
    return  originalString.replace(dateRegex, formattedDate);

  } else {
    return "Date not found in the string";
  }
}

// function reviewText(days) {
//   let text = ""
//   if (days > 13) {
//     const week = Math.round(days / 7)
//     text = "Review Due " + week + " weeks ago"
//   } else if (days > 7) {
//     text = "Review Due 1 week ago"
//   } else if (days > 1) {
//     text = "Review Due " + days + " days ago"
//   } else if (days > 0) {
//     text = "Review Due Yesterday"
//   } else if (days > -1) {
//     text = "Review Due Today"
//   } else if (days > -2) {
//     text = "Review Tomorrow"
//   } else if (days > -7) {
//     text = "Review in " + Math.abs(days) + " days"
//   } else if (days > -14) {
//     text = "Review in 1 week"
//   } else {
//     const week = Math.round(Math.abs(days) / 7)
//     text = "Review in " + week + " weeks"
//   }

//   return text;
// }