<template>
  <div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Create New Channel</md-dialog-title>
      <FormChannel @close="showDialog = false" :accountId="accountId" />
    </md-dialog>

    <md-button class="md-primary md-raised" @click="showDialog = true">Create Channel</md-button>
  </div>
</template>

<script>
import FormChannel from "@/components/blocks/forms/Channel";

export default {
  name: "CreateChannel",
  props: {
    channel: {
      type: Object,
      default: null
    },
    accountId: {
      type: String,
      default: null
    }
  },
  components: {
    FormChannel
  },
  data: () => ({
    showDialog: false
  })
};
</script>
