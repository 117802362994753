<template>
  <div class="settings-users">
    <md-table>
      <md-table-row>
        <md-table-head class="bg-primary">Name</md-table-head>
        <md-table-head class="bg-primary">Email</md-table-head>
        <md-table-head class="bg-primary">Role</md-table-head>
        <md-table-head class="bg-primary">Action</md-table-head>
      </md-table-row>

      <md-table-row v-for="(user, index) in users" :key="index">
        <md-table-cell>{{user.name}}</md-table-cell>
        <md-table-cell>{{user.email}}</md-table-cell>
        <md-table-cell>{{user.role}}</md-table-cell>
        <md-table-cell>
          <md-button
            class="md-accent"
            @click="isShowConfirm = true; removeAccessId = user.id"
            v-if="user.type != 'invited'"
          >
            Remove Access
            <md-icon>person_remove</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <md-dialog-confirm
      :md-active.sync="isShowConfirm"
      md-title="Remove Access"
      md-content="Are you sure you want to remove access from this user?"
      md-confirm-text="Agree"
      md-cancel-text="Cancel"
      @md-confirm="removeAccess"
      @md-cancel="cancelAccess"
    />
  </div>
</template>

<script>
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";

import DialogCreateUser from "@/components/blocks/dialogs/CreateUser";

// let dbUsers = db.collection("users");

export default {
  name: "SettingsUsers",
  props: ["selectedAccount"],
  components: {
    DialogCreateUser
  },
  data() {
    return {
      users: [],
      fetchUser: false,
      isShowConfirm: false,
      removeAccessId: null
    };
  },
  watch: {
    selectedAccount: {
      // call it upon creation too
      immediate: true,
      handler(newValue, oldValue) {
        if(this.fetchUser == false) {
          this.fetchUsers();
          this.fetchInvites();
        }

        //alert("selectedAccount");
      }
    }
  },
  mounted() {
    //alert("mounted");
  },
  computed: {},
  methods: {
    fetchUsers() {
      this.fetchUser = true;
      this.$store.dispatch("setSending", true);
      this.users = [];
      let accountId = this.selectedAccount.id;

      let access = db
        .collectionGroup("access")
        .where("accountId", "==", accountId);
      access
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            doc.ref.parent.parent
              .get()
              .then(parentDoc => {
                let userData = parentDoc.data();
                  let userObj = {
                    id: parentDoc.id,
                    name: userData.displayName,
                    email: userData.email,
                    role: userData.role ?? "Admin"
                  };
                  this.users.push(userObj);
              })
              .catch(err => {
                this.$store.dispatch("setSending", false);
                this.$store.dispatch("showSnackbar", err);
              });
          });
        })
        .then(() => {
          this.$store.dispatch("setSending", false);
        })
        .catch(err => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", err);
        });
    },
    fetchInvites() {
      db.collection("invites")
        .where("accountId", "==", this.selectedAccount.id)
        .where("accessGranted", "==", false)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let data = doc.data();

            this.users.push({
              id: doc.id,
              name: data.invitedName,
              email: data.invitedEmail,
              role: "Invited",
              type: "invited"
            });
          });
        });
      // .onSnapshot(snapshot => {
      //   snapshot.docChanges().forEach(change => {
      //     let data = change.doc.data();
      //     switch (change.type) {
      //       //case "modified":
      //       case "added":
      //         this.users.push({
      //           id: change.doc.id,
      //           name: data.invitedName,
      //           email: data.invitedEmail,
      //           role: "Invited" + userData.role ?? "Admin"
      //         });
      //     }
      //   });
      // });
    },
    removeAccess() {
      let docId =
        "/" + this.removeAccessId + "/access/" + this.selectedAccount.id;

      let result = db
        .collection("users")
        .doc(docId)
        .delete()
        .then(() => {
          this.$store.dispatch("showSnackbar", "Access removed from user!");
        })
        .catch(error => {
          this.$store.dispatch(
            "showSnackbar",
            "Error removing document" + error
          );
        });
      this.fetchUsers();
      this.removeAccessId = null;
    },
    cancelAccess() {
      this.removeAccessId = null;
    }
  }
};
</script>

<style></style>