import moment from "moment";

export default function ThreadModel(doc, accounts) {
  let threadDoc = doc.data();
  

  let timestamp = "";
  if (threadDoc.hasOwnProperty("latestMessageTime")) {
    timestamp = readTimestamp(threadDoc.latestMessageTime.toDate());
    // timestamp = moment(threadDoc.latestMessageTime.toDate()).format(
    //     "hh:mm A"
    // );
    
  }

  let tags = [];
  let usedBadge = null;
  if (accounts.length > 1) {
    let account = accounts.filter(
      account => account.id === threadDoc.accountId
    );
    if (account.length) {
      usedBadge = account[0].number;
      tags.push({
        name: account[0].name,
        class: "badge-color-" + account[0].number
      });
    }
  }

  if (threadDoc.linkedContact) {
    tags.push({
      name: "Linked Contact",
      class: "badge-color-1"
    });
  }

  let count = 0;
  if (threadDoc.hasOwnProperty("tags")) {
    threadDoc.tags.forEach(tag => {
      if (count === usedBadge) count++;
      tags.push({ name: tag.name, class: "badge-color-" + count });
      count++;
      if (count > 4) count = 0;
    });
  }
  let curIcon = getThreadIcon(threadDoc.channelSystem);
  let thread = {
    id: doc.id,
    //...threadDoc,
    accountId: threadDoc.accountId,
    bgClass: "",
    channelId: threadDoc.channelId,
    channelSystem: threadDoc.channelSystem,
    channelType: threadDoc.channelType,
    content: getExcerpt(
      threadDoc.latestMessageText,
      curIcon === "email" ? 35 : 70
    ),
    draft: threadDoc.draft,
    photoUrl: threadDoc.photoUrl,
    fullData: threadDoc,
    icon: curIcon,
    linkedContact: threadDoc.linkedContact ?? null,
    messageCount: threadDoc.messageIds ? threadDoc.messageIds.length : 0,
    name: getExcerpt(getName(threadDoc), 25),
    note: threadDoc.note,
    readStatus: threadDoc.readCount && threadDoc.messageIds ? !(threadDoc.readCount - threadDoc.messageIds.length) : false,
    secs: threadDoc.latestMessageTime.seconds,
    status: threadDoc.status,
    subject:
      curIcon === "email" ? getExcerpt(threadDoc.subject, 35) : "",
    tags,
    timestamp: timestamp,
    to: threadDoc.email || threadDoc.phone || threadDoc.displayName,
    unreadCount: threadDoc.unreadCount,
  };
  
  return thread;
}

function readTimestamp(dateTime) {
  let today = moment().format("DDMMYYYY");
  let yesterday = moment()
    .subtract(1, "days")
    .format("DDMMYYYY");
  let aDateTime = moment(dateTime).format("DDMMYYYY");

  let output = moment(dateTime).format("ll");
  if (aDateTime == today) {
    output = moment(dateTime).format("hh:mm A");
  } else if (aDateTime == yesterday) {
    output = "Yesterday";
  }
  return output;
}

function getThreadIcon(channelSystem) {
  let icon = "";
  if (channelSystem === "Nylas") {
    icon = 'email';
  } else if (channelSystem == "Facebook Messenger" || channelSystem == "Facebook") {
    icon = 'facebook'
  } else {
    icon = 'phone'
  }
  return icon;
}

function getExcerpt(content, length) {
  if (content) {
    let curIndex = content.lastIndexOf(" ", length);
    if (curIndex === -1) curIndex = length;
    return content.length > length
      ? content.substr(0, curIndex) + "..."
      : content;
  } else {
    return "";
  }
}

function getName(thread) {
  let name = "";
  if (thread.hasOwnProperty("displayName") && thread.displayName && thread.displayName !== "Display Name Missing") {
    name = thread.displayName;
  } else if (thread.hasOwnProperty("tempContact") && thread.tempContact) {
    let nameObj = thread.tempContact;
    if (nameObj.hasOwnProperty("firstName") && nameObj.firstName) {
      name = nameObj.firstName;
    }
    if (
      nameObj.hasOwnProperty("firstName") +
      nameObj.hasOwnProperty("lastName") &&
      nameObj.firstName &&
      nameObj.lastName
    ) {
      name += " ";
    }
    if (nameObj.hasOwnProperty("lastName") && nameObj.lastName) {
      name += nameObj.lastName;
    }

    if (!name) {
      if (nameObj.hasOwnProperty(0) && nameObj[0].hasOwnProperty("email")) {
        name = nameObj[0].email;
      } else if (thread.hasOwnProperty("phone")) {
        name = thread.phone;
      } else {
        name = "Unknown User";
      }
    }
  } else {
    name = thread.name;
  }
  return name;
}
