import moment from "moment";
import dateFormat from "@/includes/dateFormat"
import TransformTags from "@/models/functions/TransformTags";

export default function ContactModel(doc, accounts) {

  let contactObj = doc.data();
  var leadsInfoBlocks = [];
  if (contactObj.displayAccount) {
    leadsInfoBlocks.push({
      title: "Account",
      description: contactObj.displayAccount.replace("\n", "<br />"),
    });
  }
  if (contactObj.displayTrial) {
    leadsInfoBlocks.push({
      title: "Trial",
      description: contactObj.displayTrial.replace("\n", "<br />"),
    });
  }


  let contact = {
    id: doc.id,
    ...contactObj,
    leadsInfo: leadsInfoBlocks,
  };
  contact.tags = TransformTags(contactObj, accounts);
  return contact;
  return {
    id: doc.id,
    name: this.getName(threadDoc.tempContact),
    content: this.getExcerpt(threadDoc.latestMessageText),
    timestamp: moment(threadDoc.latestMessageTime.toDate()).format("lll"),
    secs: threadDoc.latestMessageTime.seconds,
    color: this.getRandomColor(),
    initials: this.getInitial(threadDoc.tempContact),
    bgClass: "",
  };
}