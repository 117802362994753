<template>
  <div>
    <p v-if="status === 'connected'">
      Connected: {{profile.name}} ({{profile.id}})
      <a
        href="javascript://"
        @click="fetchPages"
      >Sync Pages</a>
    </p>
    <md-button
      class="md-raised md-primary"
      @click="logInWithFacebook"
      v-if="status && status !== 'connected'"
    >Connect Facebook</md-button>
  </div>
</template>
<script>
export default {
  name: "FacebookIntegration",
  data() {
    return {
      status: null,
      profile: {},
      accounts: {},
      form: {
        channelSystem: null,
      },
    };
  },
  mounted() {
    this.mounted();
    if (typeof window.FB !== "undefined") {
      window.FB.getLoginStatus((response) => {
        // Called after the JS SDK has been initialized.
        this.statusChangeCallback(response); // Returns the login status.
        //console.log("statusChangeCallback");
        //console.log(response);
      });
    }
  },
  computed: {
    facebookPages() {
      if (this.accounts.hasOwnProperty("data")) {
        return this.accounts.data;
      } else {
        return [];
      }
    },
  },
  methods: {
    async mounted() {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
    },
    async logInWithFacebook() {
      window.FB.login((response) => {
        this.statusChangeCallback(response);
      });
      return false;
    },
    statusChangeCallback(response) {
      // Called with the results from FB.getLoginStatus().
      console.log("statusChangeCallback");
      console.log(response); // The current login status of the person.
      this.status = response.status;
      if (response.status === "connected") {
        this.getProfile();
        this.getAccounts();
      } else {
      }
    },
    fetchPages() {
      window.FB.login(
        (response) => {
          this.statusChangeCallback(response);
        },
        {
          scope: "pages_show_list,pages_messaging",
          auth_type: "rerequest",
        }
      );
      return false;
    },
    linkPage(item) {
      console.log(item);
    },
    getProfile() {
      console.log("fetch profile");
      FB.api("/me", (response) => {
        if (response.error) {
          this.status = "error";
        } else {
          this.profile = response;
        }
        console.log(response);
      });
    },
    getAccounts() {
      console.log("fetch accounts");
      FB.api("/me/accounts", (response) => {
        this.accounts = response;
        this.$store.dispatch("setFacebookPages", response.data);
        console.log(response);
      });
    },
    async initFacebook() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: "1086157704917303", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          xfbml: true,
          version: "v8.0",
        });

        FB.getLoginStatus((response) => {
          // Called after the JS SDK has been initialized.
          this.statusChangeCallback(response); // Returns the login status.
          //console.log("statusChangeCallback");
          //console.log(response);
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      if (typeof window.FB !== "undefined") {
        return;
      }
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
  },
};
</script>
<style>
.button {
  color: white;
  min-width: 150px;
  background-color: #000000a1;
  height: 2.5rem;
  border-radius: 2rem;
  font-weight: 400;
  font-size: 0.8rem;
}
</style>