<template>
  <div class="leads px-md-3">
    <md-toolbar>
      <div class="main-info" style="flex:1">
        <h3 class="md-title float-left">
          <!-- <md-icon>keyboard_arrow_down</md-icon> -->
          <md-menu md-size="medium" md-align-trigger>
<!--            <md-button md-menu-trigger mdCloseOnSelect="true" class="btn-title">
              {{ statusOptions[leadStatus] }}
&lt;!&ndash;              <md-icon>keyboard_arrow_down</md-icon>&ndash;&gt;
            </md-button>-->

<!--            <md-button md-menu-trigger mdCloseOnSelect="true" class="btn-title" v-for="(status, key) in statusOptions" :key="key" @click="leadStatus = key" :to="{ name: status }">-->
            <md-button md-menu-trigger mdCloseOnSelect="true" class="btn-title" v-for="(status, key) in statusOptions" :key="key" @click="leadStatusChange(key)">
              {{ status }}
            </md-button>
<!--            <md-menu-content>
              <md-menu-item v-for="(status, key) in statusOptions" :key="key" @click="leadStatus = key"
                :to="{ name: status }">{{ status }}</md-menu-item>
            </md-menu-content-->
          </md-menu>
        </h3>
        <!-- <span class="custom-badge status-primary ml-3">{{leads.length}}</span> -->
      </div>
      <DialogCreateLead />
      <LeadBulkActions :leads="this.leads" :leadStatus="leadStatus" class="ml-2" />
      <!-- <md-button class="md-raised md-primary">Add Lead</md-button> -->
      <md-menu md-size="medium" md-align-trigger :mdCloseOnClick="closeOnClick" :mdCloseOnSelect="closeOnSelect">
        <md-button md-menu-trigger class="md-icon-button">
          <md-icon>filter_list</md-icon>
        </md-button>

        <md-menu-content class="px-3 md-elevation-20 bg-white account-filter">
          <div class="md-title text-gray my-2"> Leads Filters</div>
          <md-menu-item @click="$event => selectedTimeFilter = 'lastUpdated'">

            <md-radio v-model="selectedTimeFilter" value="lastUpdated" @click="$event => !closeOnSelect"> Last Updated</md-radio>
          </md-menu-item>
          <md-menu-item @click="selectedTimeFilter = 'leadReviewDate'">

            <md-radio v-model="selectedTimeFilter" value="leadReviewDate" @click="$event => !closeOnSelect"> Last Reviewed</md-radio>
          </md-menu-item>
          <md-menu-item @click="selectedTimeFilter = 'leadCreated'">
            <md-radio v-model="selectedTimeFilter" @click="$event =>! closeOnClick" value="leadCreated"> Last
              Created</md-radio>
          </md-menu-item>
        </md-menu-content>
      </md-menu>
    </md-toolbar>
    <md-list class="md-elevation-4">

   <ItemLead v-if="filteredLeads.length > 0" v-for="lead in filteredLeads" :key="lead.id" :leadObj="lead" :accounts="accounts" />
   <h2 v-else>No leads found</h2>
    </md-list>
    <md-list-item v-if="scrollProgress">
      <!-- <div class="w-100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></div> -->
      <md-progress-spinner md-mode="indeterminate" :md-stroke="3" class="mx-auto"></md-progress-spinner>
    </md-list-item>
  </div>
</template>

<script>
import DialogCreateLead from "@/components/blocks/dialogs/CreateLead";
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";

import FiltersAccounts from "@/components/blocks/filters/Accounts";
import ItemLead from "@/components/blocks/items/Lead";
import LeadBulkActions from "@/components/blocks/actions/LeadBulkActions";
import LeadsFilter from "../blocks/filters/LeadsFilter.vue";

//const leadsRef = db.collection("contacts").orderBy('lastUpdated', "desc");
//this was leadReviewDate previously

let lastVisible;

export default {
  name: "Leads",
  components: {
    DialogCreateLead,
    FiltersAccounts,
    ItemLead,
    LeadBulkActions,
    LeadsFilter
  },
  data() {
    return {
      showCount: 40,
      fetchedAccounts: [],
      //displayLastCreatedLeads:["lastUpdated","lastCreated"],
      selectedTimeFilter: 'lastUpdated',
      leads: [],
      scrollPosition: null,
      scrollProgress: false,
      scrollCheck: true,
      leadStatus: "lead",
      statusOptions: {
        all: "All Leads",
        lead: "Active Leads",
        won: "Won Leads",
        //"S": "Starred",
        lost: "Lost Leads",
      },
      closeOnClick: true,
      closeOnSelect: true,
      unsubscribe: null,
    };
  },
  computed: {
    ...mapGetters({
      accounts: "accounts",
      selectedAccounts: "selectedAccounts",
    }),
    filteredLeads() {

      let leads = this.leads.filter(
        (lead) => this.selectedAccounts.indexOf(lead.accountId) !== -1
      );
      return leads;
    },
  },
  watch: {
    // selectedAccounts: {
    //   handler(accounts) {
    //     console.log("watcher :: selectedAccounts ---", this.selectedAccounts)
    //     console.log("watcher :: selectedAccounts ::accounts---", accounts)
    //     this.leadsHandler(accounts, false);
    //     console.log("this.leads---", this.leads)
    //     // this.updateLeadCreated(accounts);
    //   }
    // },
    selectedTimeFilter(value) {
      this.leadsHandler(this.selectedAccounts, true)
    },
    selectedAccounts(newValue, oldValue) {

      // merge two arrays
      let arr = newValue.concat(oldValue);
      let uniqueArr = [];

      // loop through array
      for (let i of arr) {
        if (uniqueArr.indexOf(i) === -1) {
          uniqueArr.push(i);
        }
      }
      this.leadsHandler(this.selectedAccounts, true)
    },
    showCount: {
      handler(showCount) {

        this.leadsHandler(this.selectedAccounts, true);
      },
    },
    leadStatus: {
      handler(leadStatus) {

        this.leadsHandler(this.selectedAccounts, this.leadStatus);
      },

    },
  },
  // firestore: {
  //   leads: db.collection("contacts").where("leadStatus","==","lead")
  // },
  mounted() {
    window.addEventListener("scroll", this.scrollHandler);

    let route = this.$route.name;

    this.leadsHandler(this.selectedAccounts, false)

    this.leadStatus = this.routeToLeadStatus(route);



  },
  methods: {
    leadsHandler(accounts, countChanged) {

      // Initialize this.unsubscribe as a no-op function
      this.unsubscribe = () => {};
      let newAccounts = this.selectedAccounts.filter(
        (acountId) => !this.fetchedAccounts.includes(acountId)
      );

      if (!newAccounts.length && !countChanged) {
        return;
      }

      this.fetchedAccounts = [...this.selectedAccounts];

      // this.$bind(
      //   "leads",
      //   leadsRef.where("accountId", "in", accounts).limit(20)
      //   //.limit(this.showCount)
      // ).then(leads => {
      //   // user will point to the same property declared in data:
      //   // this.user === user
      //   if (this.scrollPosition) {
      //     window.scrollTo(0, this.scrollPosition);
      //   }
      //   lastVisible = leads[leads.length - 1];
      // });
      //alert("leads called");
      this.leads = [];
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      if (this.leadStatus != "all") {


         this.unsubscribe = db.collection("contacts")
             .limit(this.showCount)
             .where("accountId", "in", accounts)
             .where("leadStatus", "==", this.leadStatus)
             .orderBy(this.selectedTimeFilter, "desc")
             .get()
             .then(querySnapshot => {
             //.onSnapshot((querySnapshot) => {
           querySnapshot.forEach((doc) => {
             this.leads = [...this.leads, {id:doc.id,...doc.data()}];
             lastVisible = doc;
           });
         })
      }else{
        // console.log("leadStatus in else----",this.leadStatus)
        this.unsubscribe = db.collection("contacts").limit(this.showCount)
        .where("accountId", "in", accounts).orderBy(this.selectedTimeFilter, "desc")
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            this.$store.dispatch("setSending", false);
            let doc = change.doc;

            let leadObj = { id: doc.id, ...doc.data() };
            // if (!leadObj.leadReviewDate) {
            //   doc.ref.update({
            //     leadReviewDate: leadObj.leadCreated,
            //   });
            //   console.log("lead updated");
            //   //this.$store.dispatch("showSnackbar", "lead updated");
            // }
            if (change.type == "modified") {

              //remove the contact
              // this.leads = JSON.parse(JSON.stringify(this.leads.filter((lead) => lead.id !== leadObj.id)));
              // this.leads.push(leadObj); //add contact on top
              // this.leads.splice(0, 0, leadObj); //add contact on top
              this.leadsHandler(this.selectedAccounts, true);
            } else if (change.type == "added") {

              this.leads.push(leadObj);

            } else if (change.type == "removed") {
              this.leads = this.leads.filter((lead) => lead.id !== leadObj.id);
            }
            lastVisible = doc;
          });
        });
      }
      //
    },
      scrollHandler(event) {
        if (this.leads.length < this.showCount) {
          return;
        }

        if (
          !this.scrollProgress &&
          window.innerHeight + window.scrollY + 100 >=
          document.body.offsetHeight &&
          this.scrollCheck
        ) {
          // this.scrollPosition = window.scrollY;
          // this.showCount += 20;
          this.scrollProgress = true;
          this.$store.dispatch("setSending", true);

          if (this.leadStatus != "all") {
            db.collection("contacts")
                .limit(this.showCount)
                .where("accountId", "in", this.selectedAccounts)
                .where("leadStatus", "==", this.leadStatus)
                .orderBy(this.selectedTimeFilter, "desc")
                .startAfter(lastVisible)
                .get()
            .then((querySnapshot) => {
              if (querySnapshot.empty) {
                //console.log("found empty");
                this.scrollCheck = false;
              }
              this.scrollProgress = false;
              this.$store.dispatch("setSending", false);
              querySnapshot.forEach((doc) => {
                let leadObj = { id: doc.id, ...doc.data() };
                this.leads.push(leadObj);
                lastVisible = doc;
              });
            })
            .catch((err) => {
              this.$store.dispatch("showSnackbar", err);
            });

          }
          else{
          db.collection("contacts")
            .limit(this.showCount)
            .where("accountId", "in", this.selectedAccounts)
            .startAfter(lastVisible)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.empty) {

                this.scrollCheck = false;
              }
              this.scrollProgress = false;
              this.$store.dispatch("setSending", false);
              querySnapshot.forEach((doc) => {
                let leadObj = { id: doc.id, ...doc.data() };
                this.leads.push(leadObj);
                lastVisible = doc;
              });
            })
            .catch((err) => {
              this.$store.dispatch("showSnackbar", err);
            });
        }}
      },
      updateLeadCreated(accounts) {
        db.collection("contacts")
          .where("leadStatus", "==", "lead")
          .where("accountId", "in", accounts)
          .get()
          .limit(100)
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let data = doc.data();

              if (!data.leadCreated) {
                doc.ref.update({
                  leadReviewDate: data.leadCreated,
                });
                this.$store.dispatch("showSnackbar", "lead updated");
              }
            });
          })
          .catch((err) => {
            this.$store.dispatch("showSnackbar", err);
          });
      },
      routeToLeadStatus(routeName) {
        if (routeName == "Leads") {
          return "lead";
        }
        return Object.keys(this.statusOptions)[
          Object.values(this.statusOptions).indexOf(routeName)
        ];
      },
    leadStatusChange(status)
    {
      this.leadStatus = status;
      this.leadsHandler(this.selectedAccounts, false)

     /* if(status == "lead")
      {
        location.href = '/leads/';
        return;
      }
        location.href = '/leads/'+status;*/

    }
    },
    destroyed() {

      window.removeEventListener("scroll", this.scrollHandler);
      if (this.unsubscribe) {
        this.unsubscribe();
      }
    },
  };
</script>

<style lang="scss">
.one-lead {
  .lead-day-container {
    width: 100px;
    height: auto;
    float: left;
    background: #510fa8;
    color: white;
    text-align: center;
    font-size: 1.25rem;
    border-radius: 10px 0px 0px 10px;
    position: relative;

    &.status-overdue,
    &.status-lost {
      background: #f44336;
    }

    &.status-due {
      background: purple;
    }

    &.status-future,
    &.status-won {
      background: #4caf50;
    }

    .lead-day {
      position: absolute;
      width: 100px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .md-title {
        white-space: initial;
        font-size: 18px;
      }
    }
  }

  .lead-content {
    width: calc(100% - 10px);
    background-color: #f7f7fa;
    border-radius: 0px 10px 10px 0px;
    height: 140px;

    &:hover {
      background-color: #eef2ff;
    }

    .main-info {
      .contact-name {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .contact-info {
      font-size: 13px;

      .md-icon {
        font-size: 18px !important;
      }
    }
  }

  .lead-info-container {
    overflow-y: auto;

    .md-theme-default.lead-info {
      width: 200px;
      background-color: #510fa8;
      color: white;
      float: left;
      border-radius: 10px;
      margin: 5px;

      .md-card-header {
        font-size: 16px;
        font-weight: 500;
      }

      .md-card-content {
        font-size: 13px;
      }
    }
  }
}

.leads .md-list.md-theme-default .md-list-item-container:not(.md-list-item-default):not(.md-list-item-expand):not([disabled]):hover {
  background: transparent;
}
</style>
