<template>
  <md-menu md-size="medium" md-align-trigger :mdCloseOnSelect="true">
    <md-button class="md-raised md-primary" md-menu-trigger>Bulk Actions</md-button>
    <md-menu-content>
      <md-menu-item @click="snoozeLeads(1, '24 hours')">Delay for 24 hours</md-menu-item>
      <md-menu-item v-if="leadStatus === 'lead'" @click="lostLeads()">Mark as Lead Lost</md-menu-item>
      <md-menu-item @click="snoozeLeads(0, 'Review Today')">Review Today</md-menu-item>
    </md-menu-content>
  </md-menu>
</template>
<script>
import { db } from "@/firebase/init";
import firebase from "firebase";
import { mapGetters } from "vuex";
import moment from "moment";
//import app mixins
import LogAcivityMixin from "@/mixins/LogActivityMixin";

export default {
  name: "LeadBulkActions",
  props: {
    leads: {
      type: Array,
      default: null,
    },
    leadStatus: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  mixins: [LogAcivityMixin],
  methods: {
    lostLeads() {
      //set progress bar
      this.$store.dispatch("setSending", true);

      let leads = this.leads;
      let user = this.user;

      //prepare common data
      let contactRef = null;
      let messageRef = null;
      let messageText = user.data.displayName + " marked the lead as lost";
      let activityMessageDetails = {
        status: "lost",
        updatedBy: {
          name: user.data.displayName,
          id: user.data.uid,
        },
      };

      // Get a new write batch
      let batch = db.batch();

      //create batch items for all leads
      leads.map((lead) => {
        contactRef = db.collection("contacts").doc(lead.id);
        batch.update(contactRef, {
          leadStatus: "lost",
          leadCompletedDate: firebase.firestore.Timestamp.now(),
        });

        messageRef = db.collection("messages").doc();
        batch.set(
          messageRef,
          this.logActivityObject(
            messageText,
            lead.accountId,
            lead.id,
            activityMessageDetails
          )
        );
      });

      //commit batch
      batch
        .commit()
        .then(() => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch(
            "showSnackbar",
            leads.length + " Leads marked lost successfully"
          );
        })
        .catch((err) => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", err);
        });
    },
    snoozeLeads(days, snoozeText) {
      //set progress bar
      this.$store.dispatch("setSending", true);

      let leads = this.leads;
      let user = this.user;
      
      //prepare common data
      let contactRef = null;
      let messageRef = null;
      let messageText =
        user.data.displayName + " snoozed the lead for " + snoozeText;
      let activityMessageDetails = {
        days: days,
        updatedBy: {
          name: user.data.displayName,
          id: user.data.uid,
        },
      };
      const leadDate = moment().add(days, "d").toDate();

      // Get a new write batch
      let batch = db.batch();

      //create batch items for all leads
      leads.map((lead) => {
        contactRef = db.collection("contacts").doc(lead.id);
        batch.update(contactRef, {
          leadReviewDate: firebase.firestore.Timestamp.fromDate(leadDate),
        });

        messageRef = db.collection("messages").doc();
        batch.set(
          messageRef,
          this.logActivityObject(
            messageText,
            lead.accountId,
            lead.id,
            activityMessageDetails
          )
        );
      });

      //commit batch
      batch
        .commit()
        .then(() => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch(
            "showSnackbar",
            leads.length + " Leads updated successfully"
          );
        })
        .catch((err) => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", err);
        });
    },
  },
};
</script>