<template>
  <div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>
        {{ todo ? 'Edit Action-item' : 'Create New To Do' }}
      </md-dialog-title>

      <FormActionItem
          v-if="todo"
          @close="showDialog = false"
          :form="todo"
          form-type="edit"
          :lead="lead"
      />
      <FormActionItem
          v-else
          @close="showDialog = false"
          form-type="add"
          :lead="lead"
      />

    </md-dialog>

    <md-button
        v-if="todo"
        class="md-primary"
        @click="showDialog = true"
    >
      <md-icon>edit</md-icon>
      Edit
    </md-button>
    <md-button
        v-else
        class=""
        @click="showDialog = true"
    >
      <md-icon
          class="md-primary mx-1 mb-3 text-white" title="Add Action Item">event</md-icon>
    </md-button>
  </div>
</template>

<script>
import FormActionItem from '@/components/blocks/forms/FormActionItem'

export default {
  name: 'CreateToDo',
  props: {
    todo: {
      type: Object,
      default: null
    }, lead: {
      type: Object,
      default: null
    }
  },
  components: {
    FormActionItem
  },
  data: () => ({
    showDialog: false
  })
}
</script>
