<template>
  <md-snackbar :md-active.sync="snackbar.show">{{snackbar.message}}</md-snackbar>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "SnackBar",
  computed: {
    ...mapGetters({
      snackbar: "snackbar"
    })
  }
};
</script>