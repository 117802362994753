<template>
  <div>
    <form novalidate class="md-layout" @submit.prevent="validateUser">
      <md-card class="md-layout-item md-size-100 md-small-size-100 p-3">
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('firstName')">
                <label for="first-name">First Name</label>
                <md-input
                  name="first-name"
                  id="first-name"
                  autocomplete="given-name"
                  v-model="form.firstName"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.firstName.required">The first name is required</span>
                <span class="md-error" v-else-if="!$v.form.firstName.minlength">Invalid first name</span>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('lastName')">
                <label for="last-name">Last Name</label>
                <md-input
                  name="last-name"
                  id="last-name"
                  autocomplete="family-name"
                  v-model="form.lastName"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.lastName.required">The last name is required</span>
                <span class="md-error" v-else-if="!$v.form.lastName.minlength">Invalid last name</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('email')">
                <label for="email">Email</label>
                <md-input
                  type="email"
                  name="email"
                  id="email"
                  autocomplete="email"
                  v-model="form.email"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.email.required">The email is required</span>
                <span class="md-error" v-else-if="!$v.form.email.email">Invalid email</span>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('phone')">
                <label for="phone">Phone</label>
                <md-input
                  type="text"
                  name="phone"
                  id="phone"
                  autocomplete="phone"
                  v-model="form.phone"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.phone.required">The phone is required</span>
                <span class="md-error" v-else-if="!$v.form.phone.minlength">Invalid phone</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('accountId')">
                <label for="accountId">Account</label>
                <md-select
                  name="accountId"
                  id="accountId"
                  v-model="form.accountId"
                  md-dense
                  :disabled="sending || lockedAccount"
                >
                  <md-option
                    v-for="account in accounts"
                    :value="account.id"
                    :key="account.id"
                  >{{account.name}}</md-option>
                </md-select>
                <span class="md-error" v-if="!$v.form.accountId.required">The account is required</span>
              </md-field>
            </div>
          </div>

          <!-- <md-chips v-model="form.tags" md-placeholder="Add tags..."></md-chips> -->
        </md-card-content>

        <md-card-actions>
          <md-button type="button" class="md-secondary" @click="close()">Cancel</md-button>
          <md-button
            type="submit"
            class="md-raised md-primary"
            :disabled="sending"
          >{{ lead === true ? "Create Lead" : (formType === 'add' ? 'Create Contact' : 'Update Contact') }}</md-button>
        </md-card-actions>
      </md-card>

      <md-snackbar :md-active.sync="userSaved">The user {{ lastUser }} was saved with success!</md-snackbar>
    </form>
  </div>
</template>

<script>
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";
import firebase from "firebase";
import LogAcivityMixin from "@/mixins/LogActivityMixin";

let contactsRef = db.collection("contacts");

import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "ContactForm",
  props: {
    lockedAccount: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: function () {
        return {
          id: null,
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
          accountId: null,
        };
      },
    },
    threadId: {
      type: String,
      default: null,
    },
    lead: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [validationMixin, LogAcivityMixin],
  computed: {
    ...mapGetters({
      accounts: "accounts",
      sending: "sending",
      user: "user",
    }),
  },
  data: () => ({
    formType: "add",
    form: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      accountId: null,
    },
    contactId: null,
    userSaved: false,
    lastUser: null,
    initialValues: {},
  }),
  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(3),
      },
      lastName: {
        minLength: minLength(1),
      },
      email: {
        email,
      },
      phone: {
        minLength: minLength(8),
      },
      accountId: {
        required,
      },
    },
  },
  mounted() {
    if (this.contact) {
      this.formType = this.contact.id ? "edit" : "add";
      this.form.firstName = this.contact.firstName ?? null;
      this.form.lastName = this.contact.lastName ?? null;
      this.form.email = this.contact.email ?? null;
      this.form.phone = this.contact.phone ?? null;
      this.form.accountId = this.contact.accountId ?? null;
      this.form.photoUrl = this.contact.photoUrl ?? null;
      this.contactId = this.contact.id ?? null;
      this.initialValues = { ...this.form };
    } else {
      this.formType = "add";
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.firstName = null;
      this.form.lastName = null;
      this.form.email = null;
      this.form.phone = null;
      this.form.accountId = null;
      this.form.photoUrl = null;
    },
    async saveUser() {
      this.$store.dispatch("setSending", true);
      // Instead of this timeout, here you can call your API

      let displayName = this.form.lastName
        ? `${this.form.firstName} ${this.form.lastName}`
        : this.form.firstName;
      if (this.formType === "add") {
        let contactDoc = contactsRef.doc();
        contactDoc
          .set({
            ...this.form,
            displayName,
            createdAt: firebase.firestore.Timestamp.now(),
            lastUpdated: firebase.firestore.Timestamp.now(),
            origin: "connect-app",
            leadStatus: this.lead ? "lead" : "",
            leadCreated: this.lead ? firebase.firestore.Timestamp.now() : null,
            leadReviewDate: this.lead
              ? firebase.firestore.Timestamp.now()
              : null,
          })
          .then(async () => {
            this.$emit("added", contactDoc.id, displayName);
            this.lastUser = displayName;
            this.userSaved = true;
            this.$store.dispatch("setSending", false);
            this.clearForm();
            this.close();
          })
          .catch((err) => {
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", err);
          });
      } else {
        contactsRef
          .doc(this.contactId)
          .update({
            ...this.form,
            displayName,
            lastUpdated: firebase.firestore.Timestamp.now(),
          })
          .then(async () => {
            this.lastUser = displayName;
            this.userSaved = true;
            await this.logContactActivity();
            this.$store.dispatch("setSending", false);
            this.clearForm();
            this.close();
          })
          .catch((err) => {
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", err);
          });
      }
    },
    async logContactActivity() {
      for (let key in this.initialValues) {
        console.log(key);
        if (this.initialValues[key] !== this.form[key]) {
          let update_or_set = this.initialValues[key] ? "updated" : "set";
          let messageText =
            this.user.data.displayName +
            " " +
            update_or_set +
            " the " +
            key +
            " to " +
            '"' +
            this.form[key] +
            '"';
          //resultArray.push(messageText);
          await this.logActivity(
            messageText,
            this.form.accountId,
            this.contact.id,
            {
              update_or_set,
              key,
              oldValue: this.initialValues[key],
              newValue: this.form[key],
              updatedBy: {
                name: this.user.data.displayName,
                id: this.user.data.uid,
              },
            }
          );
          //await this.logActivity(messageText);
        }
      }
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveUser();
      }
    },
  },
};
</script>
