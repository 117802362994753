<template>
  <md-button class="md-icon-button" @click="action()" title="Move to Archive">
    <md-icon class="text-gray">archive</md-icon>
  </md-button>
</template>
<script>
import StatusUpdateMixin from "@/mixins/StatusUpdateMixin";

export default {
  name: "ActionArchive",
  props: ["item", "collection"],
  mixins: [StatusUpdateMixin],
  data: () => ({
    status: "A"
  })
};
</script>