<template>
  <md-menu-item @click="action()">
    {{text}}
    <md-icon class="text-gray">{{icon}}</md-icon>
  </md-menu-item>
</template>
<script>
import StatusUpdateMixin from "@/mixins/StatusUpdateMixin";

export default {
  name: "ActionArchive",
  props: ["item", "collection", "status", "text", "icon"],
  mixins: [StatusUpdateMixin]
};
</script>