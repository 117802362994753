<template>
  <div class="lead-detail px-md-3">
    <md-toolbar>
      <!-- <router-link :to="{ name: 'Leads' }">
        <md-icon>arrow_back</md-icon> Back
      </router-link>-->
      <md-button class="md-primary text-decoration-none" @click="backLink()">
        <md-icon class="mr-2">arrow_back</md-icon>Back
      </md-button>

    </md-toolbar>
    <div v-if="lead" class="md-layout md-gutter m-0 py-3 md-elevation-4">
      <div class="md-layout-item md-small-size-100 md-sicze-70 lead-detail-body">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-60">
            <Avatar
              :name="lead.contactInfo.displayName"
              :photoUrl="lead.contactInfo.photoUrl"
              class="float-left img-size"
            />
            <div class="float-left mx-2">
              <div class="main-info">
                <span class="md-title contact-name mr-2">
                  {{
                  lead.contactInfo.displayName
                  }}
                </span>
                <span
                    v-for="(tag, index) in lead.tagsShow"
                    :key="index"
                    class="custom-badge mr-2"
                    :class="'badge-color-' + index"
                >
                  {{ tag.name
                  }}
                  <a
                      v-if="index > 0"
                      href="#"
                      class="btn-close"
                      @click.prevent="delTag(index - 1)"
                  >&times;</a>
                </span>
                <span class="lead-days">Lead {{ lead.reviewText }}</span>
              </div>
              <div class="contact-info my-2">
                <span v-on:click="logAction('Phone')">
                  <md-icon class="md-primary">phone_iphone</md-icon>
                  <a :href="'tel:'+lead.contactInfo.phone">{{ lead.contactInfo.phone }}</a>
                </span>
                <span v-on:click="logAction('Email')">
                  <md-icon class="md-primary ml-2">email</md-icon>
                  <a :href="'mailto:'+lead.contactInfo.email">{{ lead.contactInfo.email }}</a>
                </span>
              </div>
              <md-button
                  class="md-raised md-primary"
                  @click="showDialog = true"
              >
                <md-icon
                  class="text-black" title="Add To Do">event
              </md-icon>
              </md-button>
              <AddTag className="md-raised md-primary" @addTag="addTag" />
              <div>
              <md-menu
                  v-if="lead.leadStatus === 'lead'"
                  md-size="medium"
                  md-align-trigger
                  :mdCloseOnSelect="true"
              >
                <md-button class="md-raised md-primary mt-6" md-menu-trigger>Set followup</md-button>
                <md-menu-content>
                  <md-menu-item @click="snoozeLead(1, '24 hours')">24 hours</md-menu-item>
                  <md-menu-item @click="snoozeLead(7, '1 week')">1 week</md-menu-item>
                  <md-menu-item @click="snoozeLead(14, '2 weeks')">2 weeks</md-menu-item>
                  <md-menu-item @click="snoozeLead(21, '3 weeks')">3 weeks</md-menu-item>
                  <md-menu-item @click="lostLead()">Mark as Lost</md-menu-item>
                  <md-menu-item @click="completedLead()">Mark as Won</md-menu-item>
                </md-menu-content>
              </md-menu>
              <md-button class="md-raised md-primary" @click="showEditDialog = true">Edit</md-button>
              <md-dialog :md-active.sync="showDialog">
                <md-dialog-title>
                  {{ todo ? 'Edit Action-item' : 'Create New To do' }}
                </md-dialog-title>

                <FormActionItem
                    v-if="todo"
                    @close="showDialog = false"
                    :form="todo"
                    form-type="edit"
                    :lead="lead"
                />
                <FormActionItem
                    v-else
                    @close="showDialog = false"
                    form-type="add"
                    :lead="lead"
                />
              </md-dialog>
                <md-button class="md-raised md-primary" @click="showDialog = true">To Do</md-button>
              </div>
              <!--              <div class="status-info float-left py-2">
                              <span
                                v-for="(tag, index) in lead.tagsShow"
                                :key="index"
                                class="custom-badge mr-2"
                                :class="'badge-color-' + index"
                              >
                                {{ tag.name
                                }}
                                <a
                                  v-if="index > 0"
                                  href="#"
                                  class="btn-close"
                                  @click.prevent="delTag(index - 1)"
                                >&times;</a>
                              </span>
                            </div>-->
              <div class="w-100">
                
                
                <ContacEditDialog
                  :showEditDialog="showEditDialog"
                  :contact="lead.contact"
                  @close="showEditDialog = false"
                />
              </div>
            </div>
          </div>


          <div class="md-layout-item md-small-size-100  px-1">
            <md-card class="primary-card">
              <md-card-header>
                <div class="md-title">Linked to <br/>Mindbody</div>
                <md-card-actions v-if="lead.integrationId">
                  <md-button
                      class="md-raised md-primary mt-n-50"
                      @click="refreshIntegrationSync"
                  > <md-icon
                      class="text-black" title="Sync Now">refresh
                  </md-icon></md-button>

                </md-card-actions>
                <h6 class="f-14"> Last Sync: {{this.lastSynced.syncedAt}} </h6>
                <h6 class="f-14"> Site ID: {{this.lastSynced.siteId}} </h6>
                <h6 class="f-14"> Member ID: {{this.leadObj.integrationId}} </h6>
              </md-card-header>
              <md-card-content class="pt-3 fixed">
                <div
                  v-for="contract in lead.contracts"
                  :key="contract.contractId"
                  class="custom-badge mb-2"
                  :class="contract.status === 'inactive' ? 'status-email' : 'status-member'"
                >{{ getShortDate(contract.startDate.seconds) }} - {{ contract.contractName }}</div>
              </md-card-content>
            </md-card>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-55 mt-n-90">
            <md-card class="primary-card w-100">
              <md-card-header>
                <div class="md-title">Notes</div>
              </md-card-header>
              <md-card-content class="pt-3">
                <md-field>
                  <md-textarea v-model="notes"></md-textarea>
                </md-field>
              </md-card-content>
              <md-card-actions>
                <md-button class="md-raised md-primary" @click="saveNote()">Save</md-button>
              </md-card-actions>
            </md-card>
          </div>
        </div>
        <br/>
        <div class="md-layout-item md-small-size-100  px-1">
          <md-card class="primary-card">
            <md-card-header>
              <div class="md-title">Session</div>
            </md-card-header>
            <md-card-content class="pt-3 fixed session-container">
              <MindbodyVisits />
              <!-- <div
                v-for="visit in lead.visits"
                :key="visit.id"
                class="custom-badge mb-2"
                :class="visit.signedIn ? 'status-email' : 'status-member'"
              >{{ getShortDate(visit.startDate.seconds) }} - {{ visit.name }}</div> -->
            </md-card-content>
            <!-- <md-card-actions>
              <md-button class="md-raised md-primary w-100">Book</md-button>
            </md-card-actions>-->
          </md-card>
        </div>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-40">
        <md-card class="primary-card h-100">
          <md-card-header>
            <div class="md-title">Activity Log</div>
          </md-card-header>
          <md-button class="md-raised md-primary md-dense" :disabled="sending" @click="logCall()">Log Call</md-button>

          <md-card-content class="px-0">
            <ul
              id="messages"
              class="messages message-list custom-scrollbar px-3"
              ref="chatContainer"
            >
              <Message
                v-for="(message, index) in messages"
                :key="message.id"
                :message="message"
                :prevDate="prevDate(index)"
              />
            </ul>
          </md-card-content>
<!--          <div class="w-100 px-1 border-top" style="right:0">
            <md-button class="md-raised md-primary md-dense" :disabled="sending" @click="logCall()">Log Call</md-button>
            &lt;!&ndash; <NewMessage :thread="selectedThread" /> &ndash;&gt;
            &lt;!&ndash; <form @submit.prevent="addMessage">
              <div class="message-action">
                <div class="sender-info px-1">
                  <md-field class="mr-auto channel-container" md-dense>
                    <label for="channel">Channel</label>
                    <md-select v-model="currentChannel" name="channel" id="channel">
                      <md-option
                        v-for="(channel, index) in channelList"
                        :value="channel"
                        :key="index"
                      >{{ channel }}</md-option>
                    </md-select>
                  </md-field>
                  <div class="sender-details text-right mt-1">
                    <span class="text-gray mx-1">
                      <strong>From:</strong> US Based customer channel
                    </span>
                  </div>
                </div>
                <md-field class="mb-2">
                  <md-textarea v-model="newMessage"></md-textarea>
                </md-field>
                <p v-if="feedback" class="text-danger">{{ feedback }}</p>
                <div class="mb-3">
                  <md-button class="md-icon-button">
                    <md-icon>sentiment_satisfied_alt</md-icon>
                  </md-button>
                  <md-button class="md-icon-button">
                    <md-icon>edit</md-icon>
                  </md-button>
                  <md-button class="md-icon-button">
                    <md-icon>attach_file</md-icon>
                  </md-button>
                  <md-button class="md-icon-button">
                    <md-icon>add_circle_outline</md-icon>
                  </md-button>
                  <md-button class="md-raised md-primary float-right" @click="sendMessage">
                    <img src="@/assets/img/icon-send.svg" />
                    Send
                  </md-button>
                </div>
              </div>
            </form>&ndash;&gt;
          </div>-->
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import { db } from "@/firebase/init";
import LeadModel from "@/models/LeadModel";
import Avatar from "@/components/blocks/Avatar";
import axios from "axios";
import AddTag from "@/components/blocks/dialogs/AddTag";
import ContacEditDialog from "@/components/blocks/dialogs/ContactEdit";
import moment from "moment";
import { mapGetters } from "vuex";
import firebase from "firebase";

import Message from "@/components/blocks/Message";
import MessageModel from "@/models/MessageModel";
import NewMessage from "@/components/blocks/forms/NewMessage";
import MindbodyVisits from "@/components/blocks/MindbodyVisits";
import LogAcivityMixin from "@/mixins/LogActivityMixin";
import LeadActionsMixin from "@/mixins/LeadActionsMixin";
import CreateActionItem from "@/components/blocks/dialogs/CreateActionItem";
import FormActionItem from "@/components/blocks/forms/FormActionItem";

const leads = db.collection("contacts");
const messagesRef = db
  .collection("messages")
  .orderBy("messageTime", "desc")
  .limit(10);

export default {
  name: "LeadDetail",
  props: ["id"],
  components: {
    Avatar,
    AddTag,
    ContacEditDialog,
    Message,
    MindbodyVisits,
    NewMessage,
    CreateActionItem,
    FormActionItem
  },
  mixins: [LeadActionsMixin, LogAcivityMixin],
  data() {
    return {
      lead: {
        contactInfo: {
          displayName: "",
        },
      },
      thread: {},
      leadObj: {},
      messages: [],
      newMessage: "",
      notes: "",
      scrollable: true,
      totalChatHeight: 0,
      currentChannel: "Facebook",
      channelList: ["Facebook", "TextMagic", "Nylas", "Twilio"],
      feedback: "",
      backRouteName: "Leads",
      showEditDialog: false,
      showDialog: false,
      lastSynced: {}
    };
  },
  watch: {
    id: {
      // call it upon creation too
      immediate: true,
      handler(id) {
        console.log(this.$route.params.id)
        let leadObj = leads.doc(this.$route.params.id);
        console.log(leads.doc())
        this.$bind("leadObj", leadObj);
      },
    },
    leadObj: {
      handler(newValue, oldValue) {
        console.log(this.accounts)
        this.lead = LeadModel(newValue, this.accounts);
        this.notes = this.lead.notes;
        console.log(this.lead)
        if (newValue.accountId !== oldValue.accountId) {
          //console.log("in if block",newValue.accountId,oldValue.accountId)
          this.fetchMessages();
        }
        const emailToFilter = this.lead.contactInfo.email;
        console.log(emailToFilter)

        let getSyncData = db
            .collection("integrationSyncLog")
            .where("clientDump.Email", "==", emailToFilter)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                this.lastSynced = doc.data()
                const date = this.lastSynced.syncedAt.toDate();
                this.lastSynced.syncedAt = this.getConvertedate(date)
              });
            });
      },
    },
  },
  mounted() {
    //this.fetchMessages();
    this.scroll();
    this.scrollToEnd();
    const collectionRef = db.collection("integrationSyncLog");

    /*let contacts = db
        .collection("integrationSyncLog")
        .where("firstName", "==", contact.firstName)
        .get()
        .then((querySnapshot) => {
          this.loaded = true;
          querySnapshot.forEach((doc) => {
          });
        });*/
   // console.log("id in details page",this.$route.params.id)
  },
  computed: {
    ...mapGetters({
      sending: "sending",
      user: "user",
      userToken: "userToken",
      accounts: "accounts",
    }),
    // selectedThread() {
    //   const count = this.messages.length;
    //   if (!count) return {};
    //   let message = this.messages[count - 1];
    //   return this.getThread(message.threadId);
    // }
  },
  methods: {
    refreshIntegrationSync() {
      this.$store.dispatch("setSending", true);
      this.mindbodyData = {};
      let lead = this.lead;
      let url =
        `integrations/mindbody/sync?accountId=` +
        lead.accountId +
        `&action=processrecords&token=${this.userToken}`;
      axios({
        url: url,
        method: "POST",
        data: {
          query: {
            limit: 1,
            ClientIds: [lead.integrationId],
          },
        },
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.mindbodyData = resp.data;
          }
          this.$store.dispatch("setSending", false);
        })
        .catch((err) => {
          this.$store.dispatch("setSending", false);
          this.snackbar = true;
          this.snackbarMessage = err;
        });
    },
    fetchMessages() {
      
      this.messages = [];
      try {
        messagesRef
          .where("linkedContact", "==", this.id)
          .where("accountId", "==", this.lead.accountId)
          .onSnapshot((snapshot) => {
            this.$store.dispatch("setSending", false);
            let routeSelect = false;
            snapshot.docChanges().forEach((change) => {
              let doc = change.doc;
             // console.log("doc.linkedContact----",doc.data().linkedContact  )
              let message = MessageModel(doc);
              if (change.type == "modified") {
                this.messages = this.messages.filter(
                  (message) => message.id !== doc.id
                ); //remove the thread
                this.messages.unshift(message); //add thread on top
              } else if (change.type == "added") {
                this.messages.unshift(message);
              } else if (change.type == "removed") {
                this.messages = this.messages.filter(
                  (message) => message.id !== doc.id
                );
              }
              this.messages = this.messages.sort((a, b) => a.secs - b.secs);
              this.scrollToEnd();
            });
          });
      } catch (err) {
        this.$store.dispatch("setSending", false);
        this.$store.dispatch("showSnackbar", err);
      }
    },
    getThread(threadId) {
      db.collection("thread")
        .doc(threadId)
        .get()
        .then((doc) => {
          return { id: doc.id, ...doc.data() };
        });
    },
    getMessageArray(doc) {
      return {
        messageText: doc.messageText,
        messageTime: moment(doc.messageTime.toDate()).format("lll"),
        direction: doc.direction,
      };
    },
    addTag(tagName) {
      this.lead.tags.push({
        name: tagName,
        score: 0,
      });
      this.$store.dispatch("setSending", true);
      leads
        .doc(this.id)
        .update({
          tags: this.lead.tags,
        })
        .then(() => {
          this.$store.dispatch("setSending", false);
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("setSending", false);
        });
    },
    delTag(index) {
      this.lead.tags.splice(index, 1);
      this.$store.dispatch("setSending", true);
      leads
        .doc(this.id)
        .update({
          tags: this.lead.tags,
        })
        .then(() => {
          this.$store.dispatch("setSending", false);
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("setSending", false);
        });
    },
    backLink() {
      this.$router.go(-1);
    },
    sendMessage() {
      if (this.newMessage) {
        this.messages.push({
          messageText: this.newMessage,
          messageTime: moment().format("hh:mm A"),
          direction: "outgoing",
        });
        this.newMessage = "";
        this.scrollToEnd();
      }
    },
    scroll() {
      let scrollableElement = document.querySelector(".message-list");
      scrollableElement.addEventListener("scroll", (event) => {});
    },
    scrollToEnd() {
      this.$nextTick(() => {
        var container = this.$el.querySelector(".message-list");
        container.scrollTop = container.scrollHeight;
      });
    },
    scrollTo() {
      this.$nextTick(() => {
        let currentHeight = this.$refs.chatContainer.scrollHeight;
        let difference = currentHeight - this.totalChatHeight;
        var container = this.$el.querySelector(".message-list");
        container.scrollTop = difference;
      });
    },
    prevDate(index) {
      if (index === 0) {
        return "";
      } else {
        let prevMessage = this.messages[index - 1];
        return moment(prevMessage.timestamp).format("L");
      }
    },
    getShortDate(ms) {
      return moment(ms * 1000).format("MMMM Do YYYY");

      return moment(ms * 1000).format("L");
    },
    getConvertedate(date)
    {
// Format Date to MMMM Do YYYY
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return  date.toLocaleDateString(undefined, options);
    },
    saveNote() {
      if(!this.notes && !this.leadObj.notes){
        this.$store.dispatch("showSnackbar", "Note field is empty");
        return;
      }
      this.$store.dispatch("setSending", true);
      leads
        .doc(this.id)
        .update({
          notes: this.notes,
        })
        .then(() => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", "Note updated successfully");
        })
        .catch((err) => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", err);
        });
      this.logAction('Notes')
    },
    logCall(){
      this.$store.dispatch("setSending", true);
      let lead = this.leadObj;
      let user = this.user;
      let messageText = user.data.displayName + " called the contact";
      leads
        .doc(this.id)
        .update({
          lastContacted: firebase.firestore.Timestamp.now(),
          lastContactedMessage: "Outgoing Call"
        })
        .then(() => {
          this.logActivity(
                        messageText,
                        lead.accountId,
                        lead.id,
                        {
                            call: true,
                            updatedBy: {
                                name: user.data.displayName,
                                id: user.data.uid,
                            },
                        }
                    );
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", "Call logged successfully");
        })
        .catch((err) => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", err);
        });
      
    },
    logAction(action){
      this.$store.dispatch("setSending", true);

      //todo = this.todoObj;

      let user = this.user;
      let lead = this.leadObj;

      let messageText = user.data.displayName + " clicked on the "+ action;
      if(action == "Phone")
      {
         messageText = user.data.displayName + " called the contact";
      }
      if(action == "Notes")
      {
        messageText = user.data.displayName + " Updated the Notes";
      }
      leads
          .doc(this.id)
          .update({
            lastContacted: firebase.firestore.Timestamp.now(),
            lastContactedMessage: action
          })
          .then(() => {
            this.logActivity(
                messageText,
                lead.accountId,
                lead.id,
                {
                  call: true,
                  updatedBy: {
                    name: user.data.displayName,
                    id: user.data.uid,
                  },
                }
            );
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", action+" logged successfully");
          })
          .catch((err) => {
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", err);
          });


    }
  },

};
</script>
<style lang="scss" scoped>
.lead-detail-body {
  position: relative;
  height: calc(100vh - 160px);
  .main-info {
    .contact-name {
      font-size: 16px;
      font-weight: bold;
    }
    .lead-days {
      font-size: 13px;
    }
  }
  .contact-info {
    font-size: 13px;
    .md-icon {
      font-size: 18px !important;
    }
  }
}
.bottom-action {
  position: absolute;
  bottom: 0;
  right: 15px;
}
.message-list {
  height: calc(100vh - 350px);
  overflow: auto;
  padding: 0px;
  .one-message {
    list-style: none;
    .message-body {
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.08);
      font-size: 14px;
      max-width: 80%;
      padding: 0.5rem;
      border-radius: 0px 10px 10px 0px;
      display: inline-block;
    }
    &:first-child .message-body {
      border-radius: 10px 10px 10px 0px;
    }
    &:last-child .message-body {
      border-radius: 0px 10px 10px 10px;
    }
    &.dir-outgoing {
      text-align: right;
      .message-body {
        border-radius: 10px 0px 0px 10px;
        background-color: #eef2ff;
      }
      &:first-child .message-body {
        border-radius: 10px 10px 0px 10px;
      }
      &:last-child .message-body {
        border-radius: 10px 0px 10px 10px;
      }
    }
  }
}
.status-info {
  .custom-badge {
    position: relative;
    cursor: pointer;
    .btn-close {
      background-color: lightgrey;
      color: black;
      border-radius: 10px;
      text-align: center;
      line-height: 20px;
      padding: 0px 7px;
      margin-left: 0px;
      margin-top: -5px;
      position: absolute;
      display: none;
      z-index: 10;
    }
    &:hover {
      .btn-close {
        display: initial;
      }
    }
  }
}
.session-container {
  max-height: 200px;
  overflow-y: auto;
}
.mt-n-90{
  margin-top: -90px !important;
}
.mt-n-50{
  margin-top: -50px !important;
}
.f-14{
  font-size: 14px !important;
}
.mt-6{
  margin-top: 6px !important;
}
/*.img-size
{
  height: 80px !important;
  width: 80px !important;
}*/
</style>
<style>
  .messages .message-body {
    min-width: 85%;
  }
</style>
