import moment from "moment";

export default function MessageModel(doc) {
  let message = doc.data();

  let text = "";
  if (
    message.hasOwnProperty("messageBodyVisible") &&
    message.messageBodyVisible
  ) {
    text = message.messageBodyVisible;
  } else if (
    message.hasOwnProperty("messageTextMobile") &&
    message.messageTextMobile
  ) {
    text = message.messageTextMobile;
  } else if (message.hasOwnProperty("messageText") && message.messageText) {
    text = message.messageText;
  }

  // let name = "";
  // if (message.hasOwnProperty("displayName") && message.displayName) {
  //   name = message.displayName;
  // } else if (message.hasOwnProperty("tempContact") && message.tempContact) {
  //   name = getName(message.tempContact);
  // } else {
  //   name = this.thread.name;
  // }

  return {
    id: doc.id,
    //name: name,
    content: text,
    html: message.messageBody,
    timestamp: moment(message.messageTime.toDate()).format("lll"),
    bgClass: getBgClass(message),
    direction: message.direction,
    secs: message.messageTime.seconds,
    //tags: [this.accountName(message.accountId)],
    isNote: message.messageType === "note",
    threadId: message.threadId,
    senderName: message.senderName,
    channelType: message.channelType,
    messageType: message.messageType,
    files: message.files || []
  };
}

function getName(nameObj) {
  if (typeof nameObj === "undefined") {
    return "";
  }
  return nameObj.firstName + " " + nameObj.lastName;
}

function getBgClass(message) {
  const noteClass = message.messageType === "note" ? "message-note" : "";
  if (message.direction === "outgoing" || message.direction === "o") {
    return "chat-bubble bg-light-info " + noteClass;
  }
  return "chat-bubble bg-light-inverse " + noteClass;
}

function accountName(accountId) {
  let accounts = this.accounts.filter(account => account.id === accountId);
  if (accounts.length) {
    return accounts[0].name;
  }
}
