<template>
  <div class="editor">
    <editor-content class="editor__content" :editor="editor" />
    <editor-menu-bar v-slot="{ commands, isActive }" :editor="editor">
      <div class="menubar float-left mb-0 mt-2">
        <span v-for="actionName in activeButtons" :key="actionName">
          <button
            v-if="actionName === 'bold'"
            class="menubar__button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
            type="button"
          >
            <icon name="bold" />
          </button>
          <button
            v-if="actionName === 'italic'"
            class="menubar__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
            type="button"
          >
            <icon name="italic" />
          </button>

          <button
            v-if="actionName === 'strike'"
            class="menubar__button"
            :class="{ 'is-active': isActive.strike() }"
            @click="commands.strike"
            type="button"
          >
            <icon name="strike" />
          </button>

          <button
            v-if="actionName === 'underline'"
            class="menubar__button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
            type="button"
          >
            <icon name="underline" />
          </button>

          <button
            v-if="actionName === 'code'"
            class="menubar__button"
            :class="{ 'is-active': isActive.code() }"
            @click="commands.code"
            type="button"
          >
            <icon name="code" />
          </button>

          <button
            v-if="actionName === 'paragraph'"
            class="menubar__button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
            type="button"
          >
            <icon name="paragraph" />
          </button>

          <button
            v-if="actionName === 'h1'"
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
            type="button"
          >H1</button>

          <button
            v-if="actionName === 'h2'"
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
            type="button"
          >H2</button>

          <button
            v-if="actionName === 'h3'"
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
            type="button"
          >H3</button>

          <button
            v-if="actionName === 'bullet_list'"
            class="menubar__button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
            type="button"
          >
            <icon name="ul" />
          </button>

          <button
            v-if="actionName === 'ordered_list'"
            class="menubar__button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
            type="button"
          >
            <icon name="ol" />
          </button>

          <button
            v-if="actionName === 'blockquote'"
            class="menubar__button"
            :class="{ 'is-active': isActive.blockquote() }"
            @click="commands.blockquote"
            type="button"
          >
            <icon name="quote" />
          </button>

          <button
            v-if="actionName === 'code_block'"
            class="menubar__button"
            :class="{ 'is-active': isActive.code_block() }"
            @click="commands.code_block"
            type="button"
          >
            <icon name="code" />
          </button>

          <button
            v-if="actionName === 'horizontal_rule'"
            class="menubar__button"
            @click="commands.horizontal_rule"
            type="button"
          >
            <icon name="hr" />
          </button>

          <button
            v-if="actionName === 'undo'"
            class="menubar__button"
            @click="commands.undo"
            type="button"
          >
            <icon name="undo" />
          </button>

          <button
            v-if="actionName === 'redo'"
            class="menubar__button"
            @click="commands.redo"
            type="button"
          >
            <icon name="redo" />
          </button>
        </span>
      </div>
    </editor-menu-bar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Icon from "./Icon";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from "tiptap-extensions";

export default {
  name: "Editor",
  components: {
    EditorContent,
    EditorMenuBar,
    Icon
  },
  props: {
    initialContent: {
      type: String,
      required: true,
      default: "<em>editable text</em>"
    },
    activeButtons: {
      type: Array,
      validator: function(list) {
        for (let el of list) {
          // The value must match one of these strings
          if (
            [
              "bold",
              "italic",
              "strike",
              "underline",
              "code",
              "paragraph",
              "h1",
              "h2",
              "h3",
              "bullet_list",
              "ordered_list",
              "blockquote",
              "code_block",
              "horizontal_rule",
              "undo",
              "redo"
            ].indexOf(el) === -1
          ) {
            return -1;
          }
        }
        return 1;
      },
      default: () => ["bold", "italic"]
    }
  },
  computed: {
    ...mapGetters({
      editorContent: "editorContent"
    })
  },
  watch: {
    editorContent: {
      immediate: true,
      handler(newValue, oldValue) {
        this.editor.setContent(newValue);
        this.editor.focus("start");
      }
    }
  },
  data() {
    return {
      html: "",
      json: "",
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History()
        ],
        content: this.initialContent
      })
    };
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  created() {
    this.html = this.editor.getHTML();
    this.json = this.editor.getJSON();

    this.editor.on("update", () => {
      this.html = this.editor.getHTML();
      this.json = this.editor.getJSON();
      this.$emit("update", this.html);
    });

    this.$parent.$on("clearEditor", this.clear);
  },
  methods: {
    clear() {
      this.editor.clearContent();
    }
  }
};
</script>

<style lang="sass">
@import '@/assets/tiptap/sass/main.scss'
</style>
