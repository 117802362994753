<template>
    <div>
      <md-dialog :md-active.sync="showDialog">
        <md-dialog-title>
          {{ todo ? 'Edit Action-item' : 'Create New Action Item' }}
        </md-dialog-title>

        <FormActionItemFront
          v-if="todo"
          @close="showDialog = false"
          :form="todo"
          form-type="edit"
        />
        <FormActionItemFront
          v-else
          @close="showDialog = false"
          form-type="add"
        />

      </md-dialog>

      <md-button
        v-if="todo"
        class="md-primary"
        @click="showDialog = true"
      >
        <md-icon>edit</md-icon>
        Edit
      </md-button>
      <md-button
        v-else
        class="md-primary md-raised"
        @click="showDialog = true"
      >
        Add Action Item
      </md-button>
    </div>
  </template>

  <script>
  import FormActionItemFront from '@/components/blocks/forms/FormActionItemFront'

  export default {
    name: 'CreateToDo',
    props: {
      todo: {
        type: Object,
        default: null
      }, lead: {
        type: Object,
        default: null
      }
    },
    components: {
      FormActionItemFront
    },
    data: () => ({
      showDialog: false
    })
  }
  </script>
