import { db } from '@/firebase/init'

export default {
  methods: {
    refreshAccounts(user) {
      if (!user) {
        return;
      }
      const refUser = db.collection('users');
      const accounts = [];

      refUser.doc(user.uid).collection("access").get()
        .then(function (querySnapshot) {
          let count = 0
          querySnapshot.forEach(async (doc) => {
            let accountData = doc.data()
            let account = {
              id: doc.id,
              name: accountData.accountName,
              number: count
            }
            accounts.push(account)
            count++;
            if (count > 4) count = 0
          });
        }).then(() => {
          this.$store.dispatch("fetchAccounts", accounts)
        })
    }
  }
}