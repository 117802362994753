<template>
  <div @click="linkToDetail()" class="pointer">
    <Avatar :name="contact.displayName" :photoUrl="contact.photoUrl" class="float-left" />
    <span class="md-title contact-name" style="line-height:50px">{{contact.displayName}}</span>
  </div>
</template>
<script>
import { db } from "@/firebase/init";

import Avatar from "@/components/blocks/Avatar";

export default {
  name: "LinkedContact",
  props: ["contact"],
  components: {
    Avatar
  },
  methods: {
    linkToDetail() {
      this.$router.push({
        name: "Lead Detail",
        params: { id: this.contact.id }
      });
    }
  }
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>