<template>
  <div>
    <form novalidate @submit.prevent="validateInfo">
      <md-card class="login">
        <md-card-header>
          <span class="md-title text-primary">Signup</span>
        </md-card-header>
        <md-card-content>
          <md-field :class="getValidationClass('name')">
            <label>Name</label>
            <md-input v-model="form.name" :disabled="sending"></md-input>
            <span class="md-error" v-if="!$v.form.name.required">The name is required</span>
          </md-field>
          <md-field :class="getValidationClass('email')">
            <label>Email</label>
            <md-input v-model="form.email" type="email" :disabled="sending"></md-input>
            <span class="md-error" v-if="!$v.form.email.required">The email is required</span>
            <span class="md-error" v-else-if="!$v.form.email.email">The email is invalid</span>
          </md-field>
          <md-field :class="getValidationClass('password')">
            <label>Password</label>
            <md-input v-model="form.password" type="password" :disabled="sending"></md-input>
            <span class="md-error" v-if="!$v.form.password.required">The password is required</span>
            <span
              class="md-error"
              v-else-if="!$v.form.password.minlength"
            >The password must be at least {{ $v.form.password.$params.minLength.min }} letters.</span>
          </md-field>
          <md-field :class="getValidationClass('confirmPassword')">
            <label>Confirm Password</label>
            <md-input v-model="form.confirmPassword" type="password" :disabled="sending"></md-input>
            <span
              class="md-error"
              v-if="!$v.form.confirmPassword.sameAsPassword"
            >The password must be identical.</span>
          </md-field>
          <p v-if="feedback" class="text-danger">{{ feedback }}</p>
        </md-card-content>

        <md-card-actions class="d-block">
          <md-button type="submit" class="md-raised md-primary w-100" :disabled="sending">Signup</md-button>

          <p class="mt-5">
            Already have an account?
            <router-link :to="{name: 'Login'}" :disabled="sending">Sign in</router-link>
          </p>
        </md-card-actions>
      </md-card>
    </form>
  </div>
</template>

<script>
import { db } from "@/firebase/init";
import firebase from "firebase";
import store from "@/store/index";
import { mapGetters } from "vuex";

import { validationMixin } from "vuelidate";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import accountsMixin from "@/mixins/AccountsMixin";

export default {
  name: "Signup",
  mixins: [validationMixin, accountsMixin],
  data: () => ({
    form: {
      name: null,
      email: null,
      password: null,
      confirmPassword: null
    },
    feedback: null
  }),
  computed: {
    ...mapGetters({
      sending: "sending"
    })
  },
  mounted() {
    store.dispatch("resetState");
  },
  validations: {
    form: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      },
      confirmPassword: {
        sameAsPassword: sameAs("password")
      }
    }
  },
  methods: {
    signup() {
      this.$store.dispatch("setSending", true);
      this.feedback == null;

      firebase
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then(response => {
          store.dispatch("storeSelectedAccounts", []);
          response.user
            .updateProfile({
              displayName: this.form.name
            })
            .then(() => {
              store.dispatch("fetchUser", {
                user: firebase.auth().currentUser
              });
              store.dispatch(
                "showSnackbar",
                "Account created. Checking for existing access"
              );
              let userGrantAccess = firebase
                .functions()
                .httpsCallable("userGrantAccess");
              const userId = firebase.auth().currentUser.uid;
              userGrantAccess({
                email: this.form.email,
                userId: userId
              })
                .then(result => {
                  this.$router.push({ name: "Inbox" });
                  this.$store.dispatch("setSending", false);
                  this.refreshAccounts(firebase.auth().currentUser);
                })
                .catch(err => {
                  this.feedback = err.message;
                  this.$store.dispatch("setSending", false);
                });
            });
        })
        .then(() => {})
        .catch(err => {
          this.feedback = err.message;
          this.$store.dispatch("setSending", false);
        });
    },
    updateAccountAccess(user) {
      db.collection("invites")
        .where("invitedEmail", "==", this.form.email)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const inviteData = doc.data();
            db.collection("users")
              .doc(user.id + "/access/" + inviteData.accountId)
              .set({
                accountId: inviteData.accountId,
                accountName: inviteData.accountName,
                role: inviteData.role
              });
          });
        });
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    validateInfo() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.signup();
      }
    }
  }
};
</script>
