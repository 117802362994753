<template>
  <div class="settings-channels">
    <div class="md-layout md-gutter m-3">
      <div class="md-layout-item">
        <div class="md-layout my-3" v-if="mindbodyData.status === 'error'">
          <div class="mindbody-connect-container">
            <h5 class="md-title">Mindbody</h5>
            <p class="mb-0">
              To connect Studio Backoffice to your Mindbody Account, you must be the
              <strong>Owner</strong> of the Mindbody Site.
              <br />Please enter your Site ID to recieve the activation code required for integration.
            </p>
            <md-field class="txt-site-id float-left mb-0">
              <label>Site ID</label>
              <md-input placeholder="Ex: 123456" v-model="siteID"></md-input>
            </md-field>
            <md-button class="md-raised md-primary" @click="addSiteID">Submit</md-button>
          </div>
        </div>
        <div class="md-layout my-3" v-if="mindbodyData.status === 'pendingauth'">
          <div class="mindbody-test-container">
            <h5 class="md-title">Mindbody: Connected to Site {{mindbodyData.mindbodySiteId}}</h5>
            <p class="mb-0">
              To connect Studio Backoffice to your Mindbody Account, you must be the
              <strong>Owner</strong> of the Mindbody Site.
              <br />Please enter your Site ID to recieve the activation code required for integration.
            </p>
            <h6 class="md-subtitle">Activation Code:</h6>
            <p>{{mindbodyData.mindbodyActivationCode }}</p>
            <h6 class="md-subtitle">
              Activation URL: (
              <a
                :href="mindbodyData.mindbodyActivationLink"
                target="_blank"
              >Open this link</a>)
            </h6>
            <p>{{mindbodyData.mindbodyActivationLink}}</p>
            <md-button class="md-raised" @click="getIntegrationsInfo">Test Connection</md-button>
          </div>
        </div>
        <div class="md-layout my-3" v-if="mindbodyData.status === 'active'">
          <div class="mindbody-result-container">
            <div class="md-layout-item">
              <h5 class="md-title">Mindbody</h5>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-medium-size-50 md-large-size-40">
                <p>
                  You are successfully connected to Site:
                  <span
                    class="font-weight-bold"
                  >{{mindbodyData.mindbodySiteId}}</span>
                </p>

                <p>
                  As your clients are updated they will be imported as contacts.
                  <br />Once complete they will continue to stay in Sync.
                </p>
                <md-button class="md-raised btn-light">Start Sync</md-button>
                <md-button class="md-raised btn-info" @click="forceSync">Force Sync Last 30 Days</md-button>
                <md-button class="md-raised btn-info" @click="forceSyncAll">Force Sync All</md-button>
              </div>
              <div class="md-layout-item md-medium-size-50 md-large-size-40">
                <md-button
                  class="md-raised md-primary border-primary btn-info"
                  @click="disconnect"
                >Disconnect</md-button>
                <h6 class="md-subtitle">Last Webhook Recieved:</h6>
                <p>{{dateTimeFormat(mindbodyData.mindbodyLastWebhook)}}</p>
                <h6 class="md-subtitle">Last Client Sync</h6>
                <p>{{dateTimeFormat(mindbodyData.mindbodyLastSync)}}</p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <PlaybookIntegration :account="selectedAccount" />
        <hr />
        <div class="md-layout mt-5" style="display:none;">
          <div class="facebook-container">
            <h5 class="md-title">Facebook</h5>
            <p>To view and reply to Facebook page messages you must login with facebook.</p>
            <md-button>Login with Facebook</md-button>
          </div>
        </div>
      </div>
    </div>
    <md-snackbar :md-active.sync="snackbar">{{snackbarMessage}}</md-snackbar>
  </div>
</template>

<script>
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
const firebase = require("firebase");

import PlaybookIntegration from "@/components/blocks/integrations/Playbook";

let refAccounts = db.collection("accounts");
export default {
  name: "SettingsIntegrations",
  props: ["selectedAccount"],
  components: {
    PlaybookIntegration,
  },
  data() {
    return {
      siteID: "",
      mindbodyData: {},
      snackbar: false,
      snackbarMessage: "",
    };
  },
  firestore: {},
  computed: {
    ...mapGetters({
      userToken: "userToken",
    }),
  },
  mounted() {
    console.log(this.selectedAccount);
    this.getIntegrationsInfo();
  },
  watch: {
    selectedAccount: function () {
      console.log(this.selectedAccount);
      this.getIntegrationsInfo();
    },
  },
  methods: {
    addSiteID() {
      refAccounts
        .doc(this.selectedAccount.id)
        .update({
          mindbodySiteId: this.siteID,
        })
        .then(() => {
          this.snackbar = true;
          this.snackbarMessage = `Connected to Site ${this.siteID}!`;
          this.getIntegrationsInfo();
        });
    },
    getIntegrationsInfo() {
      this.$store.dispatch("setSending", true);
      this.mindbodyData = {};
      let url = `integrations/mindbody/status?accountId=${this.selectedAccount.id}&token=${this.userToken}`;
      axios({ url: url, method: "GET" })
        .then((resp) => {
          if (resp.status === 200) {
            this.mindbodyData = resp.data;
          }
          this.$store.dispatch("setSending", false);
        })
        .catch((err) => {
          this.$store.dispatch("setSending", false);
          this.snackbar = true;
          this.snackbarMessage = err;
        });
    },
    disconnect() {
      refAccounts
        .doc(this.selectedAccount.id)
        .update({
          mindbodySiteId: firebase.firestore.FieldValue.delete(),
          mindbodyCoreAuthToken: firebase.firestore.FieldValue.delete(),
        })
        .then(() => {
          this.snackbar = true;
          this.snackbarMessage = `Disconnected from Site ${this.mindbodyData.mindbodySiteId}. Please wait 2 minutes before reconnecting.`;
          this.getIntegrationsInfo();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dateTimeFormat(m) {
      if(!m) return;
      return moment.unix(m).format("DD MMM YYYY hh:mm a");
    },
    forceSync() {
      this.$store.dispatch("setSending", true);
      let url = `integrations/mindbody/sync?accountId=${this.selectedAccount.id}&action=synclastmonth&token=${this.userToken}`;
      axios({ url: url, method: "POST" })
        .then((resp) => {
          this.snackbar = true;
          this.snackbarMessage = resp.data;
          this.$store.dispatch("setSending", false);
        })
        .catch((err) => {
          this.$store.dispatch("setSending", false);
          this.snackbar = true;
          this.snackbarMessage = err;
        });
    },
    forceSyncAll() {
      this.$store.dispatch("setSending", true);
      let url = `integrations/mindbody/sync?accountId=${this.selectedAccount.id}&action=syncfull&token=${this.userToken}`;
      axios({ url: url, method: "POST" })
        .then((resp) => {
          this.snackbar = true;
          this.snackbarMessage = resp.data;
          this.$store.dispatch("setSending", false);
        })
        .catch((err) => {
          this.$store.dispatch("setSending", false);
          this.snackbar = true;
          this.snackbarMessage = err;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.md-title {
  font-size: 16px;
  font-weight: 600;
}
.md-subtitle {
  font-size: 13px;
  font-weight: 600;
}
.txt-site-id {
  width: 200px;
}
p {
  font-size: 13px;
}
</style>
