import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null,
      token: null
    },
    userDoc: {},
    accounts: [],
    selectedAccounts: [],
    sending: false,
    snackbar: {
      show: false,
      message: ""
    },
    selectedChannel: {
      action: "",
      channel: null
    },
    tokens: [],
    currentDeviceToken: false,
    editorContent: "",
    facebookPages: [],
    notificationChannels: []
  },

  getters: {
    user(state) {
      return state.user
    },
    userDoc(state) {
      return state.userDoc
    },
    userToken(state) {
      return state.user.token
    },
    userTokens(state) {
      return state.tokens
    },
    currentDeviceToken(state) {
      return state.currentDeviceToken
    },
    accounts(state) {
      return state.accounts
    },
    selectedAccounts(state) {
      return state.selectedAccounts
    },
    sending(state) {
      return state.sending
    },
    snackbar(state) {
      return state.snackbar
    },
    editorContent(state) {
      return state.editorContent
    },
    selectedChannel(state) {
      return state.selectedChannel
    },
    facebookPages(state) {
      return state.facebookPages
    },
    notificationChannels(state) {
      return state.notificationChannels
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
      if(data && typeof data.userDoc !== "undefined"){
        state.userDoc = data.userDoc;
      } else {
        state.userDoc = {};
      }
    },
    SET_USER_DOCUMENT(state, data) {
      state.user.data.userDoc = data;
      state.userDoc = data;
    },
    SET_USER_TOKEN(state, data) {
      state.user.token = data;
    },
    SET_TOKENS(state, data) {
      state.tokens = data;
    },
    SET_CURRENT_DEVICE_TOKEN(state, data) {
      state.currentDeviceToken = data;
    },
    SET_ACCOUNTS(state, data) {
      state.accounts = data;
    },
    SET_SELECTED_ACCOUNTS(state, data) {
      state.selectedAccounts = data;
    },
    SET_SENDING(state, data) {
      state.sending = data;
    },
    SET_SNACKBAR(state, data) {
      state.snackbar.show = true;
      
      state.snackbar.message = data;
    },
    SET_EDITOR_CONTENT(state, data) {
      state.editorContent = data;
    },
    SET_SELECTED_CHANNEL(state, data) {
      state.selectedChannel.action = data.action;
      state.selectedChannel.channel = data.channel;
    },
    SET_FACEBOOK_PAGES(state, data) {
      state.facebookPages = data;
    },
    SET_NOTIFICATION_CHANNELS(state, data) {
      state.notificationChannels = data;
    }
  },
  actions: {
    fetchUser({ commit }, data) {
      let user = data.user;
      let userDoc = data.userDoc;

      commit("SET_LOGGED_IN", user !== null);

      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          uid: user.uid,
          userDoc: userDoc
        });
      } else {
        commit("SET_USER", null);
      }
    },
    updateUserDocument({ commit }, userDoc) {
      commit("SET_USER_DOCUMENT", userDoc)
    },
    storeUserToken({ commit }, token) {
      commit("SET_USER_TOKEN", token);
    },
    storeTokens({ commit }, tokens) {
      commit("SET_TOKENS", tokens);
    },
    storeCurrentDeviceToken({ commit }, token) {
      commit("SET_CURRENT_DEVICE_TOKEN", token);
    },
    fetchAccounts({ commit }, accounts) {
      commit("SET_ACCOUNTS", accounts);
    },
    storeSelectedAccounts({ commit }, selectedAccounts) {
      commit("SET_SELECTED_ACCOUNTS", selectedAccounts);
    },
    resetState({ commit }) {
      window.localStorage.removeItem('selectedAccountsV1.01');
      commit("SET_USER", null);
      commit("SET_ACCOUNTS", []);
      commit("SET_SELECTED_ACCOUNTS", []);
      commit("SET_CURRENT_DEVICE_TOKEN", false);
      commit("SET_TOKENS", []);
      commit("SET_SENDING", false);
      commit("SET_USER_TOKEN", null);
    },
    setSending({ commit }, sending) {
      
      commit("SET_SENDING", sending);
    },
    showSnackbar({ commit }, message) {
      commit("SET_SNACKBAR", message);
    },
    setEditorContent({ commit }, message) {
      commit("SET_EDITOR_CONTENT", message);
    },
    setManagingChannel({ commit }, channel) {
      commit("SET_SELECTED_CHANNEL", {
        channel,
        action: 'manage'
      });
    },
    setFacebookPages({ commit }, pages) {
      commit("SET_FACEBOOK_PAGES", pages);
    },
    setNotificationChannels({ commit }, channels) {
      commit("SET_NOTIFICATION_CHANNELS", channels);
    },
  }
});