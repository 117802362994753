<template>
<div>
  <div
                  v-for="visit in mindbodyVisits"
                  :key="visit.id"
                  class="custom-badge mb-2"
                  :class="visit.signedIn ? 'status-email' : 'status-member'"
                >{{ getShortDate(visit.startDate.seconds) }} - {{ visit.name }}</div>
</div>
</template>

<script>
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "MindbodyVisits",
  props: [],
  components: {
  },
  data() {
    return {
      mindbodyVisits: []
    };
  },
  computed: {
    ...mapGetters({
      userToken: "userToken",
    }),
  },
  methods: {
    getMindbodyVisits(){
      db.collection("mindbody-visits")
      .where("client_id","==",100000055)
      .where("accountId","in",["YEdeR762PlFyuHRyVGH6"])
      .orderBy("startDate", "desc")
      .limit(20)
      .get()
      .then((querySnapshot) => {
          querySnapshot.forEach( (doc)  => {
            console.log(doc.id);
            this.mindbodyVisits.push({ id: doc.id, ...doc.data() });
          });
        });
    },
    getShortDate(ms) {
      //return moment(ms).format("M/D");
      return moment(ms * 1000).format("MMMM Do YYYY");
      //return moment(ms * 1000).format("L");
    },
  },
  mounted(){
    this.getMindbodyVisits()
  }
}
</script>