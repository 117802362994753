import firebase from "firebase";
import firestore from "firebase/firestore";

var config = {
  apiKey: "AIzaSyC6sLlg-hq33NxIwY2xA9GhPvattsZUPYs",
  authDomain: "studio-backoffice-253304.firebaseapp.com",
  databaseURL: "https://studio-backoffice-253304.firebaseio.com",
  projectId: "studio-backoffice-253304",
  storageBucket: "studio-backoffice-253304.appspot.com",
  messagingSenderId: "913361955490",
  appId: "1:913361955490:web:d798af39cb682adb8545fb",
  measurementId: "G-9RNV8E1X8E",
};
const firebaseApp = firebase.initializeApp(config);

const settings = {};
if (process.env.NODE_ENV === "developement") {
  settings.experimentalForceLongPolling = true;
}

const db = firebaseApp.firestore();
db.settings(settings);
db.enablePersistence();

let messaging;
if (firebase.messaging.isSupported()) {
   messaging = firebaseApp.messaging();
   messaging.usePublicVapidKey(
     "BDaeByAf3DX_B8Dl7nBhscqZQY7TwtUeocty0V7LunN5teOQtw3eFaL3vztY5rpnT184ouT27m_fV2J4b_Opf50"
   );
}

export { db, messaging };
