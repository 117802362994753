<template>
  <md-menu md-align-trigger :mdCloseOnSelect="true">
    <md-button md-menu-trigger class="md-icon-button">
      <md-icon class="text-gray">more_vert</md-icon>
    </md-button>

    <md-dialog :md-active.sync="showEditDialog">
      <md-dialog-title>Create Contact</md-dialog-title>
      <ContactForm
        :contact="threadToContact"
        :lockedAccount="true"
        @close="close()"
        @added="added"
      />
    </md-dialog>
    <md-menu-content>
      <md-menu-item @click="showEditDialog = true" v-if="!thread.linkedContact">New Contact</md-menu-item>

      <md-menu-item @click="showLeadDialog = true" v-if="!thread.linkedContact">New Lead</md-menu-item>

      <md-menu-item @click="showLinkDialog = true" v-if="!thread.linkedContact">Link Contact</md-menu-item>

      <md-menu-item
        @click="convertToLead(linkedContactObj.id)"
        v-if="linkedContactObj.id && linkedContactObj.leadStatus != 'lead'"
      >Convert To Lead</md-menu-item>
      <StatusUpdate
        v-if="thread.status !== 'important'"
        :item="thread"
        collection="threads"
        status="important"
        text="Move to Important"
        icon="label_important"
      />
      <StatusUpdate
        v-if="thread.status !== 'I'"
        :item="thread"
        collection="threads"
        status="I"
        text="Move to Inbox"
        icon="move_to_inbox"
      />
      <StatusUpdate
        v-if="thread.status !== 'A'"
        :item="thread"
        collection="threads"
        status="A"
        text="Move to Archive"
        icon="archive"
      />
      <StatusUpdate
        v-if="thread.status !== 'T'"
        :item="thread"
        collection="threads"
        status="T"
        text="Move to Trash"
        icon="delete_outline"
      />
    </md-menu-content>

    <md-dialog :md-active.sync="showLeadDialog">
      <md-dialog-title>Create Lead</md-dialog-title>
      <ContactForm
        :contact="threadToContact"
        :lockedAccount="true"
        :lead="true"
        @close="showLeadDialog = false"
        @added="added"
      />
    </md-dialog>

    <md-dialog :md-active.sync="showLinkDialog">
      <LinkContacts
        :contact="threadToContact"
        :thread="thread"
        @close="showLinkDialog = false"
        @openContact="showLinkDialog = false; showEditDialog = true;"
      />
    </md-dialog>
    <!-- <md-button class="md-primary mr-1">New Lead</md-button> -->
  </md-menu>
</template>
<script>
import { db } from "@/firebase/init";
import firebase from "firebase";
import StatusUpdate from "@/components/blocks/actions/StatusUpdate";
import LinkContactMixin from "@/mixins/LinkContactMixin";

let threadRef = db.collection("threads");

export default {
  name: "ThreadMenu",
  props: ["thread", "linkedContactObj"],
  mixins: [LinkContactMixin],
  components: {
    StatusUpdate,
  },
  data() {
    return {
      showLeadDialog: false,
    };
  },
  watch: {
    thread: function (newValue, oldValue) {
      this.showEditDialog = false;
      this.showLinkDialog = false;
      this.showLeadDialog = false;
    },
  },
  methods: {
    convertToLead(contactId) {
      this.$store.dispatch("setSending", true);
      db.collection("contacts")
        .doc(contactId)
        .update({
          leadStatus: "lead",
          leadCreated: this.lead ? firebase.firestore.Timestamp.now() : null,
          leadReviewDate: this.lead ? firebase.firestore.Timestamp.now() : null,
        })
        .then(() => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", "Contact converted to lead");
        })
        .catch((err) => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", err);
        });
    },
  },
};
</script>