<template>
  <span>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Note</md-dialog-title>

      <FormNote
        @close="showDialog = false"
        :note="thread.note"
        :id="thread.id"
        collection="threads"
        @noteUpdated="noteUpdated"
      />
    </md-dialog>

    <md-button class="md-primary md-raised" @click="showDialog = true">Private Note</md-button>
  </span>
</template>

<script>
import FormNote from "@/components/blocks/forms/Note";

export default {
  name: "CreateNote",
  props: {
    thread: {
      type: Object,
      default: null
    }
  },
  components: {
    FormNote
  },
  data: () => ({
    showDialog: false
  }),
  methods: {
    noteUpdated(note) {
      this.thread.note = note;
    }
  }
};
</script>
