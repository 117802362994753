import { db } from "@/firebase/init";
import firebase from "firebase";
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            user: "user"
        })
    },
    methods: {
        action() {
            db.collection(this.collection)
                .doc(this.item.id)
                .update({
                    status: this.status,
                    statusUpdatedManually: true,
                    statusUpdates: firebase.firestore.FieldValue.arrayUnion({
                        status: this.status,
                        time: firebase.firestore.Timestamp.now(),
                        updatedBy: {
                            id: this.user.data.uid,
                            name: this.user.data.displayName
                        }
                    }),
                    unreadCountInt: 0 //@todo remove this once read is implemented
                });
        }
    }
}