<template>
  <div>
    <form novalidate class="md-layout" @submit.prevent="validate">
      <md-card class="md-layout-item md-size-100 md-small-size-100">
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <FacebookIntegration />
              <md-field :class="getValidationClass('facebookPage')" v-if="facebookPages.length">
                <label>Facebok Page</label>
                <md-select v-model="form.facebookPage" md-dense :disabled="sending">
                  <md-option
                    v-for="system in facebookPages"
                    :value="system.id"
                    :key="system.id"
                  >{{system.name}}</md-option>
                </md-select>
                <span
                  class="md-error"
                  v-if="!$v.form.facebookPage.required"
                >Facebook page is required</span>
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-card-actions>
          <md-button type="button" class="md-secondary" @click="close()" :disabled="sending">Cancel</md-button>
          <md-button
            type="submit"
            class="md-primary"
            :disabled="sending || !facebookPages.length"
          >Update</md-button>
        </md-card-actions>
      </md-card>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";
import firebase from "firebase";

let refChannels = db.collection("channels");

import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

import FacebookIntegration from "@/components/blocks/integrations/Facebook";

export default {
  name: "Facebook",
  components: {
    FacebookIntegration,
  },
  mixins: [validationMixin],
  props: ["channel"],
  computed: {
    ...mapGetters({
      accounts: "accounts",
      sending: "sending",
      facebookPages: "facebookPages",
      userToken: "userToken",
    }),
  },
  data: () => ({
    form: {
      facebookPage: null,
    },
  }),
  mounted() {
    this.form.facebookPage = this.channel.facebookPage?.id;
  },
  validations: {
    form: {
      facebookPage: {
        required,
      },
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.facebookPage = null;
    },
    async saveChannel() {
      this.$store.dispatch("setSending", true);
      //let doc_id = this.form.id;
      //let param = null;
      let facebookPageObj = this.facebookPages.filter(
        (facebookPage) => facebookPage.id === this.form.facebookPage
      );
      if (facebookPageObj.length) {
        facebookPageObj = facebookPageObj[0];
      }

      //alert(await this.checkPageLinkExists(facebookPageObj.id));
      //this.$store.dispatch("setSending", false);
      //return;

      refChannels
        .doc(this.channel.id)
        .update({
          facebookPage: facebookPageObj,
        })
        .then(async () => {
          let response = await this.callGenerateToken();
          
          this.$store.dispatch("setSending", false);
          this.$store.dispatch(
            "showSnackbar",
            response.status ? "Integration Updated Successfully" : response.error
          )
          this.clearForm();
          this.close();
        })
        .catch((err) => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", err);
        });
    },
    async checkPageLinkExists(pageId) {
      //this method has permission issue
      let response = await refChannels
        .where("facebookPage.id", "==", pageId)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            console.log("exists");
            return true;
          } else {
            console.log("not exists");
            return false;
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
      return response;
    },
    async callGenerateToken() {
      let channelId = this.channel.id;
      let url = `facebook/token?token=${this.userToken}`;
      return await axios({
        url: url,
        method: "POST",
        data: {
          channelId: channelId,
        },
      })
        .then((resp) => {
          if (resp.status === 200) {
            return resp.data;
          }
        })
        .catch((err) => {
          return {status: false, error: err};
        });
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveChannel();
      }
    },
  },
};
</script>
