<template>
  <md-avatar
    class="md-avatar-icon md-large custom-avatar"
    :style="
      `background-color: ${color.bgColor}; border-color: ${color.color}; color: ${color.color}; height:90px!important; width: 90px !important;`
    "
  >
    <img v-bind:src="photoUrlHttps" :style="`width: 100%; height:100%;`"
       v-if="photoUrl"  />
    <md-ripple  v-else>{{ initials }}</md-ripple>
  </md-avatar>
</template>

<script>
export default {
  name: "Avatar",
  props: ["name", "photoUrl"],
  data() {
    return {
      color: this.getRandomColor(),
      height:"60px !important",
      width:"60px !important"
    };
  },
  computed: {
    initials() {
      let name = this.name;
      if (!name) {
        return "";
      }
      var initials = name.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();
      return initials;
    },
    photoUrlHttps() {
      if (this.photoUrl) return this.photoUrl.replace("http://", "https://");
    },
  },
  methods: {
    getRandomColor() {
      let colors = ["#069058", "#EF8211", "#6329D4", "#9734C6"];
      let bgColors = ["#BBF7DE", "#FFE8C2", "#ECE4FA", "#F3D9FF"];
      let curIndex = Math.floor(Math.random() * colors.length);
      return {
        bgColor: bgColors[curIndex],
        color: colors[curIndex],
      };
    },
  },
};
</script>

<style lang="scss">
.custom-avatar {
  border-style: solid;
  border-width: 1.5px;
  font-size: 18px !important;
  font-weight: 600;
  width: 50px !important;
  height: 50px !important;
}

.custom-avatar img {
  height: 47px;
  width: 47px;
}
</style>
