<template>
  <div>
    <form novalidate class="md-layout" @submit.prevent="validateUser">
      <md-card class="md-layout-item md-size-100 md-small-size-100">
        <md-card-content class="mx-5">
          <md-field :class="getValidationClass('name')">
            <label for="name">Email</label>
            <md-input autocomplete="email" v-model="form.email" :disabled="sending" />
            <span class="md-error" v-if="!$v.form.email.required">The email is required</span>
          </md-field>

          <md-field :class="getValidationClass('role')">
            <label for="role">Role</label>
            <md-select v-model="form.role" md-dense :disabled="sending">
              <md-option v-for="role in roles" :value="role" :key="role">{{role}}</md-option>
            </md-select>
            <span class="md-error" v-if="!$v.form.role.required">The role is required</span>
          </md-field>
        </md-card-content>

        <md-card-actions>
          <md-button type="button" class="md-secondary" @click="close()">Cancel</md-button>
          <md-button type="submit" class="md-primary" :disabled="sending">Add User</md-button>
        </md-card-actions>
      </md-card>
    </form>
  </div>
</template>

<script>
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";
import firebase from "firebase";

import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

const refUser = db.collection("users");

export default {
  name: "User",
  mixins: [validationMixin],
  props: {
    form: {
      type: Object,
      default: function() {
        return {
          email: null,
          role: null
        };
      }
    },
    account: {
      default: null
    }
  },
  computed: {
    ...mapGetters({
      accounts: "accounts",
      sending: "sending",
      user: "user"
    })
  },
  data: () => ({
    userSaved: false,
    lastUser: null,
    roles: ["Admin", "User"]
  }),
  validations: {
    form: {
      email: {
        required
      },
      role: {
        required
      }
      // accountId: {
      //   required
      // }
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.name = null;
      this.form.email = null;
      this.form.role = null;
      this.form.accountId = null;
    },
    saveUser() {
      let account = this.account;
      const accountSave = {
        accountId: account.id,
        role: this.form.role,
        accountName: account.name
      };
      //this.sending = true;
      this.$store.dispatch("setSending", true);
      refUser
        .where("email", "==", this.form.email)
        .get()
        .then(querySnapshot => {
          if (querySnapshot.empty) {
            db.collection("invites")
              .where("invitedEmail", "==", this.form.email)
              .where("accountId", "==", account.id)
              .get()
              .then(querySnapshot => {
                if (querySnapshot.empty) {
                  db.collection("invites")
                    .doc()
                    .set({
                      ...accountSave,
                      createdAt: firebase.firestore.Timestamp.now(),
                      invitedEmail: this.form.email,
                      invitedBy: {
                        name: this.user.data.email,
                        id: this.user.data.uid
                      },
                      accessGranted: false
                    })
                    .then(() => {
                      this.formProcessed("User Invited Successfully");
                    })
                    .catch(err => {
                      this.formProcessed(err);
                    });
                } else {
                  this.formProcessed("User Alredy Invited");
                }
              });

            // db.collection("invites")
            //   .doc(this.form.email)
            //   .get()
            //   .then(doc => {
            //     if (doc.exists) {
            //       doc.ref
            //         .collection("access")
            //         .doc(account.id)
            //         .set(accountSave)
            //         .then(() => {
            //           this.formProcessed("User Invited Again");
            //         })
            //         .catch(err => {
            //           this.formProcessed(err);
            //         });
            //     } else {
            //       doc.ref
            //         .set({
            //           createdAt: firebase.firestore.Timestamp.now(),
            //           invitedBy: this.user.data.email,
            //           invitedId: this.user.data.uid
            //         })
            //         .then(() => {
            //           doc.ref
            //             .collection("access")
            //             .doc(account.id)
            //             .set(accountSave)
            //             .then(() => {
            //               this.formProcessed("User Invited Successfully");
            //             })
            //             .catch(err => {
            //               this.formProcessed(err);
            //             });
            //         })
            //         .catch(err => {
            //           this.formProcessed(err);
            //         });
            //     }
            //   });
          }
          querySnapshot.forEach(doc => {
            doc.ref
              .collection("access")
              .doc(this.account.id)
              .get()
              .then(subDoc => {
                if (subDoc.exists) {
                  this.formProcessed("User already has access to this account");
                } else {
                  subDoc.ref
                    .set(accountSave)
                    .then(() => {
                      this.formProcessed("Account access granted to the user");
                    })
                    .catch(err => {
                      this.formProcessed(err);
                    });
                }
              });
          });
        })
        .catch(err => {
          this.formProcessed(err);
        });
    },
    formProcessed(message) {
      this.$store.dispatch("setSending", false);
      this.$store.dispatch("showSnackbar", message);
      this.clearForm();
      this.close();
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveUser();
      }
    }
  }
};
</script>
