<template>
  <md-list-item>

    <div class="row col-12 m-0 p-0 one-lead">
      <div :class="'lead-day-container'">
        <div class="lead-day">
          <div class="md-title">
            <span v-if="action.contactInfo.phone">
              <a :href="'tel:' + action.contactInfo.phone"><md-icon
                  class="md-primary mx-1 mb-3 text-white">phone</md-icon></a>
            </span>
          </div>
          <div class="md-title">
            <span v-if="action.contactInfo.email">
              <a :href="'mailto:' + action.contactInfo.email" target="_blank"><md-icon
                  class="md-primary mx-1 text-white">email</md-icon></a>
            </span>
          </div>
          <div class="md-title">
            <router-link :to="{ name: 'Action Detail', params: { id: action.id } }">
              <md-icon class="md-primary mx-1 mt-3 text-white">note_add</md-icon>
            </router-link>

          </div>
        </div>
      </div>
      <div class="lead-content lead-content-action">
        <router-link :to="{ name: 'Action Detail', params: { id: action.id } }">
          <div class="md-layout">

            <div class="md-layout-item md-small-size-100 py-3">
              <Avatar :name="action.contactInfo.displayName" :photoUrl="action.contactInfo.photoUrl"
                class="ml-3 float-left" />
              <div class="float-left mx-2">
                <div class="main-info">
                  <span class="md-title contact-name">
                    {{
                      action.contactInfo.displayName
                    }}
                  </span>
                  <!-- <span :class="'custom-badge-1 ml-2 text-white badge-color-0 status-'+todo.dueTime" >
                    {{todo.reviewTextTitle}} {{todo.reviewTextSubHeading}}
                  </span> -->
                </div>
                <div class="contact-info my-2">
                  <span v-if="action.contactInfo.phone">
                    <md-icon class="md-primary mx-1">phone_iphone</md-icon>
                    <a :href="'tel:' + action.contactInfo.phone">{{ action.contactInfo.phone }}</a>
                  </span>
                  <span v-if="action.contactInfo.email">
                    <md-icon class="md-primary mx-1">email</md-icon>
                    <a :href="'mailto:' + action.contactInfo.email">{{ action.contactInfo.email }}</a>
                  </span>
                </div>
                <div class="contact-info my-2">

                  {{ action.lastUpdated !== undefined ? `Last Updated Date ${getShortDate(todo.lastUpdated.seconds)}` : ""}}
                <br/>
                  Action Created Date: {{ getShortDate(action.todoCreated.seconds) }}<br>
                Action  logged status: {{ action.todoStatus }} <br/>
                Action  Todo: {{ action.todo }}
                </div>
                <!-- <div class="status-info py-2">
                  <span
                    v-for="(tag, index) in todo.tagsShow"
                    :key="index"
                    class="custom-badge mr-2"
                    :class="'badge-color-' + index"
                  >{{ tag.name }}
                  </span>
                </div> -->
                <div v-if="action.leadID">
                <router-link :to="{ name: 'Lead Detail', params: { id: action.leadID } }">
                  <span class="custom-badge mr-2 badge-color-0">View Lead </span>
                </router-link>
              </div>
              </div>
            </div>

            <div class="md-layout-item md-small-size-100 lead-info-container">
              <md-card v-for="(info, index) in action.todoInfo" :key="index" class="lead-info">
                <md-card-header>
                  <div>{{ info.title }}</div>
                </md-card-header>

                <md-card-content class="text-wrap">{{ info.description }}</md-card-content>
              </md-card>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </md-list-item>
</template>
<style>
.lead-day-container {
  width: 70px !important;
}

.status-overdue {
  background: #f44336;
}

.status-due {
  background: purple;
}

.custom-badge-1 {
  border-radius: 50px;
  padding: 2px 6px;
  font-weight: 500;
  font-size: 13px;
}
</style>
<script>
import Avatar from "@/components/blocks/Avatar";
import moment from "moment";
import ActionModel from "../../../models/ActionModel";

export default {
  name: "ActionItem",
  props: ["actionObj", "accounts"],
  methods: {
    getShortDate(ms) {
      //return moment(ms).format("M/D");
      const date= new Date(moment(ms * 1000).format("L"));
      let finalDate= `${date.getDate()}  ${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()} `
     return finalDate;
    },

  },
  components: {
    Avatar,
  },
  data() {
    return {
      action: ActionModel(this.actionObj, this.accounts),
    };
  },
};
</script>