var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-message w-100 px-1"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.addMessage($event)}}},[_c('div',{staticClass:"message-action"},[_c('div',{staticClass:"sender-info d-flex p-2"},[_c('div',{staticClass:"mr-auto channel-container"},[_c('span',{staticClass:"text-gray"},[_c('strong',[_vm._v("Channel:")]),_vm._v(" "+_vm._s(_vm.thread.channelType)+" ")])]),_c('div',{staticClass:"sender-details mt-1"},[_c('span',{staticClass:"text-gray mx-3"},[_c('strong',[_vm._v("From:")]),_vm._v(" "+_vm._s(_vm.from)+" ")]),_c('span',{staticClass:"text-gray"},[_c('strong',[_vm._v("To:")]),_vm._v(" "+_vm._s(_vm.thread.to)+" ")])])]),(!_vm.htmlEditor)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newMessage),expression:"newMessage"}],staticClass:"new-message-input",attrs:{"readonly":_vm.sending},domProps:{"value":(_vm.newMessage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newMessage=$event.target.value}}}):_c('Editor',{attrs:{"initial-content":"","active-buttons":[
          'bold',
          'italic',
          'strike',
          'underline',
          'code',
          'paragraph',
          'h1',
          'h2',
          'h3',
          'bullet_list',
          'ordered_list',
          'blockquote',
          'code_block',
          'horizontal_rule',
          'undo',
          'redo' ]},on:{"update":_vm.messageUpdate}}),_c('div',{staticClass:"col"},[_c('div',{staticClass:"row",staticStyle:{"overflow":"hidden"}},[(_vm.feedback)?_c('p',{staticClass:"text-danger mx-3 col s6"},[_vm._v(_vm._s(_vm.feedback))]):_vm._e(),_c('div',{staticClass:"mx-0 text-right col s6"},[_c('md-button',{staticClass:"md-raised md-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.addNote()}}},[_vm._v("Add Note")]),_c('md-button',{staticClass:"md-raised md-primary",attrs:{"type":"submit"}},[_c('img',{attrs:{"src":require("@/assets/img/icon-send.svg")}}),_vm._v(" Send ")])],1)])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }