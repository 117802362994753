<template>
  <div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Add User</md-dialog-title>
      <FormAccess @close="showDialog = false" :account="account" />
    </md-dialog>

    <md-button v-if="user" class="md-primary" @click="showDialog = true">
      <md-icon>edit</md-icon>Edit
    </md-button>
    <md-button v-else class="md-primary md-raised" @click="showDialog = true">Add User</md-button>
  </div>
</template>

<script>
import FormAccess from "@/components/blocks/forms/Access";

export default {
  name: "AddUser",
  props: {
    user: {
      type: Object,
      default: null
    },
    account: {
      default: null
    }
  },
  components: {
    FormAccess
  },
  data: () => ({
    showDialog: false
  })
};
</script>
