<template>
  <div>
    <md-dialog-prompt
      :md-active.sync="showDialog"
      v-model="channel.channelName"
      md-title="Edit Channel Name"
      md-input-placeholder="Channel Name"
      md-confirm-text="Update"
      @md-confirm="updateChannelName" />
    <md-button 
      class="md-icon-button md-primary" 
      @click="showDialog = true"
    >
      <md-icon>edit</md-icon>
    </md-button>
    <md-snackbar :md-active.sync="saved">
      The channel name was updated with success!
    </md-snackbar>
  </div>
</template>
<script>
import {db} from '@/firebase/init'
import {mapGetters} from 'vuex'

let refChannels = db.collection('channels')

export default {
  name: 'EditChannelName',
  props: [
    'channel'
  ],
  computed: { 
    ...mapGetters({
      sending: 'sending'
    }),
  },
  data: () => ({
    saved: false,
    showDialog: false
  }),
  methods: {
    updateChannelName(val) {
      this.$store.dispatch('setSending', true)
      refChannels.doc(this.channel.id).update({
        channelName: val
      }).then(() => {
        this.saved = true
        this.$store.dispatch('setSending', false)
      })
    }
  } 
}
</script>