<template>
  <md-button class="md-icon-button" @click="action()" title="Move to Trash">
    <md-icon class="text-gray">delete_outline</md-icon>
  </md-button>
</template>
<script>
import StatusUpdateMixin from "@/mixins/StatusUpdateMixin";

export default {
  name: "ActionTrash",
  props: ["item", "collection"],
  mixins: [StatusUpdateMixin],
  data: () => ({
    status: "T"
  })
};
</script>