<template>
  <span>
    <md-dialog-prompt
      :md-active.sync="showDialog"
      v-model="tagName"
      md-title="Add new tag"
      md-input-placeholder="Tag Name"
      md-confirm-text="Add"
      @md-confirm="$emit('addTag', tagName)" />
    <md-button
        :class='className'
      @click="showDialog = true; tagName=''"
        :style='styleSheet'
    >
      <md-icon>add_box</md-icon>
    </md-button>
  </span>
</template>
<script>

export default {
  name: 'AddTag',
  props: ["className","styleSheet"],
  data: () => ({
    showDialog: false,
    tagName: '',
  })
}
</script>