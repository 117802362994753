<template>
  <div class="new-message w-100 px-1">
    <form @submit.prevent="addMessage">
      <div class="message-action">
        <div class="sender-info d-flex p-2">
          <!-- <md-field class="mr-auto channel-container" md-dense style="width:200px;">
            <label for="channel">Channel</label>
            <md-select v-model="currentChannel" name="channel" id="channel">
              <md-option
                v-for="(channel, index) in channelList"
                :value="channel"
                :key="index"
              >{{channel}}</md-option>
            </md-select>
          </md-field>-->
          <div class="mr-auto channel-container">
            <span class="text-gray">
              <strong>Channel:</strong>
              {{thread.channelType}}
            </span>
          </div>

          <div class="sender-details mt-1">
            <span class="text-gray mx-3">
              <strong>From:</strong>
              {{from}}
            </span>
            <span class="text-gray">
              <strong>To:</strong>
              {{thread.to}}
            </span>
          </div>
        </div>
        <!-- <md-field class="mb-2"> -->
        <!-- <md-textarea v-model="newMessage" :readonly="sending"></md-textarea> -->
        <!-- </md-field> -->
        <textarea
          v-if="!htmlEditor"
          v-model="newMessage"
          :readonly="sending"
          class="new-message-input"
        ></textarea>
        <Editor
          v-else
          initial-content
          :active-buttons="[
            'bold',
            'italic',
            'strike',
            'underline',
            'code',
            'paragraph',
            'h1',
            'h2',
            'h3',
            'bullet_list',
            'ordered_list',
            'blockquote',
            'code_block',
            'horizontal_rule',
            'undo',
            'redo',
          ]"
          @update="messageUpdate"
        />
        <div class="col">
        <div class="row" style="overflow: hidden">
        <p v-if="feedback" class="text-danger mx-3 col s6">{{ feedback }}</p>
        <div class="mx-0 text-right col s6">
          <!-- <md-button class="md-icon-button">
            <md-icon>sentiment_satisfied_alt</md-icon>
          </md-button>
          <md-button class="md-icon-button">
            <md-icon>edit</md-icon>
          </md-button>
          <md-button class="md-icon-button">
            <md-icon>attach_file</md-icon>
          </md-button>
          <md-button class="md-icon-button">
            <md-icon>add_circle_outline</md-icon>
          </md-button>-->
          <!-- <CreateNoteDialog :thread="thread" />selectedThread -->
          <md-button type="button" class="md-raised md-primary" @click="addNote()">Add Note</md-button>
          <md-button type="submit" class="md-raised md-primary">
            <img src="@/assets/img/icon-send.svg" />
            Send
          </md-button>
        </div>
        </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";

import CreateNoteDialog from "@/components/blocks/dialogs/CreateNote";
import Editor from "@/components/blocks/editor/Editor.vue";

const firebase = require("firebase");
const refChannels = db.collection("channels");
// Required for side-effects
require("firebase/functions");

export default {
  name: "NewMessage",
  props: ["thread"],
  components: {
    Editor,
    CreateNoteDialog
  },
  data() {
    return {
      demo: false,
      newMessage: null,
      feedback: null,
      currentChannel: this.thread.channelSystem,
      channel: null,
      from: null
      // channelList: [
      //   'Facebook',
      //   'TextMagic',
      //   'Nylas',
      //   'Twilio'
      // ]
    };
  },
  watch: {
    thread: {
      immediate: true,
      handler(newValue, oldValue) {
        this.saveDraft(newValue, oldValue);
        //console.log(newValue);
        if (!(oldValue && newValue.id === oldValue.id)) {
          this.from = "Loading...";
          //check if thread is same or changed
          refChannels
            .doc(newValue.fullData.channelId)
            .get()
            .then(doc => {
              let channel = doc.data();
              //console.log(channel);
              this.channel = channel;
              this.from = channel.fromEmail || channel.fromPhone || channel.facebookPage?.name;
            });
        }
      }
    },
    editorContent: {
      init: true,
      handler(newValue, oldValue) {
        this.newMessage = newValue;
      }
    }
  },
  computed: {
    ...mapGetters({
      sending: "sending",
      user: "user",
      editorContent: "editorContent"
    }),
    channelList() {
      return [this.thread.channelSystem];
    },
    htmlEditor() {
      let channel = this.thread.channelSystem;
      if (channel) {
        return channel.toLowerCase() === "nylas";
      }
    }
  },
  methods: {
    addMessage() {
      this.sendMessage("");
    },
    addNote() {
      this.sendMessage("note");
    },
    sendMessage(messageType) {
      if (this.newMessage) {
        this.$store.dispatch("setSending", true);
        let sendMessage = firebase.functions().httpsCallable("sendMessage");
        let sendMessageObj = {
          threadId: this.thread.id,
          messageText: this.newMessage,
          messageType: messageType,
          senderName: this.user.data.displayName,
          senderId: this.user.data.uid
        };

        this.$store.dispatch("setEditorContent", this.newMessage);
        sendMessage(sendMessageObj)
          .then(result => {
            console.log(result);
            this.newMessage = null;
            this.feedback = null;
            this.$store.dispatch("setEditorContent", "");
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", "Message Sent");
            this.updateDraft(this.thread.id, "");
          })
          .catch(error => {
            this.$store.dispatch("setEditorContent", "a");
            this.newMessage = null;
            // Getting the Error details.
            this.feedback = null;
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", error);
          });
      } else {
        this.feedback = "You must enter a message in order to send one";
      }
    },
    saveDraft(newValue, oldValue) {
      if (oldValue && newValue.id !== oldValue.id) {
        let message = this.newMessage;
        if (message) {
          if (message !== oldValue.draft) {
            this.updateDraft(oldValue.id, message);
          }
        }
      }
      if (newValue.draft) {
        this.newMessage = newValue.draft;
        this.$store.dispatch("setEditorContent", newValue.draft);
      } else {
        this.newMessage = "";
        this.$store.dispatch("setEditorContent", "");
      }
    },
    demoMessage() {
      this.$emit("demoMessage");
    },
    removeDemoMessages() {
      this.$emit("removeDemoMessages");
    },
    messageUpdate(message) {
      this.newMessage = message;
      this.feedback = null;
    },
    updateDraft(threadId, message) {
      db.collection("threads")
        .doc(threadId)
        .update({
          draft: message
        });
    }
  }
};
</script>

<style lang="scss">
.message-action {
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  margin: 0.25rem;
  .sender-info {
    border-bottom: 1px solid #e5e5e5;
    .channel-container {
      width: 200px;
      margin-bottom: 0px;
      text-transform: capitalize;
    }
    .sender-details {
      font-size: 13px;
    }
  }
}
</style>