<template>
  <div class="leads px-md-3">
    <md-toolbar>
      <div class="main-info" style="flex: 1">
        <h3 class="md-title float-left">
          <!-- <md-icon>keyboard_arrow_down</md-icon> -->
          <md-menu md-size="medium" md-align-trigger>
            <md-button md-menu-trigger mdCloseOnSelect="true" class="btn-title">
              {{ statusOptions[actionStatus] }}
              <md-icon>keyboard_arrow_down</md-icon>
            </md-button>

            <md-menu-content>
              <md-menu-item
                v-for="(status, key) in statusOptions"
                :key="key"
                @click="actionStatus = key"
                :to="{ name: status }"
                >{{ status }}</md-menu-item
              >
            </md-menu-content>
          </md-menu>
        </h3>
        <!-- <span class="custom-badge status-primary ml-3">{{leads.length}}</span> -->
      </div>
      <!-- <DialogCreateLead /> -->
      <CreateActionItemFront/>
      <!-- <LeadBulkActions
        :leads="this.leads"
        :actionStatus="actionStatus"
        class="ml-2"
      /> -->
      <!-- <md-button class="md-raised md-primary">Add Lead</md-button> -->
      <md-menu
        md-size="medium"
        md-align-trigger
        :mdCloseOnClick="closeOnClick"
        :mdCloseOnSelect="closeOnSelect"
      >
        <md-button md-menu-trigger class="md-icon-button">
          <md-icon>filter_list</md-icon>
        </md-button>

        <md-menu-content class="px-3 md-elevation-20 bg-white account-filter">
          <div class="md-title text-gray my-2">Leads Filters</div>
          <md-menu-item
            @click="($event) => (selectedTimeFilter = 'lastUpdated')"
          >
            <md-radio
              v-model="selectedTimeFilter"
              value="lastUpdated"
              @click="($event) => !closeOnSelect"
            >
              Last Updated</md-radio
            >
          </md-menu-item>
          <md-menu-item @click="selectedTimeFilter = 'leadReviewDate'">
            <md-radio
              v-model="selectedTimeFilter"
              value="leadReviewDate"
              @click="($event) => !closeOnSelect"
            >
              Last Reviewed</md-radio
            >
          </md-menu-item>
          <md-menu-item @click="selectedTimeFilter = 'leadCreated'">
            <md-radio
              v-model="selectedTimeFilter"
              @click="($event) => !closeOnClick"
              value="leadCreated"
            >
              Last Created</md-radio
            >
          </md-menu-item>
        </md-menu-content>
      </md-menu>
    </md-toolbar>
    <md-list class="md-elevation-4">
      <ActionItem 
        v-for="action in filteredActions"
        :key="action.id"
        :actionObj="action"
        :accounts="accounts"
      />
    </md-list>
    <md-list-item v-if="scrollProgress">
      <!-- <div class="w-100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></div> -->
      <md-progress-spinner
        md-mode="indeterminate"
        :md-stroke="3"
        class="mx-auto"
      ></md-progress-spinner>
    </md-list-item>
  </div>
</template>

<script>
//import DialogCreateLead from "@/components/blocks/dialogs/CreateLead";
import CreateActionItem from "@/components/blocks/dialogs/CreateActionItem";
import { db } from "@/firebase/init";
import { mapGetters } from "vuex";

import FiltersAccounts from "@/components/blocks/filters/Accounts";
import ActionItem from "@/components/blocks/items/ActionItem"
import LeadBulkActions from "@/components/blocks/actions/LeadBulkActions";
import LeadsFilter from "@/components/blocks/filters/LeadsFilter.vue";
import CreateActionItemFront from "@/components/blocks/dialogs/CreateActionItemFront";

//const leadsRef = db.collection("contacts").orderBy('lastUpdated', "desc");
//this was leadReviewDate previously

let lastVisible = "";

export default {
  name: "ActionItems",
  components: {
    CreateActionItemFront,
    FiltersAccounts,
    ActionItem,
    LeadBulkActions,
    LeadsFilter,
  },
  data() {
    return {
      showCount: 40,
      fetchedAccounts: [],
      //displayLastCreatedLeads:["lastUpdated","lastCreated"],
      selectedTimeFilter: "lastUpdated",
      actionItems: [],
      scrollPosition: null,
      scrollProgress: false,
      scrollCheck: true,
      actionStatus: "all",
      statusOptions: {
        all: "All Action-items",
        active: "Active Action-items",
        completed:"Completed Action-items"
        // won: "Won  ",
        // //"S": "Starred",
        // lost: "Lost Leads",
      },
      closeOnClick: true,
      closeOnSelect: true,
      unsubscribe: null,
    };
  },
  computed: {
    ...mapGetters({

      accounts: "accounts",
      selectedAccounts: "selectedAccounts",
    }),
    filteredActions() {
      let actionItems = this.actionItems.filter(
       
        (actionItem) => this.selectedAccounts.indexOf(actionItem.accountId) !== -1
      );
      return actionItems;
    },
  },
  watch: {
    // selectedAccounts: {
    //   handler(accounts) {
    //     console.log("watcher :: selectedAccounts ---", this.selectedAccounts)
    //     console.log("watcher :: selectedAccounts ::accounts---", accounts)
    //     this.actionItemsHandler(accounts, false);
    //     console.log("this.leads---", this.leads)
    //     // this.updateLeadCreated(accounts);
    //   }
    // },
    selectedTimeFilter(value) {
      this.actionItemsHandler(this.selectedAccounts, true);
    },
    selectedAccounts(newValue, oldValue) {
      
      // merge two arrays
      let arr = newValue.concat(oldValue);
      let uniqueArr = [];

      // loop through array
      for (let i of arr) {
        if (uniqueArr.indexOf(i) === -1) {
          uniqueArr.push(i);
        }
      }
      this.actionItemsHandler(this.selectedAccounts, true);
    },
    showCount: {
      handler(showCount) {
        this.actionItemsHandler(this.selectedAccounts, true);
      },
    },
    actionStatus: {
      handler(actionStatus) {
        //console.log("this.actionStatus handler---------------", actionStatus);
        this.actionItemsHandler(this.selectedAccounts, this.actionStatus);

      },
    },
  },
  // firestore: {
  //   leads: db.collection("contacts").where("todoStatus","==","lead")
  // },
  mounted() {
    window.addEventListener("scroll", this.scrollHandler);

    let route = this.$route.name;
    
  
    this.actionItemsHandler(this.selectedAccounts, false);
  
  },
  methods: {
    actionItemsHandler(accounts, countChanged) {
      let newAccounts = this.selectedAccounts.filter(
        (acountId) => !this.fetchedAccounts.includes(acountId)
      );

      if (!newAccounts.length && !countChanged) {
      
        return;
      }

      this.fetchedAccounts = [...this.selectedAccounts];

      // this.$bind(
      //   "leads",
      //   leadsRef.where("accountId", "in", accounts).limit(20)
      //   //.limit(this.showCount)
      // ).then(leads => {
      //   // user will point to the same property declared in data:
      //   // this.user === user
      //   if (this.scrollPosition) {
      //     window.scrollTo(0, this.scrollPosition);
      //   }
      //   lastVisible = leads[leads.length - 1];
      // });
      //alert("leads called");
      this.actionItems = [];
      if (this.unsubscribe) {
        this.unsubscribe();
      }
    
      if (this.actionStatus != "all") {
        
        this.unsubscribe = db
          .collection("todo")
          .limit(this.showCount)
          .where("accountId","in",accounts)
         .where("todoStatus","==",this.actionStatus)
          .orderBy(this.selectedTimeFilter, "desc")
          .onSnapshot((querySnapshot) => {
            querySnapshot.forEach((doc) => {
             
              this.actionItems = [...this.actionItems, {id:doc.id,...doc.data()}];
             
            });
          });
      } else {
      
        this.unsubscribe = db
          .collection("todo")
          .limit(this.showCount)
          .where("accountId", "in", accounts)
        
          .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
              this.$store.dispatch("setSending", false);
              let doc = change.doc;
              let actionObj = { id: doc.id, ...doc.data() };
              
              // if(this.actionItems.indexOf(todoObj)== -1){
              //   this.actionItems.push(todoObj)
              // }
          
              // // if (!todoObj.leadReviewDate) {
              //   doc.ref.update({
              //     todoReviewDate: todoObj.todoCreated,
              //   });
              
              //   //this.$store.dispatch("showSnackbar", "Todo updated");
              // }
            
              if (change.type == "modified") {
                //remove the contact
                // this.leads = JSON.parse(JSON.stringify(this.leads.filter((lead) => lead.id !== todoObj.id)));
                // this.leads.push(todoObj); //add contact on top
                // this.leads.splice(0, 0, todoObj); //add contact on top
                this.actionItemsHandler(this.selectedAccounts, true);
              } else if (change.type == "added") {
                this.actionItems.push(actionObj);
              } else if (change.type == "removed") {
                this.actionItems = this.actionItems.filter(
                  (actionItem) => actionItem.id !== actionObj.id
                );
              }
              lastVisible = doc;
            });
          });
      }
      //
    },
    scrollHandler(event) {
      if (this.actionItems.length < this.showCount) {
        return;
      }

      if (
        !this.scrollProgress &&
        window.innerHeight + window.scrollY + 100 >=
          document.body.offsetHeight &&
        this.scrollCheck
      ) {
        // this.scrollPosition = window.scrollY;
        // this.showCount += 20;
        this.scrollProgress = true;
        this.$store.dispatch("setSending", true);
       
        if (this.actionStatus != "all") {
          db.collection("todo")
            .limit(this.showCount)
            .where("accountId", "in", this.selectedAccounts)
            .where("todoStatus", "==", this.actionStatus)
            .orderBy(this.selectedTimeFilter, "desc")
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.empty) {
                
                this.scrollCheck = false;
              }
              this.scrollProgress = false;
              this.$store.dispatch("setSending", false);
              querySnapshot.forEach((doc) => {
                let actionObj = { id: doc.id, ...doc.data() };
                this.actionItems.push(actionObj);
                lastVisible = doc;
              });
            })
            .catch((err) => {
              this.$store.dispatch("showSnackbar", err);
            });
        } else {
          db.collection("todo")
            .limit(this.showCount)
            .where("accountId", "in", this.selectedAccounts)
            .where("todoStatus", "==", this.actionStatus)
            .startAfter(lastVisible)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.empty) {
              
                this.scrollCheck = false;
              }
              this.scrollProgress = false;
              this.$store.dispatch("setSending", false);
              querySnapshot.forEach((doc) => {
                let actionObj = { id: doc.id, ...doc.data() };
                this.actionItems.push(actionObj);
                lastVisible = doc;
              });
            })
            .catch((err) => {
              this.$store.dispatch("showSnackbar", err);
            });
        }
      }
    },
    updateActionCreated(accounts) {
      db.collection("todo")
        .where("todoStatus", "==", "lead")
        .where("accountId", "in", accounts)
        .get()
        .limit(100)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let data = doc.data();
          
            if (!data.leadCreated) {
              doc.ref.update({
                todoReviewDate: data.todoCreated,
              });
              this.$store.dispatch("showSnackbar", "Todo updated");
            }
          });
        })
        .catch((err) => {
          this.$store.dispatch("showSnackbar", err);
        });
    },
    routeToLeadStatus(routeName) {
      if (routeName == "todo") {
        return "todo";
      }
      return Object.keys(this.statusOptions)[
        Object.values(this.statusOptions).indexOf(routeName)
      ];
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollHandler);
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
};
</script>

<style lang="scss">
.one-lead {
  .lead-day-container {
    width: 100px;
    height: auto;
    float: left;
    background: #510fa8;
    color: white;
    text-align: center;
    font-size: 1.25rem;
    border-radius: 10px 0px 0px 10px;
    position: relative;

    &.status-overdue,
    &.status-lost {
      background: #f44336;
    }

    &.status-due {
      background: purple;
    }

    &.status-future,
    &.status-won {
      background: #4caf50;
    }

    .lead-day {
      position: absolute;
      width: 100px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .md-title {
        white-space: initial;
        font-size: 18px;
      }
    }
  }
  .lead-content-action{
    height: 210px !important;
    width: calc(100% - 100px) !important;
  }
  .lead-content {
    /*width: calc(100% - 10px);*/
    background-color: #f7f7fa;
    border-radius: 0px 10px 10px 0px;

    &:hover {
      background-color: #eef2ff;
    }

    .main-info {
      .contact-name {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .contact-info {
      font-size: 13px;

      .md-icon {
        font-size: 18px !important;
      }
    }
  }

  .lead-info-container {
    overflow-y: auto;

    .md-theme-default.lead-info {
      width: 280px;
      background-color: #510fa8;
      color: white;
      float: left;
      border-radius: 10px;
      margin: 5px;
      height: 125px;

      .md-card-header {
        font-size: 16px;
        font-weight: 500;
        padding-top: 10px;
      }

      .md-card-content {
        font-size: 13px;
      }
    }
  }
}

.leads
  .md-list.md-theme-default
  .md-list-item-container:not(.md-list-item-default):not(
    .md-list-item-expand
  ):not([disabled]):hover {
  background: transparent;
}
</style>
