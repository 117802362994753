import { render, staticRenderFns } from "./FormActionItem.vue?vue&type=template&id=39571e70&"
import script from "./FormActionItem.vue?vue&type=script&lang=js&"
export * from "./FormActionItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports