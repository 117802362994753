<template>
  <div id="app">
    <Navbar />
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/layout/Navbar";
export default {
  name: "App",
  components: {
    Navbar,
  },
  watch: {
    '$route'(to, from) {
      let documentTitle = to.params.documentTitle
        ? to.params.documentTitle + " - " + to.name
        : to.name;
        
      document.title = documentTitle + " - Studio Backoffice";
      window.analytics.page(documentTitle);
    },
  },
};
</script>

<style>
body {
  background: #eee;
}
</style>
