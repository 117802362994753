<template>
  <div class="page-container md-layout-column">
    <md-toolbar class="md-primary">
      <md-progress-bar md-mode="indeterminate" v-if="sending" />
      <Snackbar />
      <md-button class="md-icon-button md-small-show" @click="showNavigation = true">
        <md-icon>menu</md-icon>
      </md-button>
      <router-link :to="{ name: 'Home' }" class="brand-logo">
        <img src="@/assets/whitelogo.png" alt="Studio Back Office!" />
      </router-link>
      <div class="md-small-hide">
        <md-tabs
          v-if="user.loggedIn && accounts.length>0"
          class="md-primary"
          md-alignment="left"
        >

        <md-tab id="tab-home-items" md-label="Home" :to="{ name: 'Home' }"></md-tab>
        <md-tab id="tab-action-items" md-label="Action-Items" :to="{ name: 'ActionItems' }"></md-tab>
          <md-tab id="tab-leads" md-label="Leads" :to="{ name: 'Leads' }"></md-tab>
          <md-tab id="tab-contacts" md-label="Contacts" :to="{ name: 'Contacts' }"></md-tab>

        </md-tabs>
      </div>

      <div class="ml-auto md-small-hide">
        <div v-if="!user.loggedIn" class="float-left download-app-link">
          <span>Download the app</span>
          <a href="#" class="mx-3">
            <img src="@/assets/img/appstore.svg" />
          </a>
          <a href="#" class="mx-3">
            <img src="@/assets/img/googleplay.svg" />
          </a>
        </div>
        <md-tabs v-if="!user.loggedIn" class="md-primary" md-alignment="right">
          <md-tab id="tab-login" md-label="Login" :to="{ name: 'Login' }"></md-tab>
          <md-tab id="tab-signup" md-label="Signup" :to="{ name: 'Signup' }"></md-tab>
        </md-tabs>

        <!-- <md-badge v-if="user.loggedIn" md-content="1">
          <md-button class="md-icon-button">
            <md-icon>email</md-icon>
          </md-button>
        </md-badge>
        <md-badge v-if="user.loggedIn" md-content="2">
          <md-button class="md-icon-button">
            <md-icon>notifications</md-icon>
          </md-button>
        </md-badge> -->
        <md-button v-if="user.loggedIn" class="md-icon-button" :to="{name: 'Settings'}">
          <md-icon>settings</md-icon>
        </md-button>
    <md-menu md-size="medium" v-if="user.loggedIn"  md-align-trigger>
        <md-button md-menu-trigger class="md-icon-button"  >
          <md-icon>filter_list</md-icon>
        </md-button>

        <FilterAccounts></FilterAccounts>
      </md-menu>

        <!-- <md-menu v-if="user.loggedIn" md-size="medium" md-align-trigger>
          <md-button class="md-icon-button" md-menu-trigger>
            <md-icon>settings</md-icon>
        </md-button>-->

        <!-- <md-menu-content>
            <md-menu-item :to="{ name: 'SettingsChannels' }">Settings Channels</md-menu-item>
            <md-menu-item :to="{ name: 'SettingsUsers' }">Settings Users</md-menu-item>
        </md-menu-content>-->

        <!-- </md-menu> -->

        <md-menu v-if="user.loggedIn" md-size="medium" md-align-trigger class="ml-3">
          <md-button id="tab-inbox" md-label="Inbox" :to="{ name: 'PageInbox' }"><md-icon>inbox</md-icon></md-button>
          <!--<img v-if="user.loggedIn" src="@/assets/img/icon-user.svg" />-->
          <md-button md-menu-trigger class="mt-1">
            <span v-if="user.data">{{ user.data.displayName }}</span>
            <md-icon>arrow_drop_down</md-icon>
          </md-button>

          <md-menu-content>
            <md-menu-item @click="logout">
              <md-icon>exit_to_app</md-icon>
              <span>Logout</span>
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </div>
    </md-toolbar>
    <md-drawer :md-active.sync="showNavigation" md-swipeable>
      <md-toolbar class="md-primary" md-elevation="0">
        <router-link :to="{ name: 'PageInbox' }" class="brand-logo">
          <img src="@/assets/whitelogo.png" alt="Studio Back Office!" />
        </router-link>
        <md-badge v-if="user.loggedIn" md-content="1" class="ml-auto">
          <md-button class="md-icon-button">
            <md-icon>email</md-icon>
          </md-button>
        </md-badge>
        <md-badge v-if="user.loggedIn" md-content="2">
          <md-button class="md-icon-button">
            <md-icon>notifications</md-icon>
          </md-button>
        </md-badge>
        <div v-if="!user.loggedIn" class="ml-auto">
          <a href="#" class="mx-3">
            <img src="@/assets/img/appstore.svg" />
          </a>
          <a href="#" class="mx-3">
            <img src="@/assets/img/googleplay.svg" />
          </a>
        </div>
      </md-toolbar>

      <md-list v-if="user.loggedIn">

        <md-list-item>
          <md-avatar class="md-primary">
            <img src="@/assets/img/icon-user.svg" />
          </md-avatar>
          <span class="md-list-item-text" v-if="user.data">{{ user.data.displayName }}</span>
        </md-list-item>
        <md-list-item :to="{name: 'ActionItems'}">
          <span class="md-list-item-text">Action-Items</span>
        </md-list-item>
        <md-list-item :to="{name: 'Inbox'}">
          <span class="md-list-item-text">Inbox</span>
        </md-list-item>
        <md-list-item :to="{name: 'Leads'}">
          <span class="md-list-item-text">Leads</span>
        </md-list-item>

        <md-list-item :to="{name: 'Contacts'}">
          <span class="md-list-item-text">Contacts</span>
        </md-list-item>
        <md-divider></md-divider>
        <md-list-item :to="{name: 'Settings'}">
          <md-icon>settings</md-icon>
          <span class="md-list-item-text">Settings</span>
        </md-list-item>
        <md-list-item @click="logout">
          <md-icon>exit_to_app</md-icon>
          <span class="md-list-item-text">Logout</span>
        </md-list-item>

      </md-list>

      <md-list v-if="!user.loggedIn">
        <md-list-item :to="{name: 'Login'}">
          <span class="md-list-item-text">Login</span>
        </md-list-item>
        <md-list-item :to="{name: 'Signup'}">
          <span class="md-list-item-text">Signup</span>
        </md-list-item>
      </md-list>
    </md-drawer>
  </div>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import FilterAccounts from "../blocks/filters/Accounts.vue"
import store from "@/store/index";

import Snackbar from "@/components/blocks/Snackbar";
import { deprecationHandler } from "moment";

export default {
  name: "Navbar",



  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      sending: "sending",
      accounts: "accounts",
    }),
  },
  components: {
    Snackbar,
    FilterAccounts
  },
  data() {
    return {
      dropdown: false,
      showNavigation: false,
      isLogin:false

    };
  },
  methods: {
    mounted(){
      this.loginHandler()
    },
    loginHandler(){
      if(this.$router.path !== "/login"){
        console.log("in created with if condition",this.$router.path)
        this.isLogin =!this.isLogin
      }
    },
    dropMenu() {
      this.dropdown = !this.dropdown;
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          store.dispatch("resetState");
          this.$router.push({ name: "Login" });
        });
    },



  },
};
</script>

<style scoped>
.router-link-active {
  background-color: rgba(0, 0, 0, 0.1);
}
.router-link-active.brand-logo {
  background-color: rgba(0, 0, 0, 0);
}
.md-theme-default .navbar .text-white a:not(.md-button) {
  color: white;
  text-decoration: none;
}
.download-app-link {
  margin-top: 10px;
}
.brand-logo {
  width: 140px;
}
.brand-logo img {
  height: 40px !important;
}
.md-drawer {
  width: 300px;
  z-index: 100;
}
</style>
<style>
.router-link-exact-active{
  border-bottom: 2px solid white !important;
}
.md-tabs-indicator{
  display: none;
}
</style>