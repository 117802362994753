<template>
  <md-list-item>

    <div class="row col-12 m-0 p-0 one-lead">
      <!--      <div :class="'lead-day-container'">
              <div class="lead-day">
      &lt;!&ndash;          <div class="md-title">
                  <span v-if="action.contactInfo.phone">
                    <a :href="'tel:' + action.contactInfo.phone"><md-icon
                        class="md-primary mx-1 mb-3 text-white">event</md-icon></a>
                  </span>
                </div>&ndash;&gt;
                <CreateActionItem :lead="lead"/>
                <div class="md-title">
                     <span v-on:click="logAction(lead,'Phone')" v-if="lead.contactInfo.phone">
                        <a :href="'tel:'+lead.contactInfo.phone"><md-icon class="md-primary mx-1 mb-3 text-white">phone</md-icon></a>
                     </span>
                </div>
                <div class="md-title">
                    <span v-on:click="logAction(lead,'Email')" v-if="lead.contactInfo.email">
                          <a :href="'mailto:'+lead.contactInfo.email" target="_blank"><md-icon class="md-primary mx-1 text-white">email</md-icon></a>
                    </span>
                </div>
                <div class="md-title">
                    <router-link :to="{ name: 'Lead Detail', params: { id: lead.id } }">
                  <md-icon class="md-primary mx-1 mt-3 text-white">note_add</md-icon>
                  </router-link>

                </div>
              </div>
            </div>-->
      <div class="lead-content">
        <div>
          <router-link :to="{ name: 'Lead Detail', params: { id: lead.id } }" @click="preventDefault">
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 py-3 ">

                <Avatar
                    :name="lead.contactInfo.displayName"
                    :photoUrl="lead.contactInfo.photoUrl"
                    class="ml-3 float-left"
                />

                <div class="float-left mx-2">
                  <div class="main-info">
            <span class="md-title contact-name">
              {{ lead.contactInfo.displayName }}
            </span>
                    <span :class="'custom-badge-1 ml-2 text-white badge-color-0 status-' + lead.dueTime">
              {{ lead.reviewTextTitle }} {{ lead.reviewTextSubHeading }}
            </span>
                  </div>
                  <div class="contact-info my-2">
            <span v-for="(tag, index) in lead.tagsShow" :key="index" class="custom-badge mr-2"
                  :class="'badge-color-' + index">
              {{ tag.name }}
            </span>
                    <span v-on:click="logAction(lead,'Phone')" v-if="lead.contactInfo.phone">
              <md-icon class="md-primary mx-1">phone_iphone</md-icon>
              <a :href="'tel:' + lead.contactInfo.phone">{{ lead.contactInfo.phone }}</a>
            </span>
                    <span v-on:click="logAction(lead,'Email')" v-if="lead.contactInfo.email">
              <md-icon class="md-primary mx-1">email</md-icon>
              <a :href="'mailto:' + lead.contactInfo.email">{{ lead.contactInfo.email }}</a>
            </span>
                    <div class="py-2">
                    <span style='padding-right: 20px; margin-top: 2px' class="md-title contact-name" v-on:click="logAction(lead,'Phone')"
                          v-if="lead.contactInfo.phone">
                  <a :href="'tel:'+lead.contactInfo.phone"><md-icon
                      class="text-black">phone</md-icon></a>
               </span>
                    <span style='padding-right: 20px' class="md-title contact-name" v-on:click="logAction(lead,'Email')"
                          v-if="lead.contactInfo.email">
                    <a :href="'mailto:'+lead.contactInfo.email" target="_blank"><md-icon
                        class="text-black">email</md-icon></a>
              </span>
                      <md-dialog :md-active.sync="showDialog">
                        <md-dialog-title>
                          {{'Create New To do' }}
                        </md-dialog-title>

                        <FormActionItem
                            @close="showDialog = false"
                            form-type="add"
                            :lead="lead"
                        />

                      </md-dialog>
                      <span class="md-title contact-name" @click="showDialogTrue($event)">
                    <a :href="'#'"><md-icon
                        class="text-black" title="Add To Do">event</md-icon></a>
              </span>
                      <span v-on:click="preventDefault($event)">
                      <AddTag @addTag="addTag" styleSheet="margin-top: -2px; margin-left: -2px" />
                      </span>
<!--                      <span style='padding-right: 20px' class="md-title" v-on:click="logAction(lead,'Tag')">
                     <a :href="'#'"  @addTag="addTag"><md-icon
                        class="text-black">note_add</md-icon></a>

                  </span>-->
                    <md-menu v-if="lead.leadStatus === 'lead'" md-size="medium" md-align-trigger :mdCloseOnSelect="true">
                      <md-button style="height: 25px" class="md-raised md-primary" md-menu-trigger @click="preventDefault($event)">Set followup
                      </md-button>
                      <md-menu-content>
                        <md-menu-item @click="snoozeLead(1, '24 hours')">24 hours</md-menu-item>
                        <md-menu-item @click="snoozeLead(7, '1 week')">1 week</md-menu-item>
                        <md-menu-item @click="snoozeLead(14, '2 weeks')">2 weeks</md-menu-item>
                        <md-menu-item @click="snoozeLead(21, '3 weeks')">3 weeks</md-menu-item>
                        <md-menu-item @click="lostLead()">Mark as Lost</md-menu-item>
                        <md-menu-item @click="completedLead()">Mark as Won</md-menu-item>
                      </md-menu-content>
                    </md-menu>
<!--                    <md-button
                        class=""
                        @click="showDialog = true"
                    >
                      <md-icon
                          class="text-black" title="Add To Do">event
                      </md-icon>
                    </md-button>-->
                  </div>
                  </div>
                  <div class="contact-info my-2">
                    <!-- Last Updated Date: {{ getShortDate(lead.lastUpdated.seconds) }}<br/>
                    Lead Created Date: {{ getShortDate(lead.leadCreated.seconds) }}<br/>
                    Lead logged status: {{ lead.leadStatus }} <br/> -->
                    <!-- Lead status: {{ lead.status }} -->
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-small-size-100 lead-info-container">
                <md-card v-for="(info, index) in lead.leadsInfo" :key="index" class="lead-info">
                  <md-card-header>
                    <div>{{ info.title }}</div>
                  </md-card-header>
                  <md-card-content class="text-wrap">{{ info.description }}
                  </md-card-content>
                </md-card>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </md-list-item>
</template>
<style>
.lead-day-container {
  width: 70px !important;
  min-height: 180px;
}

.status-overdue {
  background: #f44336;
}

.status-due {
  background: purple;
}

.custom-badge-1 {
  border-radius: 50px;
  padding: 2px 6px;
  font-weight: 500;
  font-size: 13px;
}
</style>
<script>
import Avatar from "@/components/blocks/Avatar";
import LeadModel from "@/models/LeadModel";
import moment from "moment";
import firebase from "firebase";
import {db} from "@/firebase/init";
import {mapGetters} from "vuex";
import LogAcivityMixin from "@/mixins/LogActivityMixin";
import CreateActionItem from "@/components/blocks/dialogs/CreateActionItem";
import LeadActionsMixin from "@/mixins/LeadActionsMixin";
import FormActionItem from "@/components/blocks/forms/FormActionItem";
import AddTag from "@/components/blocks/dialogs/AddTag";


const leads = db.collection("contacts");

export default {
  name: "Lead",
  props: ["leadObj", "accounts"],
  mixins: [LogAcivityMixin, LeadActionsMixin],

  methods: {
    getShortDate(ms) {
      //return moment(ms).format("M/D");
      const date = new Date(moment(ms * 1000).format("L"));
      let finalDate = `${date.getDate()}  ${date.toLocaleString('default', {month: 'long'})} ${date.getFullYear()} `
      return finalDate;
    },

    logAction(lead, action) {
      event.stopPropagation();
      console.log(this.user)

      this.$store.dispatch("setSending", true);

      //todo = this.todoObj;

      let user = this.user;
      let messageText = user.data.displayName + " clicked on the " + action;
      leads
          .doc(lead.id)
          .update({
            lastContacted: firebase.firestore.Timestamp.now(),
            lastContactedMessage: action
          })
          .then(() => {
            this.logActivity(
                messageText,
                lead.accountId,
                lead.id,
                {
                  call: true,
                  updatedBy: {
                    name: user.data.displayName,
                    id: user.data.uid,
                  },
                }
            );
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", action + " logged successfully");
          })
          .catch((err) => {
            this.$store.dispatch("setSending", false);
            this.$store.dispatch("showSnackbar", err);
          });

    },
    addTag(tagName) {
      this.lead.tags.push({
        name: tagName,
        score: 0,
      });
      this.$store.dispatch("setSending", true);
      leads
          .doc(this.lead.id)
          .update({
            tags: this.lead.tags,
          })
          .then(() => {
            this.$store.dispatch("setSending", false);
          })
          .catch((err) => {
            console.log(err);
            this.$store.dispatch("setSending", false);
          });
    },
  },
  components: {
    Avatar,
    AddTag,
    CreateActionItem,
    FormActionItem
  },
  data() {
    return {
      lead: LeadModel(this.leadObj, this.accounts),
      showDialog: false
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  }

};
</script>
