<template>
  <md-table-row>
    <md-table-cell>
      <span class="text-with-icon">{{channel.channelName}}</span>
      <DialogEditChannel class="float-right" :channel="channel" />
    </md-table-cell>
    <md-table-cell>{{channel.channelSystem}}</md-table-cell>
    <md-table-cell>{{channelInfo}}</md-table-cell>
    <md-table-cell>
      <!-- v-if="channelInterface" -->
      <DialogIframe
        buttonText="Manage"
        :link="channelInterface"
        :show="showDialog"
        class="float-left"
        v-if="channel.channelSystem !== 'Facebook'"
      />
      <DialogFacebookChannel v-else :channel="channel" :show="showDialog" />
    </md-table-cell>
    <md-table-cell>
      <md-switch v-model="notificationChannels" :value="channel.id" class="md-primary"></md-switch>
    </md-table-cell>
    <md-table-cell>
      <DialogDeleteChannel :channel="channel" />
    </md-table-cell>
  </md-table-row>
</template>
<script>
import DialogIframe from "@/components/blocks/dialogs/Iframe";
import DialogCreateChannel from "@/components/blocks/dialogs/CreateChannel";
import DialogEditChannel from "@/components/blocks/dialogs/EditChannel";
import DialogDeleteChannel from "@/components/blocks/dialogs/DeleteChannel";
import DialogFacebookChannel from "@/components/blocks/dialogs/FacebookChannel";
import { mapGetters } from "vuex";

export default {
  name: "ItemChannel",
  props: ["channel"],
  components: {
    DialogIframe,
    DialogCreateChannel,
    DialogEditChannel,
    DialogDeleteChannel,
    DialogFacebookChannel,
  },
  computed: {
    ...mapGetters({
      userToken: "userToken",
      selectedChannel: "selectedChannel",
      notificationChannels: "notificationChannels"
    }),
    channelInfo() {
      let channel = this.channel;
      if (channel.email) {
        return channel.email;
      }

      if (channel.mobileNumber) {
        return channel.mobileNumber;
      }

      if (channel.facebookPage && channel.facebookPage.name) {
        return channel.facebookPage.name;
      }
    },
    channelInterface() {
      let channel = this.channel;
      let link = "",
        extraParams = "",
        part = "";
      let channelSystem = channel.channelSystem;
      switch (channelSystem) {
        case "TextMagic":
          link =
            "https://datasync.studiobackoffice.com/channelconfig/textmagic?channelId=" +
            `${channel.id}` +
            "&token=" +
            this.userToken;
          break;
        case "Twilio":
          part = "channelconfig/twilio";
          break;
        case "Email":
          part = "api/channelconfig/nylas";
          break;
      }
      if(!link){
        link = `${this.$http.defaults.baseURL}${part}?channelId=${channel.id}&token=${this.userToken}&${extraParams}`;
      }
      return link;
    },
    showDialog() {
      return (
        this.selectedChannel.channel === this.channel.id &&
        this.selectedChannel.action === "manage"
      );
    },
  },
  methods: {
    remove() {
      this.$emit("remove");
    },
  },
};
</script>
<style lang="scss" scoped>
.md-table-cell {
  .text-with-icon {
    line-height: 34px;
  }
}
</style>