<template>
    <md-list class="md-elevation-4">
      <md-card
        v-for="(token,index) in tokens"
        :key="index"
        md-with-hover
        class="m-2 bg-light one-contact"
      >
        <md-card-header>
          <md-card-header-text>
            <div class="float-let">
              <div class="row main-info mb-1">
                <span class="md-title contact-name" style="word-wrap: anywhere;">{{token}}</span>
                
              </div>
              <div class="row status-info text-right">
                <span class="contact-info" @click="remove(token)"><md-icon class="md-primary mr-2 mr-1">delete</md-icon></span>
                <span class="contact-info" @click="testNotification(token)"><md-icon class="md-primary mr-2 mr-1">notifications</md-icon></span>
                
              </div>
            </div>
          </md-card-header-text>
        </md-card-header>
      </md-card>

    </md-list>
</template>
<script>
import { mapGetters } from 'vuex';
import { db } from '@/firebase/init'
const firebase = require("firebase");

let refUsers = db.collection('users')

export default {
    name: "Tokens",
    data(){
        return {
            accountContacts: []
        }
    },
    computed: {
      ...mapGetters({
          user: "user",
      }),
      tokens(){
        if(!this.user || !this.user.data.userDoc){
          return [];
        }
        return this.user.data.userDoc.tokens
      }
    },
    methods:{
      remove(selectedToken){
        let uid =  this.user.data.uid;
        let refUser = refUsers.doc(uid);
        refUser.get().then(doc => {
          if(doc.exists){
            let userDoc = doc.data();
            //console.log(user);
            
            let tokens = userDoc.tokens.filter(token => token !== selectedToken);
            refUser.update({
                tokens: tokens
            });

            userDoc.tokens = tokens
            
            this.$store.dispatch("updateUserDocument", userDoc);
              
          } else {
            console.log("user doc not found");
          }
        });
      },
      testNotification(token){
        this.$store.dispatch('setSending', true)
        let sendNotification = firebase.functions().httpsCallable('sendNotification');
        sendNotification({token: token, messageText: "Test"})
        .then(result => {
          //this.newMessage = null
          //this.feedback = null
          console.log(result);
          this.$store.dispatch('setSending', false)
        }).catch((error) => {
          //this.snackbar = true;
          // Getting the Error details.
          let code = error.code;
          let message = error.message;
          let details = error.details;
          //this.newMessage = null
          //this.feedback = null
          console.log(code, message, details)
          this.$store.dispatch('setSending', false)
        });
      }
    }
}
</script>