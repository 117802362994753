<template>
  <span>
    <md-button
      v-if="contact.leadStatus == 'lead'"
      class="md-icon-button"
      :to="{ name: 'Lead Detail', params: { id: contact.id } }"
      title="View Lead"
    >
      <md-icon class="text-gray">person</md-icon>
    </md-button>
    <md-button
      v-else-if="contact.id"
      class="md-icon-button"
      :to="{ name: 'ContactDetail', params: { id: contact.id } }"
      title="View Contact"
    >
      <md-icon class="text-gray">person</md-icon>
    </md-button>
    <md-button v-else class="md-icon-button" title="Link Contact" @click="showLinkDialog = true">
      <md-icon class="text-gray">person_add_alt</md-icon>
    </md-button>
    <md-dialog :md-active.sync="showEditDialog">
      <md-dialog-title>Create Contact</md-dialog-title>
      <ContactForm
        :contact="threadToContact"
        :lockedAccount="true"
        @close="close()"
        @added="added"
      />
    </md-dialog>
    <md-dialog :md-active.sync="showLinkDialog">
      <LinkContacts
        :contact="threadToContact"
        :thread="thread"
        @close="showLinkDialog = false"
        @openContact="showLinkDialog = false; showEditDialog = true;"
      />
    </md-dialog>
  </span>
</template>
<script>
import LinkContactMixin from "@/mixins/LinkContactMixin";

export default {
  name: "Person",
  props: ["contact", "thread"],
  mixins: [LinkContactMixin]
};
</script>