<template>
  <div class="indox">
    <md-app>
      <md-app-drawer :md-active.sync="statusCollapse" md-persistent="full">
        <md-toolbar>
          <div class="md-layout" style="flex:1;">
            <div class="md-layout-item mt-1">
              <h3 class="md-title float-left">
                <md-menu md-size="medium" md-align-trigger>
                  <md-button md-menu-trigger mdCloseOnSelect="true" class="btn-title">
                    {{statusOptions[threadStatus]}}
                    <md-icon>keyboard_arrow_down</md-icon>
                  </md-button>

                  <md-menu-content>
                    <md-menu-item
                      v-for="(status,key) in statusOptions"
                      :key="key"
                      @click="threadStatus = key"
                      :to="{name: status}"
                    >{{status}}</md-menu-item>
                  </md-menu-content>
                </md-menu>
              </h3>
              <!-- <span class="custom-badge status-primary ml-3">{{ filteredThreads.length }}</span> -->
            </div>
          </div>
          <md-menu md-size="medium" md-align-trigger>
            <md-button md-menu-trigger class="md-icon-button">
              <md-icon>filter_list</md-icon>
            </md-button>

            <FiltersAccounts />
          </md-menu>
        </md-toolbar>

        <md-list class="md-elevation-4 contact-list threads custom-scrollbar p-1">
          <md-list-item
            v-for="(thread, index) in filteredThreads"
            :key="index"
            @click="selectThread(thread, true)"
            :class="{'active': selectedThread && thread.id ===  selectedThread.id}"
          >
            <div class="w-100">
              <Avatar :name="thread.name" :photoUrl="thread.photoUrl" class="float-left" />
              <div class="contact-content float-left ml-2">
                <div class="main-info">
                  <span class="md-title contact-name mr-1">{{thread.name}}</span>
                  <md-icon class="text-gray thread-icon" v-if="thread.icon === 'phone'">phone_iphone</md-icon>
                  <md-icon class="md-secondary thread-icon" v-if="thread.icon === 'email'">email</md-icon>
                  <md-icon
                    class="md-secondary thread-icon"
                    v-if="thread.icon === 'facebook'"
                  >message</md-icon>
                  <span class="thread-time float-right text-gray">{{thread.timestamp}}</span>
                </div>
                <div class="my-1">
                  <span
                    class="thread-content text-wrap"
                    :class="{'text-gray' : !thread.unreadCount}"
                  >
                    <span class="font-weight-bold" v-if="thread.icon === 'email'">
                      {{thread.subject ? thread.subject : 'No Subject'}}
                      <br />
                    </span>
                    {{thread.content}}
                  </span>
                  <span
                    v-if="thread.messageCount > 1"
                    class="custom-badge float-right"
                    :class="{'status-read': thread.readStatus, 'status-primary': !thread.readStatus}"
                  >{{thread.messageCount}}</span>
                </div>
                <div class="status-info float-left my-1">
                  <!-- {{thread.tags}} -->
                  <span
                    v-for="(tag,index) in thread.tags"
                    :key="index"
                    class="custom-badge mr-2"
                    :class="tag.class"
                  >{{tag.name}}</span>

                  <!-- <span class="custom-badge status-phone mr-2">Phone</span>
                  <span class="custom-badge status-member mx-2">Member</span>-->
                  <!-- <span class="custom-badge status-email mx-2">Email</span>
                  <span class="custom-badge status-sms mx-2">SMS</span>-->
                </div>
              </div>
            </div>
          </md-list-item>
          <md-list-item v-if="scrollProgress">
            <!-- <div class="w-100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></div> -->
            <md-progress-spinner md-mode="indeterminate" :md-stroke="3" class="mx-auto"></md-progress-spinner>
          </md-list-item>
        </md-list>
      </md-app-drawer>
      <md-app-toolbar>
        <md-button class="md-icon-button md-small-show" @click="toggleCollapse">
          <md-icon>menu</md-icon>
        </md-button>
        <div class="main-info" style="flex:1" v-if="selectedThread">
          <LinkedContact
            v-if="selectedThread.linkedContact"
            :contactId="selectedThread.linkedContact"
            :contact="linkedContactObj"
          />
          <div v-else>
            <Avatar
              v-if="selectedThread"
              :name="selectedThread.name"
              :photoUrl="selectedThread.photoUrl"
              class="float-left"
            />
            <span
              v-if="selectedThread"
              class="md-title contact-name"
              style="line-height:50px"
            >{{selectedThread.name}}</span>
          </div>
        </div>

        <!-- <md-button class="md-raised md-primary">New Contact</md-button> -->
        <Person v-if="selectedThread" :thread="selectedThread" :contact="linkedContactObj" />
        <ThreadActions v-if="selectedThread" :thread="selectedThread" />

        <ThreadMenu
          v-if="selectedThread"
          :thread="selectedThread"
          :linkedContactObj="linkedContactObj"
        />
      </md-app-toolbar>
      <md-app-content v-if="selectedThread" class="p-0">
        <Messages :thread="selectedThread" />
        <NewMessage
          :thread="selectedThread"
          @demoMessage="demoMessage()"
          @removeDemoMessages="removeDemoMessages()"
        />
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
import { db } from "@/firebase/init";
import moment from "moment";
import { mapGetters } from "vuex";
import firebase from "firebase";
import faker from "faker";
import * as Sentry from "@sentry/browser";

import FiltersAccounts from "@/components/blocks/filters/Accounts";
import Thread from "@/components/blocks/Thread";
import Avatar from "@/components/blocks/Avatar";
import Messages from "@/components/blocks/Messages";
import NewMessage from "@/components/blocks/forms/NewMessage";
import ThreadMenu from "@/components/blocks/threads/ThreadMenu";
import Person from "@/components/blocks/contact/Person";
import LinkedContact from "@/components/blocks/items/LinkedContact";
import ThreadModel from "@/models/ThreadModel";

/*Actions*/
import ThreadActions from "@/components/blocks/threads/Actions";

let refThreads = db.collection("threads");
let queryThreads = refThreads.orderBy("latestMessageTime", "desc").limit(20);
let lastVisible;

export default {
  name: "Inbox",
  components: {
    Messages,
    Thread,
    Avatar,
    FiltersAccounts,
    NewMessage,
    ThreadActions,
    ThreadMenu,
    Person,
    LinkedContact,
  },
  data() {
    return {
      unsubscribes: [],
      threads: [],
      threadStatus: "important",
      messages: [],
      selectedThread: null,
      linkedContactObj: {},
      manualSelect: false,
      debug: false,
      route: false,
      routeName: "",
      scrollProgress: false,
      scrollCheck: true,
      statusOptions: {
        important: "Important",
        I: "Inbox",
        A: "Archive",
        // "S": "Starred",
        T: "Trash",
      },
      scrollCount: 0,
      // statusOptions: [
      //   {name: "Inbox", value: "I"},
      //   {name: "Archive", value: "A"},
      //   {name: "Trash", value: "T"},
      // ]
      fetchedAccounts: [],
      queryStatus: null,
      statusCollapse: true,
      routeSelect: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      accounts: "accounts",
      selectedAccounts: "selectedAccounts",
    }),
    filteredThreads() {
      let threads = this.threads.filter(
        (thread) => this.selectedAccounts.indexOf(thread.accountId) !== -1
      );
      if (!threads.length) {
        this.selectedThread = null;
      } else if (!this.selectedThread) {
        this.selectedThread = threads[0];
      }

      return threads;
    },
  },
  mounted() {
    
    this.fetchThreads();
    this.scroll();
    let route = this.$route.params.id;
    this.route = route !== "undefined" ? route : "";
    this.threadStatus = this.routeToThreadStatus(this.$route.name);
  },
  watch: {
    accounts: function (newValue, oldValue) {
      this.fetchThreads();
    },
    selectedAccounts: function (newValue, oldValue) {
      this.fetchThreads();
    },
    threadStatus: function (newValue, oldValue) {
      this.fetchThreads();
    },
    $route(to, from) {
      // react to route changes...
      //console.log(to, from);
    },
  },
  created() {
    let user = this.user.data;
    if (user.hasOwnProperty("userDoc") && user.userDoc) {
      let access = user.userDoc.access;
    }
  },
  methods: {
    fetchThreads() {
      
      if (!this.accounts.length || !this.selectedAccounts.length) {
        this.$store.dispatch("setSending", false);
        return;
      }

      let newAccounts = this.selectedAccounts.filter(
        (acountId) => !this.fetchedAccounts.includes(acountId)
      );

      if (!newAccounts.length && this.queryStatus === this.threadStatus) {
        return;
      }

      // subscribe to changes to the 'messages' collection
      this.scrollCheck = true;
      this.$store.dispatch("setSending", true);
      // if (this.unsubscribe) {
      //   this.unsubscribe();
      //   this.threads = [];
      //   //this.selectedThread = "";
      //   this.manualSelect = false;
      // }
      this.destroySnapshot();
      this.threads = [];
      //this.selectedThread = "";
      this.manualSelect = false;

      try {
        
        this.fetchedAccounts = [...this.selectedAccounts];
        this.queryStatus = this.threadStatus;
        let unsubscribe = queryThreads
          .where("status", "==", this.threadStatus)
          .where("accountId", "in", this.selectedAccounts)
          .onSnapshot((snapshot) => {
            this.$store.dispatch("setSending", false);
            this.routeSelect = false;
            snapshot.docChanges().forEach((change) => {
          
              this.handleThreads(change, 0);
            });
            this.handleThreadsAfter();
          });
        this.unsubscribes.push(unsubscribe);
      } catch (err) {
        this.$store.dispatch("setSending", false);
        this.$store.dispatch("showSnackbar", err);
        Sentry.captureException(err);
      }
    },
    handleThreads(change, scrollCount) {
      let doc = change.doc;
      let thread = ThreadModel(doc, this.accounts);

      lastVisible = doc;
      //console.log(thread.id, change.type, thread.name, scrollCount);
      switch (change.type) {
        case "modified":
          if (thread.id !== this.selectedThread.id) {
            thread.highlight = true; //highlight only when it is currently not selected
          } else {
            this.selectedThread = thread; //update the selectedThread also
            this.getContact(thread);
          }
          //remove the thread
          this.threads = this.threads.filter((thread) => thread.id !== doc.id);
          //this.threads.unshift(thread); //add threa on top
          this.threads.push(thread); //add thread on top
          break;

        case "added":
          //remove the thread
          this.threads = this.threads.filter((thread) => thread.id !== doc.id);

          //this.threads.unshift(thread);
          this.threads.push(thread);
          break;
        case "removed":
          if (thread.id == this.selectedThread.id) {
            this.selectedThread = this.refetchSelectedThread(thread.id); //update the thread if it is selected
          }
          this.threads = this.threads.filter((thread) => thread.id !== doc.id); //remove the thread
          break;
      }
      if (thread.id === this.route) {
        this.selectThread(thread, true);
        this.route = "";
        this.routeSelect = true;
      }
    },
    handleThreadsAfter() {
      if (this.threads.length) {
        this.threads.sort((a, b) => b.secs - a.secs);

        if (!this.manualSelect) {
          //if thread is never manually selected, continue selecting the top thread
          if (this.route) {
            
            this.fetchThreadAndSelect(this.route);
            this.route = "";
          } else if (!this.routeSelect) {
            this.selectThread(this.threads[0], false);
          }
        }
      }
    },
    selectThread(thread, manual) {
      if (this.selectedThread && thread.id === this.selectedThread.id) {
        return;
      }
      this.selectedThread = thread;
      this.manualSelect = manual;

      //thread.bgClass = ''
      //thread.unreadCount = 0;
      //this.$set(this.threads, index, thread)
      // if (thread.unreadCount) {
      //   refThreads.doc(thread.id).update({ unreadCount: 0 });
      // }
      if (!thread.readStatus && thread.messageCount) {
        refThreads.doc(thread.id).update({ readCount: thread.messageCount });
      }
      this.$router
        .push({
          name: this.statusOptions[this.threadStatus] + "Thread",
          params: { id: thread.id, documentTitle: thread.subject },
        })
        .catch((err) => {});
      this.getContact(thread);
    },
    accountsArray() {
      let accountsArray = [];
      let count = 0;
      this.accounts.some((account) => {
        accountsArray.push(account.id);
        count++;
        // if (count > 9) {
        //   this.$store.dispatch(
        //     "showSnackbar",
        //     "Threads from only first 10 accounts are displayed"
        //   );
        //   return true;
        // }
      });
      return accountsArray;
    },
    scroll() {
      let scrollableElement = document.querySelector(".threads");

      scrollableElement.addEventListener("scroll", (event) => {
        if (
          !this.scrollProgress &&
          event.target.scrollTop + event.target.clientHeight + 500 >
            event.target.scrollHeight &&
          this.scrollCheck
        ) {
          //console.log("queried");
          this.scrollCount++;
          this.scrollProgress = true;
          let unsubscribe = queryThreads
            .where("status", "==", this.threadStatus)
            .where("accountId", "in", this.selectedAccounts)
            .startAfter(lastVisible)
            .onSnapshot((snapshot) => {
              this.$store.dispatch("setSending", false);
              this.routeSelect = false;

              if (snapshot.empty) {
                //console.log("found empty");
                this.scrollCheck = false;
              }

              this.scrollProgress = false;

              snapshot.docChanges().forEach((change) => {
             
                this.handleThreads(change, this.scrollCount);
                lastVisible = change.doc;
              });
              this.handleThreadsAfter();
            });
          this.unsubscribes.push(unsubscribe);
          /*.get()
            .then((querySnapshot) => {
              if (querySnapshot.empty) {
                this.scrollCheck = false;
              }
              this.scrollProgress = false;
              querySnapshot.forEach((doc) => {
                let thread = ThreadModel(doc, this.accounts);
                this.threads.push(thread);
                lastVisible = doc;
              });
            });*/
        }
      });
    },
    demoMessage() {
      db.collection("messages")
        .doc()
        .set({
          threadId: this.selectedThread.id,
          direction: Math.random() >= 0.5 ? "outgoing" : "incoming",
          channelSystem: "Demo",
          messageTime: firebase.firestore.Timestamp.now(),
          messageText: faker.lorem.sentence(),
          demoMessage: true,
        });
      //
    },
    removeDemoMessages() {
      db.collection("messages")
        .where("threadId", "==", this.selectedThread.id)
        .where("demoMessage", "==", true)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            doc.ref.delete();
          });
        });
    },
    toggleCollapse() {
      this.statusCollapse = !this.statusCollapse;
    },
    routeToThreadStatus(routeName) {
      const statusLabel = routeName.replace("Thread", "");
      return Object.keys(this.statusOptions)[
        Object.values(this.statusOptions).indexOf(statusLabel)
      ];
    },
    fetchThreadAndSelect(threadId) {
      db.collection("threads")
        .doc(threadId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            let thread = ThreadModel(doc, this.accounts);
            this.selectThread(thread, false);
          }
        });
    },
    getContact(thread) {
      if (!thread.linkedContact) {
        this.linkedContactObj = {};
        return;
      }

      if (this.linkedContactObj.id === thread.linkedContact) {
        return;
      }
      this.linkedContactObj = {};
      db.collection("contacts")
        .doc(thread.linkedContact)
        .get()
        .then((doc) => {
          if (this.selectedThread.linkedContact === doc.id) {
            this.linkedContactObj = { id: doc.id, ...doc.data() };
          } else {
            //console.log("refetching");
            this.getContact(this.selectedThread);
          }
        });
    },
    refetchSelectedThread(threadId) {
      db.collection("threads")
        .doc(threadId)
        .get()
        .then((doc) => {
          this.selectedThread = ThreadModel(doc, this.accounts);
        });
    },
    destroySnapshot() {
      //console.log("destroySnapshot");
      this.unsubscribes.forEach((unsubscribe) => {
        unsubscribe();
        //console.log("unsubscribe");
      });
      this.unsubscribes = [];
    },
  },
  destroyed() {
    this.destroySnapshot();
  },
};
</script>

<style lang="scss" scoped>
.md-drawer {
  width: 400px;
  overflow: hidden;
}
.contact-list {
  height: calc(100vh - 135px);
  overflow-y: auto;
  .contact-content {
    width: calc(100% - 60px);
    border-bottom: 1px solid #e5e5e5;
    line-height: 16px;
    padding-bottom: 0.5rem;
    .contact-name {
      // max-width: 190px;
      display: block;
      float: left;
      // overflow: hidden;
      font-size: 14px;
      font-weight: bold;
    }
    .thread-icon {
      font-size: 18px !important;
    }
    .thread-time {
      font-size: 12px;
    }
    .thread-content {
      font-size: 13px;
    }
  }
  .md-list-item {
    margin: 0px 4px;
    .md-list-item-container {
      outline: none;
      &:hover,
      &:active {
        background-color: #eef2ff !important;
        border-radius: 10px !important;
        .contact-content {
          border-bottom-color: #eef2ff !important;
        }
      }
    }
    &.active {
      .md-list-item-container {
        background-color: #eef2ff !important;
        border-radius: 10px !important;
        .contact-content {
          border-bottom-color: #eef2ff !important;
        }
      }
    }
  }
}
.message-container {
  // width: calc(100% - 400px);
  // float: left;
  .main-info {
    .contact-name {
      line-height: 50px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
@media only screen and (max-width: 960px) {
  .contact-list {
    height: calc(100vh - 100px);
  }
}
</style>
