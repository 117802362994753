<template>
  <div class="md-layout my-3">
    <div class="mindbody-connect-container" v-if="!hasPlaybook">
      <h5 class="md-title">F45 Playbook Lead Integration</h5>
      <p
        class="mb-0"
      >This is not an official integration, when this integration is enabled, all leads in the past 30 days will be imported, and will be synced every 15 min.</p>
      <form novalidate class="md-layout" @submit.prevent="validateForm">
        <!-- <div class="md-layout-item md-small-size-100">
          <md-field :class="getValidationClass('studioId')">
            <label for="first-name">Playbook Studio ID</label>
            <md-input v-model="form.studioId" :disabled="sending" />
            <span class="md-error" v-if="!$v.form.studioId.required">Playbook studio id is required.</span>
          </md-field>
        </div> -->
        <div class="md-layout-item md-small-size-100 ml-2">
          <md-field :class="getValidationClass('email')">
            <label for="first-name">Playbook Email</label>
            <md-input v-model="form.email" :disabled="sending" />
            <span class="md-error" v-if="!$v.form.email.required">Playbook email is required.</span>
            <span class="md-error" v-if="!$v.form.email.email">Playbook email should be valid.</span>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 ml-2">
          <md-field :class="getValidationClass('password')">
            <label for="first-name">Playbook Password</label>
            <md-input v-model="form.password" type="password" :disabled="sending" />
            <span class="md-error" v-if="!$v.form.password.required">Playbook password is required.</span>
          </md-field>
        </div>
        <md-button type="submit" class="md-raised md-primary" :disabled="sending">Save</md-button>
      </form>
      <span class="md-error" v-for="(error,index) in errors" :key="index" style="color: #ff1744">{{error}}</span>
    </div>
    <div class="mindbody-result-container" v-else>
      <div class="md-layout-item">
        <h5 class="md-title">Plabybook: Connected to Studio Id {{plabyookData.studioId}}</h5>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-medium-size-50 md-large-size-40">
          <p>
            You are successfully connected to Studio Id:
            <span
              class="font-weight-bold"
            >{{plabyookData.studioId}}</span>
          </p>
          <p>Our standard sync process will import your Playbook clients as contacts. Once complete they will continue to stay in Sync.</p>
          <!-- <md-button class="md-raised btn-light">Start Sync</md-button> -->
          <md-button class="md-primary border-primary" @click="disconnect">Disconnect</md-button>

          <!-- <md-button class="md-raised btn-info" @click="forceSync">Force Sync Last 30 Days</md-button>
          <md-button class="md-raised btn-info" @click="forceSyncAll">Force Sync All</md-button>-->
        </div>
        <div class="md-layout-item md-medium-size-50 md-large-size-40">
          <h6 class="md-subtitle" v-if="plabyookData.status">Status:</h6>
          <p v-if="plabyookData.status">{{plabyookData.status}}</p>
          <h6 class="md-subtitle">Last Webhook Recieved:</h6>
          <p>{{timeFormat(plabyookData.lastWebhook)}}</p>
          <h6 class="md-subtitle">Last Client Sync:</h6>
          <p>{{timeFormat(plabyookData.lastSync)}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { db } from "@/firebase/init";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import firebase from "firebase";
import moment from "moment";

export default {
  name: "PlaybookIntegration",
  mixins: [validationMixin],
  props: {
    account: {
      type: Object,
      default: null
    },
    form: {
      type: Object,
      default: function() {
        return {
          studioId: null,
          email: null,
          password: null
        };
      }
    },
    formType: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      accounts: "accounts",
      sending: "sending",
      userToken: "userToken"
    }),
    hasPlaybook() {
      return (
        this.accountData &&
        typeof this.accountData.playbook !== "undefined" &&
        this.accountData.playbook.studioId
      );
    },
    plabyookData() {
      return this.accountData &&
        typeof this.accountData.playbook !== "undefined"
        ? this.accountData.playbook
        : {};
    }
  },
  data: () => ({
    leadSaved: false,
    accountData: {},
    errors: []
  }),
  watch: {
    account: {
      immediate: true,
      handler() {
        //this.fetchAccount();
        this.$bind(
          "accountData",
          db.collection("accounts").doc(this.account.id)
        );
      }
    }
  },
  validations: {
    form: {
      // studioId: {
      //   required
      // },
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  methods: {
    disconnect() {
      this.$store.dispatch("setSending", true);
      db.collection("accounts")
        .doc(this.account.id)
        .update({
          playbook: firebase.firestore.FieldValue.delete()
        })
        .then(() => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch(
            "showSnackbar",
            `F45 Playbook Disconnected Successfully`
          );
        })
        .catch(err => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", err);
        });
    },
    fetchAccount() {
      alert("fetching");
      db.collection("accounts")
        .doc(this.account.id)
        .onSnapshot(doc => {
          alert("update");
          this.accountData = doc.data();
        });
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.studioId = null;
      this.form.email = null;
      this.form.password = null;
    },
    async saveForm() {
      this.$store.dispatch("setSending", true);
      const email = this.form.email;
      const password = this.form.password;
      const validateCredentials = await this.validateCredentials(email, password);
      if(validateCredentials.errors){
        this.errors = validateCredentials.errors;
      } else {
        this.errors = [];
      }
      if(!validateCredentials.success){
        this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", "Something went wrong!");
          return;
      
      }
      const studioId = validateCredentials.data.franchisee_id;
     db.collection("accounts")
        .doc(this.account.id)
        .update({
          playbook: {
                  studioId,
            email: this.form.email,
            password: btoa(this.form.password),
            status: "Active"
          }
        })
        .then(() => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch(
            "showSnackbar",
            "Playbook integration added successfully"
          );
          this.clearForm();
        })
        .catch(err => {
          this.$store.dispatch("setSending", false);
          this.$store.dispatch("showSnackbar", err);
        });
      
    },
    async validateCredentials(email, password){
      let url = `api/playbook/login?token=${this.userToken}`;
      return await axios({ url: url, method: "POST", data: {
          email,
           password
        }, })
        .then((resp) => {
          return resp.data;
        })
        .catch((err) => {
            return false;
        });
    },
    validateForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveForm();
      }
    },
    timeFormat(time) {
      if (time) {
        return moment(time.toDate()).format("lll");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.md-title {
  font-size: 16px;
  font-weight: 600;
}
.md-subtitle {
  font-size: 13px;
  font-weight: 600;
}
.txt-site-id {
  width: 200px;
}
p {
  font-size: 13px;
}
</style>