<template>
   

    <!-- <md-menu-content class="px-3 md-elevation-20 bg-white account-filter">
      <div class="md-title text-gray my-2"> Leads Filters</div>
      <md-checkbox v-for="account in accounts" :value="account.id" :key="account.id" v-model="selectedAccounts"
        class="md-primary my-1"
        :disabled="selectedAccounts.length >= limit && selectedAccounts.indexOf(account.id) == -1">{{ account.name
        }}</md-checkbox>
      <md-button class="md-primary" @click="selectedAccounts = []">Clear Filters</md-button>
    </md-menu-content> -->
  </template>
  <script>
  import { mapGetters } from "vuex";
  import store from "@/store/index";
  
  export default {
    name: "LeadsFilter",
    props: ["filteredAccounts"],
    data() {
      return {
        limit: 10,
       
        selectedAccounts:[],
        snackbar: false,
        reverted: false,
        disabledAccounts: [],
        localStorageKey: "selectedAccountsV1.01" //If you change this value, change in store/index.js also
      };
    },
    computed: {
      ...mapGetters({
        accounts: "accounts"
      })
    },
    watch: {
      accounts: function (newValue, oldValue) {
        if (!newValue.length) {
          this.$router.push({ name: "New Account" });
          return;
        }
        this.fetchSelectedAccounts();
        console.log("selectedLead",this.selectedFilters)
      },
      selectedAccounts: function(newValue, oldValue) {
        window.localStorage.setItem(this.localStorageKey, newValue);
        this.$store.dispatch("storeSelectedAccounts", newValue);
      }
    },
    created() {
      this.fetchSelectedAccounts();
    },
    methods: {
      fetchSelectedAccounts() {
        if (!this.accounts.length) {
          return;
        }
        let selectedAccounts = window.localStorage.getItem(this.localStorageKey);
        if (selectedAccounts) {
          selectedAccounts = selectedAccounts.split(",");
          /* get accountsId array */
          let accounts = [];
          this.accounts.map(account => {
            accounts.push(account.id);
          });
          /*filter selectedaccounts from active accounts */
          selectedAccounts = selectedAccounts.filter(accountId =>
            accounts.includes(accountId)
          );

        } else if (selectedAccounts === null) {
          selectedAccounts = [];
          let count = 0;
          this.accounts.map(account => {
            if (count < 10) {
              selectedAccounts.push(account.id);
              count++;
            }
          });
        } else {
          selectedAccounts = [];
        }
        if (this.selectedAccounts.length > 10) {
          this.$store.dispatch("showSnackbar", "Only 10 accounts are selected. Selected:" + this.accounts.length);
        }
        this.selectedAccounts = selectedAccounts;


      }
    }
  };
  </script>
  <style lang="scss" scoped>
  .account-filter {
    border-radius: 10px;
  
    .md-title {
      font-size: 15px;
      font-weight: bold;
    }
  
    .md-checkbox {
      font-size: 13px;
      font-weight: 500;
    }
  
    .md-button {
      font-size: 15px;
      font-weight: bold;
      text-transform: none;
    }
  }
  </style>
  