export default function transformTags(todoObj, accounts) {
    let tags = [];
    let usedBadge = null;
    if (accounts.length > 1) {
        let account = accounts.filter(
            account => account.id === todoObj.accountId
        );
        if (account.length) {
            usedBadge = account[0].number;
            tags.push({
                name: account[0].name,
                class: "badge-color-" + account[0].number
            });
        }
    }

    let count = 0;
    if (todoObj.hasOwnProperty("tags") && Array.isArray(todoObj.tags)) {
        todoObj.tags.forEach(tag => {
            if (count === usedBadge) count++;
            if(tag.name !== 'test'){
                tags.push({ name: tag.name, class: "badge-color-" + count });
                count++;
            }
            if (count > 4) count = 0;
        });
    }

    return tags;
}