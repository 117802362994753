import moment from "moment";

export default function dateFormat(dateTime) {
    //return moment(dateTime).fromNow();
    const days = moment().diff(moment(dateTime), "days");
    const week = Math.round(days / 7);
    let output = "";
    if (days == 0) {
        return "Today";
    } else if (days == 1) {
        return "Yesterday";
    } else if (days > 0 && week < 1) {
        return days + " Days";
    } else if (week === 1) {
        return week + " Week";
    } else if (week > 1) {
        return week + " Weeks";
    }


    return output;
}