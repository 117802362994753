<template>
  <div>
    <form novalidate @submit.prevent="validateInfo">
      <md-card class="login">
        <md-card-header>
          <div class="md-title text-primary">Reset Password</div>
        </md-card-header>
        <md-card-content>
          <md-field :class="getValidationClass('email')">
            <label>Email</label>
            <md-input v-model="form.email" type="email" :disabled="sending"></md-input>
            <span class="md-error" v-if="!$v.form.email.required">The email is required</span>
            <span class="md-error" v-else-if="!$v.form.email.email">The email is invalid</span>
          </md-field>
          <md-field :class="getValidationClass('password')">
            <label>Password</label>
            <md-input v-model="form.password" type="password" :disabled="sending"></md-input>
            <span class="md-error" v-if="!$v.form.password.required">The password is required</span>
            <span class="md-error" v-else-if="!$v.form.password.minlength">The password must be at least {{ $v.form.password.$params.minLength.min }} letters.</span>
          </md-field>
          <p v-if="feedback" class="text-danger">{{ feedback }}</p>
        </md-card-content>

        <md-card-actions>
          <md-button 
            type="submit" 
            class="md-raised md-primary w-100"
            :disabled="sending"
          >
            Done
          </md-button>
        </md-card-actions>
      </md-card>
    </form>
  </div>  
</template>

<script>
import firebase from 'firebase'
import store from "@/store/index";
import {mapGetters} from 'vuex'

import {validationMixin} from 'vuelidate'
import {
  required,
  email,
  minLength,
} from 'vuelidate/lib/validators'

export default {
  name: 'ResetPassword',
  mixins: [validationMixin],
  data: () => ({
    form: {
      email: null,
      password: null
    },
    feedback: null,
  }),
  computed: { 
    ...mapGetters({
      sending: "sending"
    }) 
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    resetPassword() {
    },
    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    validateInfo() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.resetPassword()
      }
    }
  }
}
</script>

